<template>
  <div class="mt-2">
    <div class="d-flex task-check-btn">
      <button class="btn-themed btn-white check-answer-btn"
              :disabled="disabled || foundRight" @click="$emit('taskCheck')">
        {{ $t('check') }}
      </button>
      <TaskCheckBubbles :tries="tries"/>
    </div>
  </div>
</template>

<script>

import TaskCheckBubbles from "./TaskCheckBubbles"

export default {
  name: "TaskCheckButton",
  components: {TaskCheckBubbles},
  props: {
    tries: {},
    disabled: {}
  },
  computed: {
    foundRight() {
      if(!this.tries || !this.tries.length) return false
      const lastTry = this.tries.at(-1)
      return lastTry === true
    }
  }
}
</script>

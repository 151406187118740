import {mapGetters} from "vuex"

export default {
    computed: {
        isLight() {
            return this.source === 'light'
        },
        homeworkReview() {
            return this.viewMode === 'homeworkReview'
        },
        alignView() {
            return this.block?.content?.view || 'left'
        },
        ...mapGetters([
            'isEnggoMethodics'
        ]),
    }
}

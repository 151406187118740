var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.parsed)?_c('div',{staticClass:"task"},[_c('TaskCounter',{ref:"taskcounter",attrs:{"block":_vm.block,"showAnswers":_vm.showAnswers && _vm.host,"counter":_vm.counter},on:{"refresh":_vm.clear}}),_c('drop',{staticClass:"drag-words-wrapper",nativeOn:{"click":function($event){return _vm.dragWordsClicked.apply(null, arguments)}}},[_vm._l((_vm.shuffledWords),function(c,cind){return [_c('drag',{key:("word-" + cind),staticClass:"d-inline-block drag-wrapper",class:{
                dragged: _vm.usedWords.includes(c.id),
                selected: _vm.selectedWord && _vm.selectedWord.id == c.id,
              },attrs:{"image-x-offset":((c.word ? c.word.length : 0) * 2.5) + 12,"image-y-offset":20,"draggable":!_vm.usedWords.includes(c.id),"transfer-data":Object.assign({}, c)},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.dragWordClicked(c)}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.transferData)?_c('div',{staticClass:"drag-block removed-by-dragging text-themed"},[_vm._v(_vm._s(c.word))]):_c('div',{staticClass:"drag-block"},[_vm._v(_vm._s(c.word))])]}}],null,true)},[_c('div',{staticClass:"drag-image",attrs:{"slot":"image"},slot:"image"},[_vm._v(_vm._s(c.word))])])]})],2),_c('swiper',{ref:"homeSwiper",attrs:{"options":_vm.swiperOptions}},[_vm._l((_vm.unusedImages),function(c,cind){return _c('swiper-slide',{key:("slide-" + cind)},[_c('drop',{key:("img-" + cind),staticClass:"d-inline-flex word-card h-100 flex-column justify-content-between",on:{"drop":function($event){
              var i = arguments.length, argsArray = Array(i);
              while ( i-- ) argsArray[i] = arguments[i];
return _vm.dropped.apply(void 0, [ c ].concat( argsArray ))}},nativeOn:{"click":function($event){return _vm.dropped(c, _vm.selectedWord)}}},[_c('img',{attrs:{"src":c.img,"alt":""}}),_c('div',{staticClass:"card-input"})])],1)}),(_vm.unusedImages.length)?_c('div',{staticClass:"swiper-button-prev d-md-inline-block d-none",attrs:{"slot":"button-prev"},slot:"button-prev"},[_c('LeftSvg')],1):_vm._e(),(_vm.unusedImages.length)?_c('div',{staticClass:"swiper-button-next d-md-inline-block d-none",attrs:{"slot":"button-next"},slot:"button-next"},[_c('RightSvg')],1):_vm._e()],2),_c('hr',{staticClass:"mt-0"}),_c('div',[_c('b-row',[_vm._l((_vm.responded),function(c,cind){return [_c('b-col',{key:("resp-" + cind),staticClass:"mb-2",attrs:{"lg":"3","md":"4","cols":"6"}},[_c('div',{staticClass:"d-flex word-card pointer h-100 justify-content-between flex-column",on:{"click":function($event){return _vm.removeWordFromResponded(cind)}}},[_c('img',{attrs:{"src":c.img.img,"alt":""}}),_c('div',{staticClass:"card-input responded",class:{
                 right:  (_vm.showAnswers || _vm.showEngGoResponses || _vm.homeworkReview) && (c.img.id === c.word.id),
                 wrong:  (_vm.showAnswers || _vm.showEngGoResponses || _vm.homeworkReview) && (c.img.id !== c.word.id)
             }},[_vm._v(" "+_vm._s(c.word.word)+" ")])])])]})],2)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
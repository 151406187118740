<!--Attach image through QR-->
<template>
  <div class="task" v-if="$store.state.user">
    <TaskCounter :block="block"
                 :counter="counter"
                 :showAnswers="host"
                 @refresh="clear"
                 ref="taskcounter"/>
    <IM02UploadPart view="block"
                    :blockId="block.id"
                    :userId="$store.state.user.id"
                    :lessonId="lessonId"
                    :materialId="materialId"
                    :selectedUser="selectedUser"
                    :homeworkStudentId="homeworkStudentId"
                    :uploadedFiles="files"
                    :host="host"
                    :viewMode="viewMode"
                    @filesUploaded="filesUploaded"
                    :slideId="block.slide_id"/>
    <div v-if="host && (!studentFiles)" class="host-placeholder">
      <b-row class="align-items-center">
        <b-col cols="auto">
          <ImageSvg/>
        </b-col>
        <b-col class="text-center">
          <div class="title">{{ $t('photos_from_students') }}</div>
          <div class="subtitle">{{ $t('when_students_upload_photos_they_will_appear_here') }}</div>
        </b-col>
      </b-row>
    </div>
    <div v-if="studentResponses && studentResponses.length && host">
      <div v-for="(obj, objind) in studentResponses"
           :key="`stud-resp-${objind}`"
           v-if="obj.responses.files && obj.responses.files.length">
        <div class="my-2">{{ obj.student.name }}</div>
        <div>
          <b-row>
            <b-col md="6" lg="4" v-for="(file, find) in obj.responses.files" :key="`file-${objind}-${find}`">
              <img class="student-img" :src="file" alt="" v-img/>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="mt-3" v-if="studentFiles">
        <a @click="downloadAllImages" class="component-counter pointer" :data-id="block.id">
          <DownloadSvg class="svg-blue"/>
        </a>
      </div>
<!--      <div v-else>-->
<!--        {{ $t('no_files_uploaded') }}-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>

import TaskCounter from "@/views/parts/constructor/parts/TaskCounter"
import EventBus from '@/services/event-bus'
import IM02UploadPart from "@/views/parts/class/IM02UploadPart"
import DownloadSvg from '@/assets/svg/download.svg'
import ImageSvg from "@/assets/svg/image_g.svg"
import {mapActions, mapMutations, mapState} from "vuex"
import {LessonsService} from "../../../services/api.service"

export default {
  components: {
    TaskCounter,
    IM02UploadPart,
    DownloadSvg,
    ImageSvg
  },
  props: {
    selectedUser: {  },
    homeworkStudentId: {
      default: null
    },
    host: {
      type: Boolean,
      default: false
    },
    source: {
      type: String,
      default: "preview"
    },
    block: {
      type: Object,
      default: null
    },
    counter: {
      type: String,
      default: null
    },
    ind: {},
  },
  computed: {
    ...mapState({
      tasksStore: state => state.call.tasksStore,
      lessonId: state   => state.call.lessonId,
      materialId: state => state.call.materialId,
      viewMode: state   => state.call.viewMode,
    }),
    taskStoreId() {
      return this.tasksStore[this.block.id] ? this.tasksStore[this.block.id] : {
        files: [],
        wnr: []
      }
    },
    studentFiles() {
      return this.studentResponses.filter(x => x.responses.files && x.responses.files.length).length > 0
    }
  },
  data() {
    return {
      wnr: [],
      files: [],
      studentResponses: []
    }
  },
  mounted() {
    this.parseData()
    this.getAllStudentsImages()
    EventBus.$on('slideTaskChangedReceive', (cid, data) => {
      if(this.block.id == cid) {
        this.files = data.files
        this.wnr = data.wnr
        if(!this.host) {
          this.saveTaskStorageBlock({blockId: this.block.id, lessonId: this.lessonId, materialId: this.materialId, type: this.viewMode, data: data})
        }
        if(this.host) {
          this.getAllStudentsImages()
          setTimeout(this.getAllStudentsImages, 1000)
        }
      }
    })
    this.$emit('solved', true, this.wnr)
  },
  methods: {
    ...mapMutations([
      'clearTasksBlock',
    ]),
    ...mapActions([
      'saveTaskStorageBlock',
    ]),
    retrieveSavedData() {
      this.files = this.taskStoreId.files
      if(this.$refs.taskcounter) {
        this.$nextTick(() => this.$refs.taskcounter.edited = true)
      }
    },
    parseData() {
      this.retrieveSavedData()
    },
    send() {
      let data = {
        wnr: this.wnr,
        files: this.files
      }
      EventBus.$emit('slideTaskChangedSend', this.block.id, data, 'IM02')
      if(this.viewMode != 'homeworkReview') {
        this.saveTaskStorageBlock({blockId: this.block.id, lessonId: this.lessonId, materialId: this.materialId, type: this.viewMode, data: data})
      }
    },
    clear() {
      this.clearTasksBlock(this.block.id)
      this.$nextTick(this.send)
    },
    filesUploaded(files) {
      this.files = files
      this.send()
    },
    getAllStudentsImages() {
      if(!this.host || !this.lessonId) return
      LessonsService.getResponsesForBlockForAllStudents(this.lessonId, this.block.id, this.viewMode, this.homeworkStudentId).then(res => {
        this.studentResponses = res.data.data.storage
      })
    },
    async downloadImg(url, name) {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = function(){
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(this.response);
        var tag = document.createElement('a');
        tag.href = imageUrl;
        tag.download = name;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
      }
      xhr.send();
    },
    async downloadAllImages() {
      for (const obj of this.studentResponses) {
        for(const file of obj.responses.files) {
          await this.downloadImg(file, obj.student.name)
        }
      }
    }
  },
  watch: {
    files(val) {
      let filled = !!val.length
      this.wnr = [filled ? 'r' : 'w']
      this.$emit('solved', filled, this.wnr)
    },
    taskStoreId: {
      handler() {
        this.retrieveSavedData()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.student-img {
  height: 220px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.host-placeholder {
  background: #F9F9FB;
  border: 1px dashed #C5CDDC;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 40px 60px;
  .title {
    color: #9DA2AC;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .subtitle {
    color: #9DA2AC;
    font-size: 13px;
    font-weight: 500;
  }
}
@media (max-width: 768px) {
  .host-placeholder {
    padding: 20px 20px;
  }
}
</style>







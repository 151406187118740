<template>
  <div class="mb-3 position-relative task-counter-wrap">
    <b-row class="align-items-center">
      <b-col cols="auto">
        <span v-if="shownCounter"
              @click="blockCounterClicked"
              :class="{ active: selectedBlockId == block.id, pointer: host }"
              class="component-counter">
          {{ shownCounter }}
        </span>
      </b-col>
      <b-col class="text-center">
        <div class="st__input st__task"
             :class="{ 'pl-0': !shownCounter }"
             v-if="block.content.task"
             v-html="block.content.task">
        </div>
      </b-col>
      <b-col cols="auto">
        <div class="refresh-counter">
          <div class="d-inline-block btn-themed btn-white btn-refresh"
               v-b-tooltip.hover
               :title="$t('see_responses')"
               v-if="isEnggoMethodics && showAnswers"
               @click="showStudentsResponses">
            <UserGroupSvg class="svg-icon-sm"/>
          </div>
          <div class="d-inline-block text-underlined pointer btn-themed btn-white btn-refresh ml-2"
               v-b-tooltip.hover :title="$t('reset')"
               v-if="edited && showAnswers"
               @click="refresh">
            <RefreshSvg class="svg-icon-sm"/>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import RefreshSvg from "@/assets/svg/refresh.svg"
import UserGroupSvg from "@/assets/svg/user_group.svg"
import EventBus from '@/services/event-bus'
import {mapGetters, mapState} from "vuex"

export default {
  components: {
    RefreshSvg,
    UserGroupSvg
  },
  props: {
    block: { type: Object, default: null },
    counter: { type: String, default: null },
    showAnswers: { type: Boolean, default: false }
  },
  data() {
    return {
      edited: false,
      timer: null
    }
  },
  computed: {
    ...mapGetters([
      'isEnggoMethodics'
    ]),
    ...mapState({
      selectedBlockId: state => state.call.selectedBlockId,
      host: state => state.call.host,
    }),
    shownCounter() {
      return this.block.custom_number
    }
  },
  mounted() {
    EventBus.$on('taskCounterEdited', (bid) => {
      if(bid == this.block.id) {
        this.edited = true
      }
    })
  },
  methods: {
    refresh() {
      this.edited = false
      EventBus.$emit('taskCounterRefresh', this.block.id)
      this.$store.commit('removeBlockForResponses', this.block.id)
      EventBus.$emit('showBlockResponses')
      this.$emit('refresh')
    },
    showStudentsResponses() {
      this.$store.commit('setBlockId', this.block.id)
      this.$nextTick(() => {
        EventBus.$emit('studentresponsesmodal')
        this.$bvModal.show('studentresponsesmodal')
      })
    },
    blockCounterClicked() {
      if(!this.host) return
      if(this.timer) {
        clearTimeout(this.timer)
      }
      this.$store.commit('setSelectedBlockId', this.block.id)
      this.timer = setTimeout(() => {
        this.$store.commit('setSelectedBlockId', null)
      }, 3000)
    }
  }
}
</script>

<style scoped lang="scss">
.task-counter-wrap {
  min-height: 24px;
}
.btn-refresh {
  width: 36px;
  height: 36px;
  line-height: 34px;
  padding: 0 !important;
  border-radius: 9px !important;
  svg {
    fill: #BBC0CE;
  }
  &:hover {
    svg {
      fill: $text-themed;
    }
  }
}
</style>





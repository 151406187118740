import { render, staticRenderFns } from "./AU03.vue?vue&type=template&id=52532d4d&scoped=true&"
import script from "./AU03.vue?vue&type=script&lang=js&"
export * from "./AU03.vue?vue&type=script&lang=js&"
import style0 from "./AU03.vue?vue&type=style&index=0&id=52532d4d&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52532d4d",
  null
  
)

export default component.exports
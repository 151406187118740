<!--Audio-->
<template>
  <div class="task">
    <TaskCounter :block="block" :counter="counter" ref="taskcounter"/>
    <audio v-if="block.content.audio" controls>
      <source :src="block.content.audio" type="audio/mpeg">
    </audio>
    <div v-else>{{ $t('file_does_not_exist') }}</div>
    <div v-if="block.content.script"
         class="audio-script"
         :class="{ onlyhost: !block.content.show_script_to_users }">
      <div class="font-weight-bold mb-0 card-collapse" v-b-toggle="'script-'+block.id">
        {{ $t('script') }}<span class="collapse-caret ml-3"><DownSvg/></span>
      </div>
      <b-collapse :id="'script-'+block.id">
        <div class="pt-3" v-html="block.content.script"></div>
      </b-collapse>
    </div>
    <StudentTaskText :block="block"/>
  </div>
</template>

<script>
import TaskCounter from "@/views/parts/constructor/parts/TaskCounter"
import StudentTaskText from "@/views/parts/constructor/parts/StudentTaskText"
import DownSvg from "@/assets/svg/down.svg"

export default {
  components: {
    StudentTaskText,
    TaskCounter,
    DownSvg
  },
  props: {
    host: {
      type: Boolean,
      default: false
    },
    source: {
      type: String,
      default: "preview"
    },
    block: {
      type: Object,
      default: null
    },
    counter: {
      type: String,
      default: null
    },
    ind: {},
  },
  mounted() {
    this.$emit('solved', true, [])
  }
}
</script>






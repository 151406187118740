var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.parsed)?_c('div',{staticClass:"task"},[_c('TaskCounter',{ref:"taskcounter",attrs:{"block":_vm.block,"counter":_vm.counter,"showAnswers":_vm.host},on:{"refresh":_vm.clear}}),_vm._l((_vm.block.content.questions),function(q,qind){return [_c('div',{key:'ques'+_vm.block.id+'-'+qind,staticClass:"question-group",class:[{
           responded: _vm.responded.includes(qind),
           multiple: _vm.countCorrectAnswersInQuestion(q) > 1
         }, ("view-" + (_vm.block.content.view))]},[_c('div',[(!_vm.isLight)?_c('span',[_vm._v(_vm._s((qind + 1))+". ")]):_vm._e(),_vm._v(_vm._s(q.title))]),_c('div',{staticClass:"answer-grid"},_vm._l((q.answers),function(a,aind){return _c('div',{key:'ans'+_vm.block.id+'-'+qind+'-'+aind,staticClass:"quiz-answer",class:{
                shouldberight: (_vm.showAnswers || _vm.showEngGoResponses || _vm.homeworkReview) && _vm.responded.includes(qind) && !_vm.responses[qind].includes(aind) && a.correct,
                right: (_vm.showAnswers || _vm.showEngGoResponses || _vm.homeworkReview) && _vm.responded.includes(qind) && _vm.responses[qind].includes(aind) && a.correct,
                wrong: (_vm.showAnswers || _vm.showEngGoResponses || _vm.homeworkReview) && _vm.responded.includes(qind) && _vm.responses[qind].includes(aind) && !a.correct
             }},[_c('div',{staticClass:"checkbox form-group",class:{ disabled: _vm.responded.includes(qind) && _vm.showAnswers }},[_c('input',{attrs:{"type":"checkbox","value":"1","disabled":_vm.responded.includes(qind) && _vm.showAnswers,"id":_vm.block.id+'-'+qind+'-'+aind},domProps:{"checked":_vm.responses[qind] && _vm.responses[qind].includes(aind)},on:{"input":function($event){_vm.answered(qind, aind, a.correct, _vm.countCorrectAnswersInQuestion(q), _vm.block.content.questions.length)}}}),_c('label',{staticClass:"mb-0",attrs:{"for":_vm.block.id+'-'+qind+'-'+aind}},[_vm._v(" "+_vm._s(a.title)+" ")])])])}),0)])]})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
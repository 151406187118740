var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.parsed)?_c('div',{staticClass:"task"},[_c('TaskCounter',{ref:"taskcounter",attrs:{"block":_vm.block,"counter":_vm.counter,"showAnswers":_vm.host},on:{"refresh":_vm.clear}}),_c('div',{staticClass:"link-words-wrapper"},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"pr-3"},[_vm._l((_vm.leftwords),function(q,qind){return [(q.left)?_c('div',{key:'left'+_vm.block.id+'-'+qind,staticClass:"left-h",style:({ height: _vm.heights[qind]+'px' }),attrs:{"data-h":("left-" + qind)},on:{"click":function($event){return _vm.selectWord(q)}}},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('div',{ref:("left-h-" + qind),refInFor:true,staticClass:"word-block mb-2",class:{
                  active: _vm.selectedWord && _vm.selectedWord.direction == q.direction && _vm.selectedWord.left == q.left,
                  responded: (_vm.tries[q.ind] && _vm.tries[q.ind].length >= _vm.triesLimit),
                  wrong: (_vm.tries[q.ind] && _vm.tries[q.ind].length >= _vm.triesLimit && !_vm.tries[q.ind][(_vm.triesLimit - 1)]) || ((_vm.showEngGoResponses || _vm.homeworkReview) && !_vm.responded.filter(function (x) { return x.i === q.ind; }).length),
                  grayed: (_vm.tries[q.ind] && !_vm.showAnswers),
                  solved: (_vm.showAnswers || _vm.showEngGoResponses || _vm.homeworkReview) && _vm.responded.filter(function (x) { return x.i === q.ind; }).length
              }},[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',[(q.audio)?_c('span',[_c('AudioSvg',{staticClass:"svg-blue svg-icon-md"})],1):_c('span',[_vm._v(_vm._s(q.left))])]),_c('b-col',{attrs:{"cols":"auto"}},[_c('span',{staticClass:"number-responded ml-2",class:{ active: !!_vm.tries[q.ind] }},[_vm._v(" "+_vm._s(_vm.tries[q.ind] ? (qind + 1) : '')+" ")])])],1)],1),(_vm.showAnswers && !(_vm.homeworkReview && !_vm.block.content.show_answers) && !_vm.isLight)?_c('TaskCheckBubbles',{key:("counter-" + (q.ind) + "-" + _vm.keyCounter),staticClass:"mb-1",attrs:{"showSpace":true,"tries":_vm.tries[q.ind] ? _vm.tries[q.ind] : []}}):_vm._e()],1)]):_vm._e()]})],2)]),_c('b-col',{attrs:{"cols":"6"}},[_c('div',{key:("right-counter-" + _vm.keyCounter),staticClass:"link-words-border"},[_vm._l((_vm.rightwords),function(q,qind){return [(q.right)?_c('div',{key:'right'+_vm.block.id+'-'+qind,staticClass:"right-h",style:({ height: _vm.heights[qind]+'px' }),attrs:{"data-h":("right-" + qind)},on:{"click":function($event){return _vm.selectWord(q)}}},[_c('div',{ref:("right-h-" + qind),refInFor:true,staticClass:"word-block mb-2",class:{
                    active: _vm.selectedWord && _vm.selectedWord.direction == q.direction && _vm.selectedWord.left == q.left && _vm.selectedWord.subind == q.subind,
                    wrong: (_vm.tries[q.ind] && _vm.tries[q.ind].length >= _vm.triesLimit && !_vm.tries[q.ind][(_vm.triesLimit - 1)]) || ((_vm.showEngGoResponses || _vm.homeworkReview) && !_vm.responded.filter(function (x) { return x.i === q.ind; }).length),
                    grayed: (_vm.isRightResponded(q) && !_vm.showAnswers),
                    solved: (_vm.showAnswers || _vm.showEngGoResponses || _vm.homeworkReview) && _vm.responded.filter(function (x) { return x.i === q.ind && x.s === q.subind; }).length
                  }},[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"cols":"auto"}},[_c('span',{staticClass:"number-responded mr-2",class:{ active: _vm.isRightResponded(q) }},[_vm._v(" "+_vm._s(_vm.isRightResponded(q) ? (qind + 1) : '')+" ")])]),_c('b-col',[(!_vm.showEngGoResponses || _vm.responded.filter(function (x) { return x.i === q.ind; }).length)?[_vm._v(_vm._s(q.right))]:[_c('span',{staticClass:"striked"},[_vm._v(_vm._s(q.right))]),_c('span',{staticClass:"text-success ml-2"},[_vm._v(_vm._s(_vm.findRightAnswer(qind)))])]],2)],1)],1)]):_vm._e()]})],2)])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
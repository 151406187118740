var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"task"},[_c('TaskCounter',{ref:"taskcounter",attrs:{"block":_vm.block,"counter":_vm.counter}}),_c('div',{staticClass:"px-md-3 px-0 text-center"},[_c('b-row',{staticClass:"align-items-center d-inline-flex",class:{ 'flex-nowrap': ['left','right'].includes(_vm.imgPosition) }},[(_vm.content.img || _vm.isLight)?_c('div',{class:{
            'mb-3': ['top'].includes(_vm.imgPosition),
            'mt-3': ['bottom'].includes(_vm.imgPosition) && !_vm.isLight,
            'col-12': ['top','bottom'].includes(_vm.imgPosition),
            'col-lg-auto col-md-4 col-sm-4 col-5': ['left','right'].includes(_vm.imgPosition),
            'order-0': _vm.imgPosition === 'left',
            'order-1': ['right', 'bottom'].includes(_vm.imgPosition)
          }},[_c('div',{staticClass:"px-2"},[(_vm.content.img)?_c('img',{staticClass:"img st__input",attrs:{"href":_vm.content.img,"src":_vm.content.img,"alt":_vm.content.descr}}):_vm._e(),(!_vm.content.img && _vm.isLight)?_c('Monstrik'):_vm._e()],1)]):_vm._e(),(_vm.content.descr)?_c('div',{class:{
                'mx-auto': ['top','bottom'].includes(_vm.imgPosition),
                'order-1': _vm.imgPosition === 'left',
                'order-0': ['right', 'bottom'].includes(_vm.imgPosition)
             }},[_c('div',{staticClass:"px-2"},[_c('div',{staticClass:"text-block d-inline-block px-md-4 px-2 bubble-speech",class:{
          'corner-left': (_vm.content.img || _vm.isLight) && _vm.imgPosition === 'left',
          'corner-right': (_vm.content.img || _vm.isLight) && _vm.imgPosition === 'right',
          'corner-top': (_vm.content.img || _vm.isLight) && _vm.imgPosition === 'top',
          'corner-bottom': (_vm.content.img || _vm.isLight) && _vm.imgPosition === 'bottom'
        }},[_c('b-row',{staticClass:"align-items-center"},[(_vm.content.audio || _vm.content.auto_audio)?_c('b-col',{attrs:{"cols":"auto"}},[_c('a',{staticClass:"btn pl-0 pr-md-2 pr-1",on:{"click":_vm.playAudio}},[_c('PlaySvg',{staticClass:"svg-icon-md svg-blue"})],1)]):_vm._e(),_c('b-col',[_vm._v(_vm._s(_vm.content.descr))])],1)],1)])]):_vm._e(),(!_vm.content.descr && (_vm.content.audio || _vm.content.auto_audio))?_c('b-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"px-2"},[_c('a',{staticClass:"btn btn-themed",on:{"click":_vm.playAudio}},[_c('PlaySvg',{staticClass:"svg-icon-md svg-white"})],1)])]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<!--Create sentence from words-->
<template>
  <div class="task" v-if="parsed">
    <TaskCounter :block="block"
                 :showAnswers="showAnswers && host"
                 :counter="counter"
                 @refresh="clear"
                 ref="taskcounter"/>
    <div v-for="(s, sind) in sentences" :key="`sentence-${sind}`" :class="{ 'mt-4': sind > 0 }">
      <div class="">
        <drop class="drag-words-wrapper" @drop="droppedBack(sind, ...arguments)">
          <template v-for="(b, bind) in s.shuffled">
            <drag class="d-inline-block drag-wrapper"
                  :key="'drag-'+block.id+'-'+bind"
                  :image-x-offset="(b.html.length * 2.5) + 12"
                  :image-y-offset="20"
                  :class="{
                    dragged: !wordCanBeDragged(b, sind),
                    selected: selectedWord && selectedWord.wordIndex === bind && selectedWord.sentenceIndex === sind
                  }"
                  @click.native="clickedDrag({ html: b.html, index: b.counter, filled: false, sind: sind }, sind, bind)"
                  :draggable="wordCanBeDragged(b, sind)"
                  :transfer-data="{ html: b.html, index: b.counter, filled: false, sind: sind }">
              <div slot="image" class="drag-image">{{ b.html }}</div>
              <template slot-scope="props">
                <div class="drag-block removed-by-dragging text-themed" v-if="props.transferData">{{ b.html }}</div>
                <div v-else class="drag-block">{{ b.html }}</div>
              </template>
            </drag>
          </template>
        </drop>
      </div>
      <div class="align-items-center text-center">
        <template v-for="(b, bind) in s.right">
          <div class="d-inline-block mr-2 position-relative align-middle drag-wrapper-setup" :key="'drop-'+block.id+'-'+bind">
            <drop class="drop-block task-input form-control form-control-white"
                  :class="{
                    responded: responded[sind][b.counter], hovered: hovered[sind][b.counter] === true,
                    right:  (checking[sind] || showEngGoResponses || (isEnggoMethodics && host) || homeworkReview) && responded[sind][b.counter] && responded[sind][b.counter].html == b.html,
                    wrong:  (checking[sind] || showEngGoResponses || (isEnggoMethodics && host) || homeworkReview) && responded[sind][b.counter] && responded[sind][b.counter].html != b.html
                  }"
                  @click.native="clickedDrop(b, sind)"
                  @dragover="$set(hovered[sind], b.counter, true)"
                  @dragleave="$set(hovered[sind], b.counter, false)"
                  @drop="dropped(sind, b.html, b.counter, ...arguments)">
              <drag class="d-inline-block drag-wrapper drag-wrapper-setup"
                    v-if="responded[sind][b.counter]"
                    @click.native="clickBack({ html: responded[sind][b.counter].html, index: responded[sind][b.counter].id, respondedIndex: b.counter, filled: true, sind: sind }, sind)"
                    :image-x-offset="(responded[sind][b.counter].length * 2.5) + 12"
                    :image-y-offset="20"
                    :draggable="(!(checking[sind] && responded[sind][b.counter].html == b.html) && !showEngGoResponses) && !(allResponded && isLight)"
                    :key="'drag-'+block.id+'-'+bind"
                    :transfer-data="{ html: responded[sind][b.counter].html, index: responded[sind][b.counter].id, respondedIndex: b.counter, filled: true, sind: sind }">
                <div slot="image" class="drag-image">{{ responded[sind][b.counter].html }}</div>
                <template slot-scope="props">
                  <div class="drag-block removed-by-dragging text-themed" v-if="props.transferData">{{ responded[sind][b.counter].html }}</div>
                  <div v-else class="drag-block">
                    <template v-if="responded[sind][b.counter].html == b.html || !showEngGoResponses">{{ responded[sind][b.counter].html }}</template>
                    <template v-else>
                      <span class="striked">{{ responded[sind][b.counter].html }}</span>
                      <span class="text-success ml-2">{{ b.html }}</span>
                    </template>
                  </div>
                </template>
              </drag>
            </drop>
          </div>
        </template>
      </div>
      <div v-if="allResponded && isLight && wnr[sind] === 'w'" class="text-center mt-3 font-italic full-right">*
        <template v-for="(b, bind) in s.right">
          <span :key="'answer-'+block.id+'-'+bind">{{ b.html }}</span>
        </template>
      </div>
      <TaskCheckButton v-if="showAnswers && sentenceResponded[sind] && !isEnggoMethodics && !(homeworkReview && !block.content.show_answers)"
                       class="mt-2"
                       :tries="tries[sind]"
                       :disabled="tries[sind] && tries[sind].length >= triesLimit"
                       @taskCheck="toCheck(sind)"
      />
    </div>
  </div>
</template>

<script>

import TaskCounter from "@/views/parts/constructor/parts/TaskCounter"
import EventBus from '@/services/event-bus'
import Vue from 'vue'
import VueDragDrop from 'vue-drag-drop'
import { mapActions, mapMutations, mapState } from "vuex"
import TaskCheckButton from "./parts/TaskCheckButton"
import {shuffleArray} from "../../../helpers/shuffleArray"
import TaskMixin from "./TaskMixin"
Vue.use(VueDragDrop)

export default {
  components: {
    TaskCheckButton,
    TaskCounter
  },
  mixins: [TaskMixin],
  props: {
    host: {
      type: Boolean,
      default: false
    },
    source: {
      type: String,
      default: "preview"
    },
    block: {
      type: Object,
      default: null
    },
    counter: {
      type: String,
      default: null
    },
    ind: {},
  },
  data() {
    return {
      responded: [],
      wnr: [],
      checking: false,
      tries: [],
      hovered: [],
      parsed: false,
      tasks: 0,
      blocks: [],
      sentences: [],
      selectedWord: null
    }
  },
  computed: {
    sentenceResponded() {
      let sentenceResponded = []
      this.sentences.forEach((sentence, index) => {
        sentenceResponded.push(sentence.right.length === this.usedWords[index].length)
      })
      return sentenceResponded
    },
    allResponded() {
      return this.sentenceResponded.filter(x => x === true).length === this.sentences.length
    },
    showAnswers() {
      if(this.isLight) return true
      return this.host || (this.block.content.show_answers && !this.isEnggoMethodics)
    },
    triesLimit() {
      return this.isLight ? 1 : 3
    },
    ...mapState({
      tasksStore: state => state.call.tasksStore,
      lessonId: state   => state.call.lessonId,
      materialId: state => state.call.materialId,
      viewMode: state   => state.call.viewMode,
      selfStudyReviewed: state  => state.call.selfStudyReviewed,
    }),
    isCorrect() {
      let arr = []
      this.sentences.forEach((s, index) => {
        arr[index] = true
        if(!this.sentenceResponded[index]) arr[index] = false
        for(let i in this.responded[index]) {
          let word = s.right.find(x => x.counter == i)
          if(this.responded[index][i].html !== word.html) {
            arr[index] = false
          }
        }
      })
      return arr
    },
    usedWords() {
      let arr = []
      this.sentences.forEach((s, index) => {
        arr.push(Object.values(this.responded[index] ? this.responded[index] : {}))
      })
      return arr
    },
    showEngGoResponses() {
      return (!this.host && this.$store.state.call.blocksShowingResponses.includes(this.block.id)) || this.selfStudyReviewed
    },
    taskStoreId() {
      return this.tasksStore[this.block.id] ? this.tasksStore[this.block.id] : {
        responded: this.defaultSentenceValues('object'),
        wnr: [],
        checking: this.defaultSentenceValues('boolean'),
        tries: this.defaultSentenceValues('array')
      }
    },
  },
  mounted() {
    this.parseData()
    EventBus.$on('slideTaskChangedReceive', (cid, data) => {
      if(this.block.id == cid) {
        this.responded = data.responded
        this.checking = data.checking
        this.tries = data.tries
        this.wnr = data.wnr
        this.saveTaskStorageBlock({blockId: this.block.id, lessonId: this.lessonId, materialId: this.materialId, type: this.viewMode, data: data})
      }
    })
    EventBus.$on('taskClear', this.clear)
    this.$emit('solved', this.allResponded, this.wnr)
  },
  methods: {
    ...mapMutations([
      'clearTasksBlock',
    ]),
    ...mapActions([
      'saveTaskStorageBlock',
    ]),
    dropped(sind, correct, index, data) {
      if(sind != data.sind) return
      if(this.isLight && this.allResponded) return
      this.checking[sind] = false
      let oldIndex = null
      for(let i in this.responded[sind]) {
        if(this.responded[sind][i].id === data.index) oldIndex = i
      }
      let previousResponse = null
      if(data.filled && this.responded[sind][index]) {
        previousResponse = this.responded[sind][index]
      }
      this.$set(this.responded[sind], index, { html: data.html, id: data.index })
      if(oldIndex) {
        this.$delete(this.responded[sind], oldIndex)
      }
      if(oldIndex && previousResponse) {
        this.$set(this.responded[sind], oldIndex, previousResponse) // swapping places
      }
      this.checkWNR()
      this.selectedWord = null
      this.$refs.taskcounter.edited = true
      this.$set(this.hovered[sind], index, false)
      this.$nextTick(this.send)
    },
    droppedBack(sind, data) {
      if(this.isLight && this.allResponded) return
      if(data.respondedIndex === undefined) return
      this.$delete(this.responded[sind], data.respondedIndex)
      this.checkWNR()
      this.$nextTick(this.send)
    },
    checkWNR() {
      this.sentences.forEach((sentence, index) => {
        if(Object.keys(this.responded[index]).length === sentence.right.length) {
          let isCorrect = this.isCorrect[index]
          this.$set(this.wnr, index, isCorrect ? 'r' : 'w')
        } else {
          this.$delete(this.wnr, index)
        }
      })
    },
    send() {
      let data = {
        responded: this.responded,
        checking: this.checking,
        tries: this.tries,
        wnr: this.wnr,
      }
      EventBus.$emit('slideTaskChangedSend', this.block.id, data)
      if(!this.homeworkReview) {
        this.saveTaskStorageBlock({
          blockId: this.block.id,
          lessonId: this.lessonId,
          materialId: this.materialId,
          type: this.viewMode,
          data
        })
      }
    },
    clear() {
      this.hovered = this.defaultSentenceValues('array')
      this.clearTasksBlock(this.block.id)
      this.$nextTick(this.send)
    },
    retrieveSavedData() {
      this.checking = this.taskStoreId.checking
      this.responded = this.taskStoreId.responded
      this.tries = this.taskStoreId.tries
      this.wnr = this.taskStoreId.wnr ? this.taskStoreId.wnr : []
      this.$nextTick(() => this.$refs.taskcounter.edited = true)
    },
    toCheck(index) {
      this.tries[index].push(this.isCorrect[index])
      this.checking[index] = true
      this.$nextTick(this.send)
    },
    parseData() {
      let sentences = this.block.content.questions
      sentences.forEach(sentence => {
        let words = sentence.content.split('|')
        let wordsObjects = []
        words.forEach((word, index) => {
          let right = word[0] !== '*'
          wordsObjects.push({ html: right ? word : word.substring(1), counter: index, right })
        })
        this.sentences.push({
          right: JSON.parse(JSON.stringify(wordsObjects.filter(x => x.right))),
          shuffled: shuffleArray(wordsObjects)
        })
      })
      this.responded = this.defaultSentenceValues('object')
      this.hovered = this.defaultSentenceValues('array')
      this.parsed = true
      this.retrieveSavedData()
    },
    defaultSentenceValues(type = 'object') {
      let arr = []
      let defValues = {'object': {}, 'array': [], 'boolean': false}
      this.sentences.forEach(() => {
        arr.push(defValues[type])
      })
      return JSON.parse(JSON.stringify(arr))
    },
    wordCanBeDragged(b, sind) {
      if(!this.usedWords[sind]) return true
      let found = this.usedWords[sind].find(x => x && x.id === b.counter)
      return !found
    },
    clickedDrag(item, sentenceIndex, wordIndex) {
      // console.log(item)
      if(this.isLight && this.allResponded) return
      if(this.selectedWord !== null && this.selectedWord.sentenceIndex === sentenceIndex && this.selectedWord.wordIndex === wordIndex) {
        this.selectedWord = null
        return
      }
      this.selectedWord = {
        sentenceIndex,
        wordIndex,
        item
      }
    },
    clickedDrop(item, sentenceIndex) {
      if(!this.selectedWord || this.selectedWord.sentenceIndex !== sentenceIndex) {
        return
      }
      this.dropped(sentenceIndex, item.html, item.counter, this.selectedWord.item)
    },
    clickBack(data, sind) {
      if(this.isLight && this.allResponded) return
      this.droppedBack(sind, data)
    }
  },
  watch: {
    allResponded(val) {
      this.$emit('solved', val, this.wnr)
      if(this.isLight && val) {
        for(const i in this.sentences) {
          this.checking[i] = true
        }
      }
    },
    taskStoreId: {
      handler() {
        this.retrieveSavedData()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.drag-image {
  display: inline-block;
  background: #FFFFFF;
  box-shadow: 0 1px 3px rgba(105, 114, 121, 0.25);
  border-radius: 10px;
  padding: 8px 12px;
}
.drag-words-wrapper {
  text-align: center;
}
.full-right {
  span {
    margin: 0 2px;
  }
}
</style>





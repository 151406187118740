<!--Type missing letters-->
<template>
  <div class="task" v-if="parsed">
    <TaskCounter :block="block"
                 :counter="counter"
                 :showAnswers="host"
                 @refresh="clear"
                 ref="taskcounter"/>
    <div class="word-teaser">
      <div class="word-teaser-content" :class="{ isLight }">
        <div v-if="block.content.descr" class="word-description">
          {{ block.content.descr }}
        </div>
        <div class="text-center mt-3">
          <template v-for="(s, sind) in symbols">
            <span class="symbol" :key="`symbol-${sind}`">
              <input :ref="`input-${sind}`"
                     @input="inputKey($event, sind, s)"
                     :class="{
                       right: ((finished && showAnswers) || showEngGoResponses) && responded[s.taskIndex] && s.content === responded[s.taskIndex],
                       wrong: ((finished && showAnswers) || showEngGoResponses) && responded[s.taskIndex] && s.content !== responded[s.taskIndex]
                     }"
                     :disabled="finished || showEngGoResponses"
                     :value="responded[s.taskIndex]"
                     v-if="s.type == 'task'"/>
              <span v-else>{{ s.content }}</span>
            </span>
          </template>
        </div>
        <TaskCheckButton v-if="showAnswers && allResponded && !homeworkReview"
                         class="mt-4 text-center"
                         :tries="tries"
                         :disabled="tries && tries.length >= triesLimit"
                         @taskCheck="toCheck"
        />

      </div>
    </div>
  </div>
</template>

<script>

import TaskCounter from "@/views/parts/constructor/parts/TaskCounter"
import EventBus from '@/services/event-bus'
import { mapActions, mapMutations, mapState } from "vuex"
import TaskMixin from "./TaskMixin"
import TaskCheckButton from "./parts/TaskCheckButton.vue"

export default {
  components: {
    TaskCheckButton,
    TaskCounter,
  },
  mixins: [TaskMixin],
  props: {
    host: { type: Boolean, default: false },
    source: { type: String, default: "preview" },
    block: { type: Object, default: null },
    counter: { type: String, default: null },
    ind: {},
  },
  data() {
    return {
      symbols: [],
      finished: false,
      parsed: false,
      responded: [],
      wnr: [],
      tries: [],
    }
  },
  computed: {
    showAnswers() {
      if(this.isLight) return true
      return this.host || (this.block.content.show_answers && !this.isEnggoMethodics)
    },
    ...mapState({
      tasksStore: state => state.call.tasksStore,
      lessonId: state   => state.call.lessonId,
      materialId: state => state.call.materialId,
      viewMode: state   => state.call.viewMode,
      selfStudyReviewed: state  => state.call.selfStudyReviewed,
    }),
    showEngGoResponses() {
      return (!this.host && this.$store.state.call.blocksShowingResponses.includes(this.block.id)) || this.selfStudyReviewed
    },
    allResponded() {
      return this.responded.filter(x => x).length === this.symbols.filter(s => s.type === 'task').length
    },
    triesLimit() {
      return this.isLight ? 1 : 3
    },
    taskStoreId() {
      return this.tasksStore[this.block.id] ? this.tasksStore[this.block.id] : {
        finished: false,
        responded: [],
        wnr: [],
        tries: [],
      }
    },
  },
  mounted() {
    this.parseData()
    EventBus.$on('slideTaskChangedReceive', (cid, data) => {
      if(this.block.id == cid) {
        this.responded = data.responded
        this.wnr = data.wnr
        this.tries = data.tries
        this.finished = data.finished
        this.saveTaskStorageBlock({
          blockId: this.block.id,
          lessonId: this.lessonId,
          materialId: this.materialId,
          type: this.viewMode,
          data: data
        })
      }
    })
    EventBus.$on('taskClear', this.clear)
  },
  methods: {
    ...mapMutations([
      'clearTasksBlock',
    ]),
    ...mapActions([
      'saveTaskStorageBlock',
    ]),
    toCheck() {
      this.$refs.taskcounter.edited = true
      this.finished = true
      if(this.finished) {
        this.$emit('solved', true, this.wnr)
      }
      this.tries.push(Object.values(this.wnr).every(val => val === 'r'))
      // this.checkWNR()
      this.send()
    },
    send() {
      let data = {
        responded: this.responded,
        tries: this.tries,
        finished: this.finished,
        wnr: this.wnr
      }
      EventBus.$emit('slideTaskChangedSend', this.block.id, data)
      if(this.viewMode != 'homeworkReview') {
        this.saveTaskStorageBlock({
          blockId: this.block.id,
          lessonId: this.lessonId,
          materialId: this.materialId,
          type: this.viewMode,
          data: data
        })
      }
    },
    clear() {
      this.clearTasksBlock(this.block.id)
      this.$nextTick(this.send)
    },
    retrieveSavedData() {
      this.responded = this.taskStoreId.responded
      this.wnr = this.taskStoreId.wnr
      this.finished = this.taskStoreId.finished
      this.$nextTick(() => this.$refs.taskcounter.edited = true)
    },
    parseData() {
      const text = this.block.content.text
      let taskIndex = -1
      if(text && text.length) {
        for (let i = 0; i < text.length; i++) {
          const char = text.charAt(i)
          if(char === '*') continue
          const nextChar = (i + 1) < text.length ? text.charAt(i + 1) : null
          if(nextChar === '*') taskIndex++
          this.symbols.push({
            type: nextChar === '*' ? 'task' : 'html',
            content: char,
            taskIndex: taskIndex
          })
        }
      }
      this.parsed = true
      this.retrieveSavedData()
    },
    async inputKey(val, index, item) {
      const symbol = val.target.value
      const inputType = val.inputType
      const taskIndex = item.taskIndex
      if(symbol.length > 1 && inputType === 'insertText') return
      const regex = /^[0-9A-Za-z()<>{}%#@!'+-]*$/
      if(!regex.test(symbol)) return
      let nextIndex = null
      if(inputType === 'deleteContentBackward') {
          this.$set(this.responded, taskIndex, null)
          this.$set(this.wnr, taskIndex, null)
          nextIndex = this.findPreviousInput(index)
      } else {
          this.$set(this.responded, taskIndex, symbol)
          this.$set(this.wnr, taskIndex, item.content === symbol ? 'r' : 'w')
          nextIndex = this.findNextInput(index)
      }
      this.$refs[`input-${nextIndex}`]?.[0].focus()
      this.$refs[`input-${nextIndex}`]?.[0].select()
      await this.$nextTick()
      if(this.allResponded) this.send()
    },
    findPreviousInput(index) {
      let nextIndex = null
      for (let i = (index - 1); i >= 0; i--) {
        if(this.symbols[i]?.type === 'task') {
          nextIndex = i
          break
        }
      }
      return nextIndex
    },
    findNextInput(index) {
      let nextIndex = null
      for (let i = (index + 1); i < this.symbols.length; i++) {
        if(this.symbols[i]?.type === 'task') {
          nextIndex = i
          break
        }
      }
      return nextIndex
    },
  },
  watch: {
    finished() {
      this.$emit('solved', true, this.wnr)
    },
    taskStoreId: {
      handler() {
        this.retrieveSavedData()
      }
    }
  }

}
</script>

<style scoped lang="scss">
.symbol {
  input {
    border: none;
    width: 16px;
    background: transparent;
    border-bottom: 1px solid #B5B5B5;
    border-radius: 0;
    outline: none;
    text-align: center;
    padding: 0;
    color: #B5B5B5;
    &.right {
      color: #27AE60;
      border-bottom: 1px solid #27AE60;
    }
    &.wrong {
      color: #FF143A;
      border-bottom: 1px solid #FF143A;
    }
  }
  span {
    width: 16px;
    text-align: center;
    display: inline-block;
  }
}
.isLight {
  .symbol {
    font-size: 24px;
    font-weight: 400;
    input, span {
      width: 20px;
    }
  }
}
@media(max-width: 768px) {
}
</style>






<!--Choose right option from dropdown-->
<template>
  <div class="task" v-if="parsed" :class="`view-${alignView}`">
    <TaskCounter :block="block"
                 :counter="counter"
                 :showAnswers="showAnswers && host"
                 @refresh="clear"
                 ref="taskcounter"/>
    <template v-for="(b, bind) in blocks">
      <template v-if="b.type === 'html'">{{ b.html }}</template>
      <template v-if="b.type === 'missing'">
        <div class="d-inline-block position-relative" :key="'task-'+block.id+'-'+bind">
          <input type="text"
                 :class="{
                    'mr-3': tries && tries[b.counter] && block.content.show_answers && !isEnggoMethodics,
                    right: (showAnswers || showEngGoResponses || homeworkReview) && responded[b.counter] === 'r',
                    wrong: (showAnswers || homeworkReview) && responded[b.counter] === 'w',
                    responded: !(showAnswers || homeworkReview) && responded[b.counter]
                }"
                 v-if="!showEngGoResponses || responded[b.counter] === 'r'"
                 v-autowidth="{ maxWidth: '250px', minWidth: '50px', comfortZone: 0 }"
                 @focus="activeIndex = b.counter"
                 @blur="activeIndex = null"
                 readonly
                 :disabled="responded[b.counter] === 'r' || (responded[b.counter] && !showHint)"
                 v-model="responses[b.counter]"
                 class="form-control task-input"/>
          <div class="enggo-correction" v-else>
            <span class="wrong">{{ responses[b.counter] }}</span>
            <span class="correct">{{ b.correct }}</span>
          </div>
          <TaskCheckBubbles class="position-absolute check-bubbles"
                            v-if="showAnswers && block.content.show_answers && !isLight"
                            :tries="(tries && tries[b.counter]) ? tries[b.counter] : []"/>
          <div class="position-absolute spacecard nopad options-dropdown" v-if="activeIndex == b.counter">
            <a v-for="(opt, oind) in b.options"
               @mousedown="selectedOption(b.counter, opt.html, opt.correct)"
               class="dropdown-option nowrap"
               :key="'opt-'+b.counter+'-'+oind">{{ opt.html }}</a>
          </div>
          <a class="task-hint"
             v-if="showHint"
             :class="{ shown: activeIndex === b.counter && (host || source != 'class') }"
             v-b-tooltip.hover :title="$t('correct_answer')"
             @mousedown='showcorrect(b.counter, b.correct)'>💡</a>
        </div>
      </template>
    </template>
  </div>
</template>

<script>

import TaskCounter from "@/views/parts/constructor/parts/TaskCounter"
import EventBus from '@/services/event-bus'
import { mapActions, mapMutations, mapState } from "vuex"
import TaskCheckBubbles from "./parts/TaskCheckBubbles"
import TaskMixin from "./TaskMixin"

export default {
  components: {
    TaskCheckBubbles,
    TaskCounter
  },
  mixins: [TaskMixin],
  props: {
    host: { type: Boolean, default: false },
    source: { type: String, default: "preview" },
    block: { type: Object, default: null },
    counter: { type: String, default: null },
    ind: {},
  },
  data() {
    return {
      responses: [],
      responded: [],
      wnr: [],
      tries: {},
      activeIndex: null,
      parsed: false,
      blocks: []
    }
  },
  computed: {
    allResponded() {
      return this.responded.length === this.tasks
    },
    tasks() {
      return this.blocks.reduce((acc, cur) => cur.type === 'missing' ? ++acc : acc, 0)
    },
    showAnswers() {
      if(this.isLight) return true
      return this.host || (this.block.content.show_answers && !this.isEnggoMethodics)
    },
    showHint() {
      if(this.isLight) return false
      return this.showAnswers
    },
    ...mapState({
      tasksStore: state => state.call.tasksStore,
      lessonId: state   => state.call.lessonId,
      materialId: state => state.call.materialId,
      viewMode: state   => state.call.viewMode,
      selfStudyReviewed: state  => state.call.selfStudyReviewed,
    }),
    showEngGoResponses() {
      if(this.allResponded && this.isLight) return true
      return (!this.host && this.$store.state.call.blocksShowingResponses.includes(this.block.id)) || this.selfStudyReviewed
    },
    taskStoreId() {
      return this.tasksStore[this.block.id] ? this.tasksStore[this.block.id] : {
        responses: [],
        responded: [],
        wnr: [],
        tries: {},
      }
    },
  },
  mounted() {
    this.parseData()
    EventBus.$on('slideTaskChangedReceive', (cid, data) => {
      if(this.block.id == cid) {
        this.responded = data.responded
        this.responses = data.responses
        this.tries = data.tries
        this.wnr = data.wnr
        this.saveTaskStorageBlock({blockId: this.block.id, lessonId: this.lessonId, materialId: this.materialId, type: this.viewMode, data: data})
      }
    })
    EventBus.$on('taskClear', this.clear)
    this.$emit('solved', this.allResponded, this.wnr)
  },
  methods: {
    ...mapMutations([
      'clearTasksBlock',
    ]),
    ...mapActions([
      'saveTaskStorageBlock',
    ]),
    selectedOption(index, option, iscorrect) {
      let tries = this.tries[index] ? this.tries[index] : []
      if(tries.length >= 3) return
      if(!this.wnr) this.wnr = []
      this.activeIndex = null
      this.$set(this.responses, index, option)
      this.$set(this.responded, index, (iscorrect ? 'r' : 'w'))
      this.$set(this.wnr, index, (iscorrect ? 'r' : 'w'))
      if(this.isEnggoMethodics) {
        tries = [iscorrect]
      } else {
        tries.push(iscorrect)
      }
      this.$set(this.tries, index, tries)
      this.send()
    },
    send() {
      let data = {
        responded: this.responded,
        responses: this.responses,
        tries: this.tries,
        wnr: this.wnr,
      }
      EventBus.$emit('slideTaskChangedSend', this.block.id, data)
      if(this.viewMode != 'homeworkReview') {
        this.saveTaskStorageBlock({blockId: this.block.id, lessonId: this.lessonId, materialId: this.materialId, type: this.viewMode, data: data})
      }
    },
    showcorrect(index, correct) {
      this.$set(this.responses, index, correct)
      this.$set(this.responded, index, 'r')
      this.$set(this.wnr, index, 'r')
      this.changed()
      this.activeIndex = null
    },
    clear() {
      this.activeIndex = null
      this.clearTasksBlock(this.block.id)
      this.$nextTick(this.send)
    },
    retrieveSavedData() {
      this.responses = this.taskStoreId.responses
      this.responded = this.taskStoreId.responded
      this.tries = this.taskStoreId.tries
      this.wnr = this.taskStoreId.wnr
      this.$nextTick(() => this.$refs.taskcounter.edited = true)
    },
    parseData() {
      let text = this.block.content.text
      let tmp_str = ''
      let cnt = 0
      let options = []
      let correct_html = ''
      for (var i = 0; i < text.length; i++) {
        let char = text.charAt(i)
        if(char === '[') {
          if(tmp_str) {
            this.blocks.push({
              type: 'html',
              html: tmp_str
            })
            correct_html = ''
            tmp_str = ''
          }
          options = []
          continue
        }
        if(char === '|' || char === ']') {
          let last = tmp_str.slice(-1)
          let correct = false
          if(last == '*') {
            correct = true
            tmp_str = tmp_str.slice(0, -1)
            correct_html = tmp_str
          }
          options.push({
            html: tmp_str,
            correct: correct
          })
          tmp_str = ''
          if(char === ']') {
            this.blocks.push({
              type: 'missing',
              options: options,
              correct: correct_html,
              counter: cnt
            })
            options = []
            cnt++
            continue
          }
          continue
        }

        tmp_str += char
      }
      if(tmp_str) {
        this.blocks.push({
          type: 'html',
          html: tmp_str
        })
      }
      this.parsed = true
      this.retrieveSavedData()
    }
  },
  watch: {
    allResponded(val) {
      this.$emit('solved', val, this.wnr)
    },
    taskStoreId: {
      handler() {
        this.retrieveSavedData()
      }
    }
  }
}
</script>


<style scoped>
.check-bubbles {
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  margin-top: -3px;
}
.view-center {
  text-align: center;
}
</style>



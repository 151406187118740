<!--Sort words by columns-->
<template>
  <div class="task" v-if="parsed">
    <TaskCounter :block="block"
                 :showAnswers="showAnswers && host"
                 :counter="counter"
                 @refresh="clear"
                 ref="taskcounter"/>
    <div class="">
      <drop class="drag-words-wrapper" @drop="droppedBack(...arguments)" @click.native="dragWordsClicked">
        <template v-for="(b, bind) in words">
          <drag class="d-inline-block drag-wrapper"
                :key="'drag-'+block.html+'-'+bind"
                :image-x-offset="(b.html.length * 2.5) + 12"
                :image-y-offset="20"
                :class="{
                  dragged: usedWords.includes(b.id),
                  selected: selectedWord && selectedWord.id == b.id,
                }"
                @click.native.stop="dragWordClicked(b)"
                :draggable="!usedWords.includes(b.id)"
                :transfer-data="{ html: b.html, columns: b.columns, filled: false, id: b.id }">
            <div slot="image" class="drag-image">{{ b.html }}</div>
            <template slot-scope="props">
              <div class="drag-block removed-by-dragging text-themed" v-if="props.transferData">{{ b.html }}</div>
              <div v-else class="drag-block">{{ b.html }}</div>
            </template>
          </drag>
        </template>
      </drop>
    </div>
    <div class="align-items-center">
      <b-row>
        <b-col v-for="(c, cind) in columns" :key="`column-${cind}`">
          <div class="column-card card">
            <div class="title text-center py-2 border-bottom">{{ c.title }}</div>
            <drop class="drop-field p-3 h-100"
                  :class="{ hovered: hovered[cind] === true }"
                  @dragover="$set(hovered, cind, true)"
                  @dragleave="$set(hovered, cind, false)"
                  @click.native="dropAreaClicked(cind)"
                  @drop="dropped(cind, ...arguments)">
              <div v-for="(r, rind) in responded[cind]" :key="`responded-${cind}-${r.id}-${rind}`"
                   class="text-center"
              >
                <drag class="d-inline-block task-input form-control"
                      :class="{
                        responded: responded[cind],
                        selected: selectedWord && selectedWord.id == r.id,
                        right:  (checking || showEngGoResponses || homeworkReview) && r && r.columns.includes(cind),
                        wrong:  (checking || showEngGoResponses || homeworkReview) && !(r && r.columns.includes(cind))
                      }"
                      @click.native.stop="dragWordClicked(r)"
                      :image-x-offset="(r.html.length * 2.5) + 12"
                      :image-y-offset="20"
                      :draggable="(!(checking && r.columns.includes(cind)) && !showEngGoResponses) && !(checking && isLight)"
                      :key="'drag-'+cind+'-'+r.html"
                      :transfer-data="{ html: r.html, columns: r.columns, filled: true, id: r.id }">
                  <div slot="image" class="drag-image">{{ r.html }}</div>
                  <template slot-scope="props">
                    <div class="drag-block removed-by-dragging text-themed" v-if="props.transferData">{{ r.html }}</div>
                    <div v-else class="drag-block">
                      <template v-if="(r && r.columns && r.columns.includes(cind)) || !showEngGoResponses">{{ r.html }}</template>
                      <template v-else>
                        <span class="striked">{{ r.html }}</span>
                        <!--                        <span class="text-success ml-2">{{ r.html }}</span>-->
                      </template>
                    </div>
                  </template>
                </drag>
              </div>
              <div v-if="showEngGoResponses" class="text-center">
                <template v-for="w in c.words">
                  <div v-if="!hasResponse(responded[cind], w)"
                       :key="`addit-${cind}-${w}`"
                       class="task-input form-control d-inline-block wrong">
                    <span class="text-success">{{ w }}</span>
                  </div>
                </template>
              </div>
            </drop>
          </div>
        </b-col>
      </b-row>
    </div>
    <TaskCheckButton v-if="showAnswers && allResponded && !homeworkReview"
                     class="mt-3"
                     :tries="tries"
                     :disabled="tries && tries.length >= triesLimit"
                     @taskCheck="toCheck"
    />
  </div>
</template>

<script>

import TaskCounter from "@/views/parts/constructor/parts/TaskCounter"
import EventBus from '@/services/event-bus'
import Vue from 'vue'
import VueDragDrop from 'vue-drag-drop'
import { mapActions, mapMutations, mapState } from "vuex"
import TaskCheckButton from "./parts/TaskCheckButton"
import { shuffleArray } from "../../../helpers/shuffleArray"
import TaskMixin from "./TaskMixin"
Vue.use(VueDragDrop)

export default {
  components: {
    TaskCheckButton,
    TaskCounter
  },
  mixins: [TaskMixin],
  props: {
    host: {
      type: Boolean,
      default: false
    },
    source: {
      type: String,
      default: "preview"
    },
    block: {
      type: Object,
      default: null
    },
    counter: {
      type: String,
      default: null
    },
    ind: {},
  },
  data() {
    return {
      responded: [],
      wnr: [],
      checking: false,
      tries: [],
      hovered: [],
      parsed: false,
      columns: [],
      words: [],
      selectedWord: null,
    }
  },
  computed: {
    allResponded() {
      return this.usedWords.length === this.words.length
    },
    showAnswers() {
      if(this.isLight) return true
      return this.host || (this.block.content.show_answers && !this.isEnggoMethodics)
    },
    triesLimit() {
      return this.isLight ? 1 : 3
    },
    ...mapState({
      tasksStore: state => state.call.tasksStore,
      lessonId: state   => state.call.lessonId,
      materialId: state => state.call.materialId,
      viewMode: state   => state.call.viewMode,
      selfStudyReviewed: state  => state.call.selfStudyReviewed,
    }),
    isCorrect() {
      if(!this.allResponded) return false
      let isCorrect = true
      this.responded.forEach((response, cind) => {
        response.forEach(word => {
          if(!word.columns.includes(cind)) isCorrect = false
        })
      })
      return isCorrect
    },
    showEngGoResponses() {
      return (!this.host && this.$store.state.call.blocksShowingResponses.includes(this.block.id)) || this.selfStudyReviewed
    },
    taskStoreId() {
      return this.tasksStore[this.block.id] ? this.tasksStore[this.block.id] : {
        responded: this.defaultSentenceValues('array'),
        wnr: [],
        checking: false,
        tries: []
      }
    },
    usedWords() {
      let arr = []
      this.responded.forEach(words => {
        arr = arr.concat(words.map(x => x.id))
      })
      return arr
    },
  },
  mounted() {
    this.parseData()
    EventBus.$on('slideTaskChangedReceive', (cid, data) => {
      if(this.block.id == cid) {
        this.responded = data.responded
        this.checking = data.checking
        this.tries = data.tries
        this.wnr = data.wnr
        this.saveTaskStorageBlock({
          blockId: this.block.id,
          lessonId: this.lessonId,
          materialId: this.materialId,
          type: this.viewMode,
          data: data
        })
      }
    })
    EventBus.$on('taskClear', this.clear)
    this.$emit('solved', this.allResponded, this.wnr)
  },
  methods: {
    ...mapMutations([
      'clearTasksBlock',
    ]),
    ...mapActions([
      'saveTaskStorageBlock',
    ]),
    dropped(columnIndex, data) {
      this.checking = false
      this.removeWordFromResponded(data.id)
      this.responded[columnIndex].push(data)
      this.checkWNR()
      this.$refs.taskcounter.edited = true
      this.$set(this.hovered, columnIndex, false)
      this.$nextTick(this.send)
      this.selectedWord = null
    },
    droppedBack(data) {
      this.removeWordFromResponded(data.id)
      this.$nextTick(this.send)
      this.selectedWord = null
    },
    dragWordsClicked() {
      if(this.selectedWord) {
        this.droppedBack(this.selectedWord)
      }
    },
    removeWordFromResponded(wordId) {
      let newResponded = []
      this.responded.forEach(responses => {
        let arr = [...new Set(responses.filter(el => el.id !== wordId))]
        newResponded.push(arr)
      })
      this.responded = newResponded
    },
    defaultSentenceValues(type = 'object') {
      let arr = []
      let defValues = {'object': {}, 'array': [], 'boolean': false}
      this.columns.forEach(() => {
        arr.push(defValues[type])
      })
      return JSON.parse(JSON.stringify(arr))
    },
    checkWNR() {
      this.columns.forEach((column, cind) => {
        let right = (this.responded[cind].filter(x => x.columns.includes(cind))).length
        // console.log(right)
        // console.log(column.words.length)
        this.$set(this.wnr, cind, right >= column.words.length ? 'r' : 'w')
      })
    },
    hasResponse(responses, wordHtml) {
      let x = responses.find(x => x.html === wordHtml)
      return !!x
    },
    send() {
      let data = {
        responded: this.responded,
        checking: this.checking,
        tries: this.tries,
        wnr: this.wnr,
      }
      EventBus.$emit('slideTaskChangedSend', this.block.id, data)
      if(!this.homeworkReview) {
        this.saveTaskStorageBlock({blockId: this.block.id, lessonId: this.lessonId, materialId: this.materialId, type: this.viewMode, data: data})
      }
    },
    clear() {
      this.hovered = []
      this.clearTasksBlock(this.block.id)
      this.$nextTick(this.send)
    },
    retrieveSavedData() {
      this.checking = this.taskStoreId.checking
      this.responded = this.taskStoreId.responded
      this.tries = this.taskStoreId.tries
      this.wnr = this.taskStoreId.wnr ? this.taskStoreId.wnr : []
      this.$nextTick(() => this.$refs.taskcounter.edited = true)
      if(this.isLight && this.checking) {
        this.$emit('solved', true, this.wnr)
      }
    },
    toCheck() {
      this.tries.push(this.isCorrect)
      this.checking = true
      if(this.isLight) this.$emit('solved', true, this.wnr)
      this.$nextTick(() => {
        this.send()
      })
    },
    dragWordClicked(word) {
      if(this.checking && this.isLight) return
      if(this.selectedWord && this.selectedWord.id == word.id) {
        this.selectedWord = null
        return
      }
      this.selectedWord = word
    },
    dropAreaClicked(columnIndex) {
      if(!this.selectedWord) return
      this.dropped(columnIndex, this.selectedWord)
    },
    parseData() {
      let columns = this.block.content.questions
      let counter = 1
      let wordsCount = {}
      columns.forEach((column, index) => {
        let words = column.content.split('|')
        column.words = words
        words.forEach(word => {
          this.words.push({ html: word, columns: [index], id: counter })
          if(!wordsCount[word]) { wordsCount[word] = [] }
          wordsCount[word].push(index)
          counter++
        })
        this.responded.push([])
        this.columns.push(column)
      })
      for(const w in wordsCount) {
        if(wordsCount[w].length > 1) {
          this.words.forEach(word => {
            if(word.html === w) {
              word.columns = wordsCount[w]
            }
          })
        }
      }
      this.words = shuffleArray(this.words)
      this.parsed = true
      this.retrieveSavedData()
    },
  },
  watch: {
    allResponded(val) {
      if(this.isLight) return
      this.checking = false
      this.$emit('solved', val, this.wnr)
    },
    taskStoreId: {
      handler() {
        this.retrieveSavedData()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.task-input {
  display: inline-flex !important;
  flex-direction: column;
  width: auto;
  min-width: 100px;
  justify-content: center;
  height: auto;
  &.selected {
    color: #bbb;
  }
}
.drop-field.hovered {
  background: #F8F9FB;
}
</style>





<!-- Insert missing by typing -->
<template>
  <div class="task" v-if="parsed" :class="`view-${alignView}`">
    <TaskCounter :block="block"
                 :counter="counter"
                 :showAnswers="showAnswers && host"
                 @refresh="clear"
                 ref="taskcounter"/>
    <b-row class="align-items-center">
      <b-col :lg="block.content.img ? 5 : 12" :md="block.content.img ? 6 : 12"
             class="text-content">
        <template v-for="(b, bind) in blocks">
          <span v-if="b.type === 'html'" v-html="b.html" :key="'task-span-'+block.id+'-'+bind"></span>
          <!--            <template v-if="b.type === 'html'" :key="'bl-'+bind" v-html="b.html"></template>-->
          <template v-if="b.type === 'missing'">
            <div class="d-inline-block position-relative"
                 :key="'task-'+block.id+'-'+bind">
              <input type="text"
                     :class="{
                    right: ((checking) || showEngGoResponses || homeworkReview) && (responded[b.counter] === 'r' || b.correct.includes(responses[b.counter])),
                    wrong: ((checking) || homeworkReview) && (responded[b.counter] === 'w'),
                    responded: !((checking) || homeworkReview) && responded[b.counter],
                 }"
                     v-if="!showEngGoResponses || responded[b.counter] === 'r' ||  b.correct.includes(responses[b.counter])"
                     v-autowidth="{ maxWidth: '250px', minWidth: '50px', comfortZone: 0 }"
                     @focus="activeIndex = b.counter"
                     @keyup.enter="check(b.counter, b.correct)"
                     @keyup="changed(b.counter, b.correct)"
                     :disabled="!!((tries && tries.length >= triesLimit) || showEngGoResponses)"
                     @blur='check(b.counter, b.correct)'
                     v-model="responses[b.counter]"
                     class="form-control task-input"/>
              <div class="enggo-correction" v-else>
                <span class="wrong">{{ responses[b.counter] }}</span>
                <span class="correct">{{ b.correct[0] }}</span>
              </div>
              <a class="task-hint"
                 v-if="showHint"
                 :class="{ shown: activeIndex === b.counter && (host || source != 'class') }"
                 v-b-tooltip.hover
                 :title="$t('correct_answer')"
                 @mousedown='showcorrect(b.counter, b.correct)'>💡</a>
            </div>
          </template>
        </template>
      </b-col>
      <b-col v-if="block.content.img" lg="7" md="6">
        <div class="text-center mt-2">
          <img class="img st__input"
               :href="block.content.img"
               :src="block.content.img"
               :alt="block.content.title"/>
        </div>
      </b-col>
    </b-row>
    <TaskCheckButton v-if="showAnswers && allResponded && !(homeworkReview && !block.content.show_answers)"
                     :tries="tries"
                     :disabled="tries && tries.length >= triesLimit"
                     @taskCheck="toCheck"
    />


  </div>
</template>

<script>

import TaskCounter from "@/views/parts/constructor/parts/TaskCounter"
import EventBus from '@/services/event-bus'
import { mapActions, mapMutations, mapState } from "vuex"
import TaskCheckButton from "./parts/TaskCheckButton"
import TaskMixin from "./TaskMixin"

export default {
  components: {
    TaskCounter,
    TaskCheckButton
  },
  mixins: [TaskMixin],
  props: {
    host: {
      type: Boolean,
      default: false
    },
    source: {
      type: String,
      default: "preview"
    },
    block: {
      type: Object,
      default: null
    },
    counter: {
      type: String,
      default: null
    },
    ind: {},
  },
  data() {
    return {
      responses: [],
      responded: [],
      wnr: [],
      tries: [],
      activeIndex: null,
      parsed: false,
      blocks: [],
      checking: false,
    }
  },
  computed: {
    allResponded() {
      return this.responded.length === this.tasks
    },
    showAnswers() {
      if(this.isLight) return true
      return this.host || (this.block.content.show_answers && !this.isEnggoMethodics)
    },
    showHint() {
      if(this.isLight) return false
      return this.showAnswers
    },
    triesLimit() {
      return this.isLight ? 1 : 3
    },
    ...mapState({
      tasksStore: state => state.call.tasksStore,
      lessonId: state   => state.call.lessonId,
      materialId: state => state.call.materialId,
      viewMode: state   => state.call.viewMode,
      methodics: state  => state.call.methodics,
      selfStudyReviewed: state  => state.call.selfStudyReviewed,
    }),
    tasks() {
      return this.blocks.reduce((acc, cur) => cur.type === 'missing' ? ++acc : acc, 0)
    },
    showEngGoResponses() {
      return (!this.host && this.$store.state.call.blocksShowingResponses.includes(this.block.id)) || this.selfStudyReviewed
    },
    isCorrect() {
      if(!this.allResponded) return false
      let rightCount = this.responded.filter(x => x == 'r').length
      return rightCount >= this.tasks
    },
    taskStoreId() {
      return this.tasksStore[this.block.id] ? this.tasksStore[this.block.id] : {
        responses: [],
        responded: [],
        checking: false,
        wnr: [],
        tries: [],
      }
    },
  },
  mounted() {
    this.parseData()
    EventBus.$on('slideTaskChangedReceive', (cid, data) => {
      if(this.block.id == cid) {
        this.responded = data.responded
        this.responses = data.responses
        this.checking = data.checking
        this.tries = data.tries
        this.wnr = data.wnr
        this.saveTaskStorageBlock({
          blockId: this.block.id,
          lessonId: this.lessonId,
          materialId: this.materialId,
          type: this.viewMode,
          data: data
        })
      }
    })
    EventBus.$on('taskClear', this.clear)
    if(this.isLight && !this.tries.length) return
    this.$emit('solved', this.allResponded, this.wnr)
  },
  methods: {
    ...mapMutations([
      'clearTasksBlock',
    ]),
    ...mapActions([
      'saveTaskStorageBlock',
    ]),
    check(index, correct) {
      this.activeIndex = null
      if(!this.responses[index]) {
        this.$delete(this.responded, index)
        return
      }
      let response = this.replaceIgnoredSymbols(this.responses[index].trim())
      let isCorrect = correct.includes(response)
      this.$set(this.responded, index, (isCorrect ? 'r' : 'w'))
      this.$set(this.wnr, index, (isCorrect ? 'r' : 'w'))
      this.send()
    },
    send() {
      let data = {
        responded: this.responded,
        responses: this.responses,
        tries: this.tries,
        checking: this.checking,
        wnr: this.wnr
      }
      EventBus.$emit('slideTaskChangedSend', this.block.id, data)
      if(this.viewMode != 'homeworkReview') {
        this.saveTaskStorageBlock({
          blockId: this.block.id,
          lessonId: this.lessonId,
          materialId: this.materialId,
          type: this.viewMode,
          data: data
        })
      }
    },
    changed(index, correct) {
      this.checking = false
      this.$refs.taskcounter.edited = true
      if(this.isEnggoMethodics) {
        this.check(index, correct)
      }
    },
    showcorrect(index, correct) {
      this.$refs.taskcounter.edited = true
      this.$set(this.responses, index, correct[0])
      this.activeIndex = null
    },
    clear() {
      this.clearTasksBlock(this.block.id)
      this.activeIndex = null
      this.$nextTick(this.send)
    },
    retrieveSavedData() {
      this.responses = this.taskStoreId.responses
      this.responded = this.taskStoreId.responded
      this.checking = this.taskStoreId.checking
      this.tries = this.taskStoreId.tries
      this.wnr = this.taskStoreId.wnr
      this.$nextTick(() => this.$refs.taskcounter.edited = true)
    },
    toCheck() {
      this.tries.push(this.isCorrect)
      this.checking = true
      if(this.isLight) {
        this.$nextTick(() => {
          this.send()
          this.$emit('solved', true, this.wnr)
        })
      }
    },
    parseData() {
      let text = this.block.content.text
      let tmp_str = ''
      let cnt = 0
      if(text && text.length) {
        for (var i = 0; i < text.length; i++) {
          let char = text.charAt(i)
          if(char === '[') {
            if(tmp_str) {
              this.blocks.push({
                type: 'html',
                html: this.getBold(tmp_str)
              })
              tmp_str = ''
            }
            continue
          }
          if(char === ']') {
            if(tmp_str) {
              const correctArray = tmp_str.split('|').map((v) => {
                return this.replaceIgnoredSymbols(v)
              })
              this.blocks.push({
                type: 'missing',
                html: tmp_str,
                correct: correctArray,
                // correct: tmp_str,
                counter: cnt
              })
              tmp_str = ''
              cnt++
            }
            continue
          }
          tmp_str += char
        }
      }
      if(tmp_str) {
        this.blocks.push({
          type: 'html',
          html: this.getBold(tmp_str)
        })
      }
      this.parsed = true
      this.retrieveSavedData()
    },
    replaceIgnoredSymbols(str) {
      return str.toLowerCase().replace('.', '').replace('!', '').replace('?', '').replace('`', '\'').replace('’', '\'')
    },
    getBold(string) {
      var count = (string.match(/\*/g) || []).length;
      if(count > 1) {
        let s = string.replace(/\*([\s\S]*?)\*/i, "<b>$1</b>");
        return this.getBold(s)
      }
      return string;
    }
  },
  watch: {
    allResponded(val) {
      this.checking = false
      if(this.isLight && !this.tries.length) return
      this.$emit('solved', val, this.wnr)
    },
    taskStoreId: {
      handler() {
        this.retrieveSavedData()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.check-bubbles {
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  margin-top: -3px;
}
.enggo-correction {
  line-height: normal;
  height: auto;
  min-height: 36px;
  align-items: center;
  display: inline-flex;
  vertical-align: middle;
  flex-direction: row;
  max-width: none;
}
.view-center {
  .text-content {
    text-align: center;
  }
  ::v-deep .task-check-btn {
    justify-content: center;
  }
}
</style>







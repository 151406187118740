<!--True or false-->
<template>
  <div class="task" v-if="parsed">
    <TaskCounter :block="block"
                 :showAnswers="showAnswers && host"
                 :counter="counter"
                 @refresh="clear"
                 ref="taskcounter"/>
    <div v-for="(s, sind) in statements"
         :key="`statement-${sind}`"
         :class="{'mt-3': sind > 0}">
      <div class="mb-2 text-center">{{ s.statement }}</div>
      <div class="text-center">
        <template v-for="(m, mind) in multiples">
          <button class="task-input form-control mx-2 px-4"
                  :disabled="responded[sind]"
                  :class="{
                    responded: responded[sind] && responded[sind] === m,
                    right:  (showAnswers || showEngGoResponses || homeworkReview) && (responded[sind] && m === s.right),
                    wrong:  (showAnswers || showEngGoResponses || homeworkReview) && (responded[sind] === m && responded[sind] !== s.right)
                  }"
                  :key="`multiple-${sind}-${mind}`"
                  @click="selectedResponse(sind, m)">
            <span v-if="m === 'true' && block.content.true_title" class="mr-2">{{ block.content.true_title }}</span>
            <span v-else-if="m === 'false' && block.content.false_title" class="mr-2">{{ block.content.false_title }}</span>
            <span v-else class="mr-2">{{ $t(m) }}</span>
            <span>{{ icons[mind] }}</span>
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

import TaskCounter from "@/views/parts/constructor/parts/TaskCounter"
import EventBus from '@/services/event-bus'
import {mapActions, mapMutations, mapState} from "vuex"
import TaskMixin from "./TaskMixin"

export default {
  components: {
    TaskCounter
  },
  mixins: [TaskMixin],
  props: {
    host: {
      type: Boolean,
      default: false
    },
    source: {
      type: String,
      default: "preview"
    },
    block: {
      type: Object,
      default: null
    },
    counter: {
      type: String,
      default: null
    },
    ind: {},
  },
  data() {
    return {
      responded: [],
      wnr: [],
      tries: [],
      parsed: false,
      statements: [],
      multiples: ['true','false','unknown'],
      icons: ["👍","👎","🤷‍"]
    }
  },
  computed: {
    allResponded() {
      return this.statements.length > 0 && (this.responded.map(x => x)).length === this.statements.length
    },
    showAnswers() {
      if(this.isLight) return true
      return this.host || (this.block.content.show_answers && !this.isEnggoMethodics)
    },
    ...mapState({
      tasksStore: state => state.call.tasksStore,
      lessonId: state   => state.call.lessonId,
      materialId: state => state.call.materialId,
      viewMode: state   => state.call.viewMode,
      selfStudyReviewed: state  => state.call.selfStudyReviewed,
    }),
    showEngGoResponses() {
      return (!this.host && this.$store.state.call.blocksShowingResponses.includes(this.block.id)) || this.selfStudyReviewed
    },
    taskStoreId() {
      return this.tasksStore[this.block.id] ? this.tasksStore[this.block.id] : {
        responded: [],
        wnr: [],
        tries: []
      }
    },
  },
  mounted() {
    this.parseData()
    EventBus.$on('slideTaskChangedReceive', (cid, data) => {
      if(this.block.id == cid) {
        this.responded = data.responded
        this.tries = data.tries
        this.wnr = data.wnr
        this.saveTaskStorageBlock({blockId: this.block.id, lessonId: this.lessonId, materialId: this.materialId, type: this.viewMode, data: data})
      }
    })
    if(!this.block.content.allow_unknown) {
      this.multiples.splice(-1)
    }
    EventBus.$on('taskClear', this.clear)
    this.$emit('solved', this.allResponded, this.wnr)
  },
  methods: {
    ...mapMutations([
      'clearTasksBlock',
    ]),
    ...mapActions([
      'saveTaskStorageBlock',
    ]),
    selectedResponse(statementIndex, response) {
      if(this.responded[statementIndex]) return
      this.$set(this.responded, statementIndex, response)
      this.checkWNR()
      this.$refs.taskcounter.edited = true
      this.$nextTick(this.send)
    },
    checkWNR() {
      this.statements.forEach((statement, statementIndex) => {
        let userResponse = this.responded[statementIndex]
        this.$set(this.wnr, statementIndex, userResponse === statement.right ? 'r' : 'w')
      })
    },
    send() {
      let data = {
        responded: this.responded,
        tries: this.tries,
        wnr: this.wnr,
      }
      EventBus.$emit('slideTaskChangedSend', this.block.id, data)
      if(this.viewMode != 'homeworkReview') {
        this.saveTaskStorageBlock({blockId: this.block.id, lessonId: this.lessonId, materialId: this.materialId, type: this.viewMode, data: data})
      }
    },
    clear() {
      this.clearTasksBlock(this.block.id)
      this.$nextTick(this.send)
    },
    retrieveSavedData() {
      this.responded = this.taskStoreId.responded
      this.tries = this.taskStoreId.tries
      this.wnr = this.taskStoreId.wnr ? this.taskStoreId.wnr : []
      this.$nextTick(() => this.$refs.taskcounter.edited = true)
    },
    parseData() {
      this.statements = this.block.content.questions
      this.parsed = true
      this.retrieveSavedData()
    },
  },
  watch: {
    allResponded(val) {
      this.$emit('solved', val, this.wnr)
    },
    taskStoreId: {
      handler() {
        this.retrieveSavedData()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.task-input.form-control {
  width: auto;
  height: 46px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  &:focus {
    box-shadow: none;
  }
  &.responded {
    background: $gray-back3;
  }
}
</style>





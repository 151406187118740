<!--Word cards task-->
<template>
  <div class="task" v-if="parsed">
    <TaskCounter :block="block"
                 :showAnswers="showAnswers && host"
                 :counter="counter"
                 @refresh="clear"
                 ref="taskcounter"/>
    <drop class="drag-words-wrapper" @click.native="dragWordsClicked">
      <template v-for="(c, cind) in shuffledWords">
        <drag class="d-inline-block drag-wrapper"
              :key="`word-${cind}`"
              :image-x-offset="((c.word ? c.word.length : 0) * 2.5) + 12"
              :image-y-offset="20"
              :class="{
                  dragged: usedWords.includes(c.id),
                  selected: selectedWord && selectedWord.id == c.id,
                }"
              @click.native.stop="dragWordClicked(c)"
              :draggable="!usedWords.includes(c.id)"
              :transfer-data="{ ...c }">
          <div slot="image" class="drag-image">{{ c.word }}</div>
          <template slot-scope="props">
            <div class="drag-block removed-by-dragging text-themed" v-if="props.transferData">{{ c.word }}</div>
            <div v-else class="drag-block">{{ c.word }}</div>
          </template>
        </drag>
      </template>
    </drop>


    <swiper ref="homeSwiper" :options="swiperOptions">
      <swiper-slide v-for="(c, cind) in unusedImages"
                    :key="`slide-${cind}`">
        <drop :key="`img-${cind}`"
              @click.native="dropped(c, selectedWord)"
              @drop="dropped(c, ...arguments)"
              class="d-inline-flex word-card h-100 flex-column justify-content-between">
          <img :src="c.img" alt=""/>
          <div class="card-input"></div>
        </drop>
      </swiper-slide>
      <div class="swiper-button-prev d-md-inline-block d-none"
           v-if="unusedImages.length"
           slot="button-prev">
        <LeftSvg/>
      </div>
      <div class="swiper-button-next d-md-inline-block d-none"
           v-if="unusedImages.length"
           slot="button-next">
        <RightSvg/>
      </div>
    </Swiper>

    <hr class="mt-0"/>

    <div>
      <b-row>
        <template v-for="(c, cind) in responded">
          <b-col lg="3"
                 md="4"
                 cols="6"
                 class="mb-2"
                 :key="`resp-${cind}`">
            <div @click="removeWordFromResponded(cind)"
                 class="d-flex word-card pointer h-100 justify-content-between flex-column">
              <img :src="c.img.img" alt=""/>
              <div class="card-input responded"
                   :class="{
                   right:  (showAnswers || showEngGoResponses || homeworkReview) && (c.img.id === c.word.id),
                   wrong:  (showAnswers || showEngGoResponses || homeworkReview) && (c.img.id !== c.word.id)
               }">
                {{ c.word.word }}
              </div>
            </div>
          </b-col>
        </template>
      </b-row>
    </div>

  </div>
</template>

<script>

import Vue from 'vue'
import VueDragDrop from 'vue-drag-drop'
import TaskCounter from "@/views/parts/constructor/parts/TaskCounter"
import EventBus from '@/services/event-bus'
import { mapActions, mapMutations, mapState } from "vuex"
import { shuffleArray } from "../../../helpers/shuffleArray"
Vue.use(VueDragDrop)
import LeftSvg from "@/assets/svg/swiper_left.svg"
import RightSvg from "@/assets/svg/swiper_right.svg"
import TaskMixin from "./TaskMixin"

export default {
  components: {
    TaskCounter,
    RightSvg,
    LeftSvg
  },
  mixins: [TaskMixin],
  props: {
    host: {
      type: Boolean,
      default: false
    },
    source: {
      type: String,
      default: "preview"
    },
    block: {
      type: Object,
      default: null
    },
    counter: {
      type: String,
      default: null
    },
    ind: {},
  },
  data() {
    return {
      responded: [],
      shuffledWords: [],
      shuffledImages: [],
      wnr: [],
      tries: [],
      parsed: false,
      cards: [],
      selectedWord: null,
      swiper: null,
      swiperOptions: {
        navigation: {
          nextEl: `.swiper-button-next`,
          prevEl: `.swiper-button-prev`,
        },
        spaceBetween: 20,
        // centeredSlides: true,
        // slidesOffsetBefore: -50,
        // loopedSlides: 1,
        // initialSlide: 1,
        // loop: true,
        breakpoints: {
          0: {
            slidesPerView: 2.2,
          },
          768: {
            slidesPerView: 3.2,
          },
          1024: {
            slidesPerView: 4.2,
            // slidesPerView: 10,
          }
        }
      }
    }
  },
  computed: {
    allResponded() {
      return this.cards.length > 0 && (this.responded.map(x => x)).length === this.cards.length
    },
    showAnswers() {
      if(this.isLight && this.allResponded) return true
      return this.host || (this.block.content.show_answers && !this.isEnggoMethodics)
    },
    ...mapState({
      tasksStore: state => state.call.tasksStore,
      lessonId: state   => state.call.lessonId,
      materialId: state => state.call.materialId,
      viewMode: state   => state.call.viewMode,
      selfStudyReviewed: state  => state.call.selfStudyReviewed,
      lessonModesEnabled: state => state.call.lessonModesEnabled,
      lessonModeFull: state => state.call.lessonModeFull,
    }),
    showEngGoResponses() {
      return (!this.host && this.$store.state.call.blocksShowingResponses.includes(this.block.id)) || this.selfStudyReviewed
    },
    taskStoreId() {
      return this.tasksStore[this.block.id] ? this.tasksStore[this.block.id] : {
        responded: [],
        wnr: [],
        tries: []
      }
    },
    usedWords() {
      return this.responded.map(x => x.word.id)
    },
    usedImages() {
      return this.responded.map(x => x.img.id)
    },
    unusedImages() {
      return this.shuffledImages.filter(x => !this.usedImages.includes(x.id))
    }
  },
  mounted() {
    this.parseData()
    EventBus.$on('slideTaskChangedReceive', (cid, data) => {
      if(this.block.id == cid) {
        this.responded = data.responded
        this.tries = data.tries
        this.wnr = data.wnr
        this.saveTaskStorageBlock({
          blockId: this.block.id,
          lessonId: this.lessonId,
          materialId: this.materialId,
          type: this.viewMode,
          data: data
        })
      }
    })
    EventBus.$on('taskClear', this.clear)
    this.$emit('solved', this.allResponded, this.wnr)
  },
  methods: {
    ...mapMutations([
      'clearTasksBlock',
    ]),
    ...mapActions([
      'saveTaskStorageBlock',
    ]),
    dropped(image, word) {
      if(!word) return
      // this.checking = false
      this.responded.push({
        word: word,
        img: image
      })
      this.checkWNR()
      this.$refs.taskcounter.edited = true
      // this.$set(this.hovered, columnIndex, false)
      this.$nextTick(this.send)
      this.selectedWord = null
    },
    checkWNR() {
      this.cards.forEach((card, cardId) => {
        let userResponse = this.responded.find(r => r.word.id === cardId)
        this.$set(this.wnr, cardId, userResponse && userResponse.word.id === userResponse.img.id ? 'r' : 'w')
      })
    },
    dragWordsClicked() {
      if(this.selectedWord) {
        this.selectedWord = null
      }
    },
    dragWordClicked(word) {
      if(this.selectedWord && this.selectedWord.id == word.id) {
        this.selectedWord = null
        return
      }
      this.selectedWord = word
    },
    removeWordFromResponded(index) {
      if(this.isLight && this.allResponded) return
      this.responded.splice(index, 1)
    },
    send() {
      let data = {
        responded: this.responded,
        tries: this.tries,
        wnr: this.wnr,
      }
      EventBus.$emit('slideTaskChangedSend', this.block.id, data)
      if(this.viewMode != 'homeworkReview') {
        this.saveTaskStorageBlock({
          blockId: this.block.id,
          lessonId: this.lessonId,
          materialId: this.materialId,
          type: this.viewMode,
          data: data
        })
      }
    },
    clear() {
      this.clearTasksBlock(this.block.id)
      this.$nextTick(this.send)
    },
    retrieveSavedData() {
      this.responded = this.taskStoreId.responded
      this.tries = this.taskStoreId.tries
      this.wnr = this.taskStoreId.wnr ? this.taskStoreId.wnr : []
      this.$nextTick(() => this.$refs.taskcounter.edited = true)
    },
    parseData() {
      this.cards = this.block.content.questions
      this.cards.forEach((c, cind) => {
        c.id = cind
      })
      this.shuffledWords = shuffleArray(JSON.parse(JSON.stringify(this.cards)))
      this.shuffledImages = shuffleArray(JSON.parse(JSON.stringify(this.cards)))
      this.parsed = true
      this.retrieveSavedData()
    },
    updateSwiper() {
      if(this.$refs.homeSwiper) {
        this.$refs.homeSwiper.updateSwiper()
      }
    }
  },
  watch: {
    allResponded(val) {
      this.$emit('solved', val, this.wnr)
    },
    taskStoreId: {
      handler() {
        this.retrieveSavedData()
      }
    },
    lessonModesEnabled: {
      handler() {
        this.updateSwiper()
      },
      deep: true,
    },
    lessonModeFull() {
      this.updateSwiper()
    }
  }
}
</script>

<style scoped lang="scss">
.swiper-container {
  padding-bottom: 15px;
}
.swiper-slide {
  height: auto;
}
.swiper-button-prev, .swiper-button-next {
  width: auto;
  &::after {
    content: none;
  }
}
.drag-image {
  display: inline-block;
  background: #FFFFFF;
  box-shadow: 0 1px 3px rgba(105, 114, 121, 0.25);
  border-radius: 10px;
  padding: 8px 12px;
}
.word-card {
  background: #FFFFFF;
  box-shadow: 0px 1px 8px -2px rgba(31, 42, 50, 0.2);
  border-radius: 9px;
  padding-bottom: 10px;
  text-align: center;
  img {
    //height: 120px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 8px;
  }
  .card-input {
    background: #FFFFFF;
    border: 1px solid #DADFEB;
    border-radius: 10px;
    height: 36px;
    width: 80%;
    margin: 10px auto 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    &.wrong {
      border-color: $theme-red;
    }
    &.right {
      border-color: $text-success;
    }
  }
}
.swiper-button-disabled {
  display: none !important;
}
@media(max-width: 768px) {
  .word-card {
    img {
      //height: 94px;
    }
  }
}
</style>





var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.parsed)?_c('div',{staticClass:"task"},[_c('TaskCounter',{ref:"taskcounter",attrs:{"block":_vm.block,"showAnswers":_vm.showAnswers && _vm.host,"counter":_vm.counter},on:{"refresh":_vm.clear}}),_c('div',{},[_c('drop',{staticClass:"drag-words-wrapper",on:{"drop":function($event){return _vm.droppedBack.apply(void 0, arguments)}},nativeOn:{"click":function($event){return _vm.dragWordsClicked.apply(null, arguments)}}},[_vm._l((_vm.words),function(b,bind){return [_c('drag',{key:'drag-'+_vm.block.html+'-'+bind,staticClass:"d-inline-block drag-wrapper",class:{
                dragged: _vm.usedWords.includes(b.id),
                selected: _vm.selectedWord && _vm.selectedWord.id == b.id,
              },attrs:{"image-x-offset":(b.html.length * 2.5) + 12,"image-y-offset":20,"draggable":!_vm.usedWords.includes(b.id),"transfer-data":{ html: b.html, columns: b.columns, filled: false, id: b.id }},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.dragWordClicked(b)}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.transferData)?_c('div',{staticClass:"drag-block removed-by-dragging text-themed"},[_vm._v(_vm._s(b.html))]):_c('div',{staticClass:"drag-block"},[_vm._v(_vm._s(b.html))])]}}],null,true)},[_c('div',{staticClass:"drag-image",attrs:{"slot":"image"},slot:"image"},[_vm._v(_vm._s(b.html))])])]})],2)],1),_c('div',{staticClass:"align-items-center"},[_c('b-row',_vm._l((_vm.columns),function(c,cind){return _c('b-col',{key:("column-" + cind)},[_c('div',{staticClass:"column-card card"},[_c('div',{staticClass:"title text-center py-2 border-bottom"},[_vm._v(_vm._s(c.title))]),_c('drop',{staticClass:"drop-field p-3 h-100",class:{ hovered: _vm.hovered[cind] === true },on:{"dragover":function($event){return _vm.$set(_vm.hovered, cind, true)},"dragleave":function($event){return _vm.$set(_vm.hovered, cind, false)},"drop":function($event){
              var i = arguments.length, argsArray = Array(i);
              while ( i-- ) argsArray[i] = arguments[i];
return _vm.dropped.apply(void 0, [ cind ].concat( argsArray ))}},nativeOn:{"click":function($event){return _vm.dropAreaClicked(cind)}}},[_vm._l((_vm.responded[cind]),function(r,rind){return _c('div',{key:("responded-" + cind + "-" + (r.id) + "-" + rind),staticClass:"text-center"},[_c('drag',{key:'drag-'+cind+'-'+r.html,staticClass:"d-inline-block task-input form-control",class:{
                      responded: _vm.responded[cind],
                      selected: _vm.selectedWord && _vm.selectedWord.id == r.id,
                      right:  (_vm.checking || _vm.showEngGoResponses || _vm.homeworkReview) && r && r.columns.includes(cind),
                      wrong:  (_vm.checking || _vm.showEngGoResponses || _vm.homeworkReview) && !(r && r.columns.includes(cind))
                    },attrs:{"image-x-offset":(r.html.length * 2.5) + 12,"image-y-offset":20,"draggable":(!(_vm.checking && r.columns.includes(cind)) && !_vm.showEngGoResponses) && !(_vm.checking && _vm.isLight),"transfer-data":{ html: r.html, columns: r.columns, filled: true, id: r.id }},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.dragWordClicked(r)}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.transferData)?_c('div',{staticClass:"drag-block removed-by-dragging text-themed"},[_vm._v(_vm._s(r.html))]):_c('div',{staticClass:"drag-block"},[((r && r.columns && r.columns.includes(cind)) || !_vm.showEngGoResponses)?[_vm._v(_vm._s(r.html))]:[_c('span',{staticClass:"striked"},[_vm._v(_vm._s(r.html))])]],2)]}}],null,true)},[_c('div',{staticClass:"drag-image",attrs:{"slot":"image"},slot:"image"},[_vm._v(_vm._s(r.html))])])],1)}),(_vm.showEngGoResponses)?_c('div',{staticClass:"text-center"},[_vm._l((c.words),function(w){return [(!_vm.hasResponse(_vm.responded[cind], w))?_c('div',{key:("addit-" + cind + "-" + w),staticClass:"task-input form-control d-inline-block wrong"},[_c('span',{staticClass:"text-success"},[_vm._v(_vm._s(w))])]):_vm._e()]})],2):_vm._e()],2)],1)])}),1)],1),(_vm.showAnswers && _vm.allResponded && !_vm.homeworkReview)?_c('TaskCheckButton',{staticClass:"mt-3",attrs:{"tries":_vm.tries,"disabled":_vm.tries && _vm.tries.length >= _vm.triesLimit},on:{"taskCheck":_vm.toCheck}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
<!--Connect words from 2 columns-->
<template>
  <div class="task" v-if="parsed">
    <TaskCounter :block="block"
                 :counter="counter"
                 @refresh="clear"
                 :showAnswers="host"
                 ref="taskcounter"/>
    <div class="link-words-wrapper">
      <b-row>
        <b-col cols="6">
          <div class="pr-3">
            <template v-for="(q, qind) in leftwords">
              <div @click="selectWord(q)"
                   v-if="q.left"
                   class="left-h"
                   :style="{ height: heights[qind]+'px' }"
                   :data-h="`left-${qind}`"
                   :key="'left'+block.id+'-'+qind">
                <div class="d-flex justify-content-end">
                  <div class="word-block mb-2"
                       :ref="`left-h-${qind}`"
                       :class="{
                    active: selectedWord && selectedWord.direction == q.direction && selectedWord.left == q.left,
                    responded: (tries[q.ind] && tries[q.ind].length >= triesLimit),
                    wrong: (tries[q.ind] && tries[q.ind].length >= triesLimit && !tries[q.ind][(triesLimit - 1)]) || ((showEngGoResponses || homeworkReview) && !responded.filter(x => x.i === q.ind).length),
                    grayed: (tries[q.ind] && !showAnswers),
                    solved: (showAnswers || showEngGoResponses || homeworkReview) && responded.filter(x => x.i === q.ind).length
                }">
                    <b-row no-gutters>
                      <b-col>
                        <span v-if="q.audio">
                      <AudioSvg class="svg-blue svg-icon-md"/>
                    </span>
                        <span v-else>{{ q.left }}</span>
                      </b-col>
                      <b-col cols="auto">
                        <span class="number-responded ml-2" :class="{ active: !!tries[q.ind] }">
                          {{ tries[q.ind] ? (qind + 1) : '' }}
                        </span>
                      </b-col>
                    </b-row>
                  </div>
                  <TaskCheckBubbles class="mb-1"
                                    :showSpace="true"
                                    v-if="showAnswers && !(homeworkReview && !block.content.show_answers) && !isLight"
                                    :key="`counter-${q.ind}-${keyCounter}`"
                                    :tries="tries[q.ind] ? tries[q.ind] : []"/>
                </div>
              </div>
            </template>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="link-words-border" :key="`right-counter-${keyCounter}`">
            <template v-for="(q, qind) in rightwords">
              <div @click="selectWord(q)"
                   v-if="q.right"
                   class="right-h"
                   :style="{ height: heights[qind]+'px' }"
                   :data-h="`right-${qind}`"
                   :key="'right'+block.id+'-'+qind">
                <div class="word-block mb-2"
                     :ref="`right-h-${qind}`"
                     :class="{
                      active: selectedWord && selectedWord.direction == q.direction && selectedWord.left == q.left && selectedWord.subind == q.subind,
                      wrong: (tries[q.ind] && tries[q.ind].length >= triesLimit && !tries[q.ind][(triesLimit - 1)]) || ((showEngGoResponses || homeworkReview) && !responded.filter(x => x.i === q.ind).length),
                      grayed: (isRightResponded(q) && !showAnswers),
                      solved: (showAnswers || showEngGoResponses || homeworkReview) && responded.filter(x => x.i === q.ind && x.s === q.subind).length
                    }">
                  <b-row no-gutters>
                    <b-col cols="auto">
                      <span class="number-responded mr-2"
                            :class="{ active: isRightResponded(q) }">
                        {{ isRightResponded(q) ? (qind + 1) : '' }}
                      </span>
                    </b-col>
                    <b-col>
                      <template v-if="!showEngGoResponses || responded.filter(x => x.i === q.ind).length">{{ q.right }}</template>
                      <template v-else>
                        <span class="striked">{{ q.right }}</span>
                        <span class="text-success ml-2">{{ findRightAnswer(qind) }}</span>
                      </template>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </template>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>

import TaskCounter from "@/views/parts/constructor/parts/TaskCounter"
import EventBus from '@/services/event-bus'
import { mapActions, mapMutations, mapState } from "vuex"
import TaskCheckBubbles from "./parts/TaskCheckBubbles"
import { shuffleArray } from "../../../helpers/shuffleArray"
import AudioSvg from '@/assets/svg/audio4.svg'
import {generateAudio} from "../../../helpers/generateAudio"
import TaskMixin from "./TaskMixin"

export default {
  components: {
    TaskCheckBubbles,
    TaskCounter,
    AudioSvg
  },
  mixins: [TaskMixin],
  props: {
    host: {
      type: Boolean,
      default: false
    },
    source: {
      type: String,
      default: "preview"
    },
    block: {
      type: Object,
      default: null
    },
    counter: {
      type: String,
      default: null
    },
    ind: {},
  },
  data() {
    return {
      id: this.ind,
      responded: [],
      rightResponded: [],
      wnr: [],
      finished: false,
      parsed: false,
      selectedWord: null,
      leftwords: [],
      rightwords: [],
      keyCounter: 0,
      heights: {}
    }
  },
  computed: {
    showAnswers() {
      if(this.isLight) return true
      return this.host || (this.block.content.show_answers && !this.isEnggoMethodics)
    },
    ...mapState({
      tasksStore: state => state.call.tasksStore,
      lessonId: state   => state.call.lessonId,
      materialId: state => state.call.materialId,
      viewMode: state   => state.call.viewMode,
      selfStudyReviewed: state  => state.call.selfStudyReviewed,
    }),
    showEngGoResponses() {
      return (!this.host && this.$store.state.call.blocksShowingResponses.includes(this.block.id)) || this.selfStudyReviewed
    },
    triesLimit() {
      return this.isLight ? 1 : 3
    },
    taskStoreId() {
      return this.tasksStore[this.block.id] ? this.tasksStore[this.block.id] : {
        responded: [],
        rightResponded: [],
        wnr: [],
        finished: false,
        tries: {},
      }
    },
  },
  mounted() {
    this.parseData()
    EventBus.$on('slideTaskChangedReceive', (cid, data) => {
      if(this.block.id == cid) {
        this.responded = data.responded
        this.rightResponded = data.rightResponded
        this.finished = data.finished
        this.tries = data.tries
        this.wnr = data.wnr
        this.saveTaskStorageBlock({blockId: this.block.id, lessonId: this.lessonId, materialId: this.materialId, type: this.viewMode, data: data})
      }
    })
    EventBus.$on('taskClear', this.clear)
    this.$emit('solved', this.finished, this.wnr)
    setTimeout(() => this.keyCounter++, 500)
    window.addEventListener('resize', this.styleHeights)
  },
  methods: {
    ...mapMutations([
      'clearTasksBlock',
    ]),
    ...mapActions([
      'saveTaskStorageBlock',
    ]),
    selectWord(word) {
      if(this.tries[word.ind] && this.tries[word.ind].length >= 3) return
      if(!this.showAnswers && word.direction == 'left' && this.tries[word.ind]) return
      if(!this.showAnswers && word.direction == 'right' && this.rightResponded.filter(x => x.i === word.ind && x.s === word.subind).length) return
      if(this.responded.filter(x => x.i === word.ind && x.s === word.subind).length) return
      // selected the same word
      if(this.selectedWord && this.selectedWord.ind == word.ind && this.selectedWord.subind == word.subind && this.selectedWord.direction == word.direction) {
        this.selectedWord = null
        return
      }
      // if select from the same column, just change selected word
      if(!this.selectedWord || this.selectedWord.direction == word.direction) {
        this.selectedWord = word
        return
      }
      let isCorrect = this.selectedWord.left == word.left
      let leftIndex = this.selectedWord.direction == 'left' ? this.selectedWord.ind : word.ind
      let leftSubindex = this.selectedWord.direction == 'left' ? this.selectedWord.subind : word.subind
      // let leftSubindex = this.selectedWord.direction == 'left' ? word.subind : this.selectedWord.subind
      let rightIndex = this.selectedWord.direction == 'left' ? word.ind : this.selectedWord.ind
      let rightSubindex = this.selectedWord.direction == 'left' ? word.subind : this.selectedWord.subind
      // let rightSubindex = this.selectedWord.direction == 'left' ? this.selectedWord.subind : word.subind
      if(!this.rightResponded.filter(x => x.i === rightIndex && x.s === rightSubindex).length) this.rightResponded.push({ i: rightIndex, s: rightSubindex })
      if(!this.wnr) this.wnr = []
      this.$set(this.wnr, leftIndex, isCorrect ? 'r' : 'w')
      if(!this.tries[leftIndex]) this.$set(this.tries, leftIndex, [])
      this.tries[leftIndex].push(isCorrect)
      this.keyCounter++
      if(isCorrect || (!isCorrect && this.tries[leftIndex].length >= 3)) {
        this.responded.push({ i: leftIndex, s: rightSubindex })
      }
      this.moveRightWordToLeft(leftIndex, leftSubindex, rightIndex, rightSubindex)
      this.finished = Object.keys(this.tries).length === this.leftwords.length
      this.changed()
      this.send()
      this.selectedWord = null

    },
    send() {
      let data = {
        responded: this.responded,
        rightResponded: this.rightResponded,
        finished: this.finished,
        tries: this.tries,
        wnr: this.wnr,
      }
      EventBus.$emit('slideTaskChangedSend', this.block.id, data)
      if(!this.homeworkReview) {
        this.saveTaskStorageBlock({blockId: this.block.id, lessonId: this.lessonId, materialId: this.materialId, type: this.viewMode, data: data})
      }
    },
    changed() {
      this.$refs.taskcounter.edited = true
    },
    retrieveSavedData() {
      this.finished = this.taskStoreId.finished
      this.responded = this.taskStoreId.responded
      this.rightResponded = this.taskStoreId.rightResponded ? this.taskStoreId.rightResponded : []
      this.tries = this.taskStoreId.tries
      this.wnr = this.taskStoreId.wnr
      this.$nextTick(() => this.$refs.taskcounter.edited = true)
    },
    clear() {
      this.clearTasksBlock(this.block.id)
      this.shuffle()
      this.$nextTick(this.send)
    },
    async shuffle() {
      this.rightwords = shuffleArray(this.rightwords)
      setTimeout(() => {
        this.reshuffleRightAnswers()
        this.$nextTick(this.styleHeights)
      }, 50)
    },
    reshuffleRightAnswers() {
      for(let i in this.tries) {
        let tries = this.tries[i]
        if(tries.length) {
          const lastTry = tries.at(-1)
          if(lastTry || tries.length >= 3) {
            this.moveRightWordToLeft(i)
          }
        }
      }
    },
    findRightAnswer(index) {
      // console.log(q)
      const leftWord = this.leftwords[index] ?? null
      return leftWord ? leftWord.right : ''
    },
    moveRightWordToLeft(leftIndex, leftSubindex = null, rightIndex = null, rightSubindex = null) {
      let leftWordInd = null
      console.log(leftSubindex)
      // console.log('right subindex', rightSubindex)
      this.leftwords.forEach((word, index) => {
        if(word.ind == leftIndex) leftWordInd = index
        if(!word.right) { // checking for words that do not need answer
          this.$set(this.wnr, index, this.tries && this.tries[index] && this.tries[index].length ? 'w' : 'r')
        } else {
          if(!(index in this.wnr)) this.$set(this.wnr, index, 'w')
        }
      })
      // console.log('word on left on index', leftWordInd)
      if(leftWordInd === null) return
      this.rightwords.forEach((word, index) => {
        let ind = rightIndex !== null ? rightIndex : leftIndex
        // console.log(rightSubindex)
        // console.log(word.subind)
        if(word.ind == ind && (rightSubindex === null || rightSubindex == word.subind)) {
          // console.log('right word was found on index', index)
          if(leftWordInd != index) {
            var a = this.rightwords[index], b = this.rightwords[leftWordInd]
            this.rightwords[leftWordInd] = a
            this.rightwords[index] = b
            this.keyCounter++
          }
        }
      })
      this.$nextTick(this.styleHeights)
    },
    async parseData() {
      this.block.content.questions.forEach((item, index) => {
        let left = item.word1.split("|")
        left.forEach((itm, ind) => {
          let lword = {
            left: itm,
            right: item.word2,
            direction: 'left',
            audio: item.word1_audio || false,
            audio_url: item.word1_audio_file || null,
            ind: index,
            subind: ind
          }
          this.leftwords.push(lword)
        })
        let right = item.word2.split("|")
        right.forEach((itm, ind) => {
          if(itm) {
            let rword = {
              left: item.word1,
              right: itm,
              direction: 'right',
              ind: index,
              subind: ind
            }
            this.rightwords.push(rword)
          }
        })
      })
      this.parsed = true
      this.retrieveSavedData()
      this.$nextTick(this.shuffle)
      // this.shuffle()
      // await this.$nextTick()
      // this.styleHeights()
      setTimeout(this.styleHeights, 50)
      // this.shuffle()
    },
    async playAudio(word) {
      if(!word.audio_url) {
        let audioUrl = await generateAudio(word.left,
            null,
            this.block.slide_id,
            `${this.block.id}_questions-${word.ind}-word1_audio_file_${Math.floor(Date.now() / 1000)}.mp3`,
            word.ind,
            this.block?.content?.audio_config || null
        )
        if (audioUrl) word.audio_url = audioUrl
        return
      }
      const audio = new Audio(word.audio_url)
      await audio.play()
    },
    isRightResponded(q) {
      return this.rightResponded.filter(x => x.i === q.ind && x.s === q.subind).length
    },
    styleHeights() {
      var l = Math.max(this.leftwords.length, this.rightwords.length)
      for(var i = 0; i < l; i++ ) {
        const leftH = this.$refs['left-h-'+i]?.[0]?.clientHeight || 0
        const rightH = this.$refs['right-h-'+i]?.[0]?.clientHeight || 0
        const maxH = Math.max(leftH, rightH) + 8
        this.$set(this.heights, i, maxH)
      }
    },
  },
  watch: {
    finished(val) {
      this.$emit('solved', val, this.wnr)
    },
    selectedWord(val) {
      if(val && val.audio) this.playAudio(val)
    },
    taskStoreId: {
      handler() {
        this.retrieveSavedData()
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.styleHeights)
  },
}
</script>

<style scoped lang="scss">
.number-responded {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  color: #8D949F;
  background: #FAFBFD;
  border: 1px solid #E6EAF2;
  border-radius: 5px;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  &.active {
    background: #F5FCFF;
    border: 1px solid #43B4EB;
  }
}
</style>



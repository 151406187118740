<template>
  <div class="mt-3" v-if="block.content.input">
    <AutosizeTextArea class="form-control-white"
                      :placeholder="$t('input_field')"
                      :min-h="50"
                      :max-h="400"
                      :white="true"
                      @input="textChanged"
                      v-model="text">
    </AutosizeTextArea>
  </div>
</template>

<script>
import AutosizeTextArea from "@/views/parts/general/form/AutosizeTextArea"
import EventBus from '@/services/event-bus'
import {mapActions, mapState} from "vuex"

export default {
  components: {
    AutosizeTextArea
  },
  props: {
    block: { type: Object, default: null },
  },
  data() {
    return {
      text: '',
      timer: null,
    }
  },
  computed: {
    ...mapState({
      tasksStore: state => state.call.tasksStore,
      lessonId: state => state.call.lessonId,
      materialId: state => state.call.materialId,
      viewMode: state => state.call.viewMode,
    }),
    taskStoreId() {
      return this.tasksStore[this.block.id] ? this.tasksStore[this.block.id] : {
        text: '',
      }
    },
  },
  mounted() {
    EventBus.$on('slideTaskChangedReceive', (block_id, data) => {
      if(this.block.id == block_id) {
        this.text = data.text
      }
    })
    EventBus.$on('taskCounterRefresh', (block_id) => {
      if(this.block.id == block_id) {
        this.clear()
      }
    })
    EventBus.$emit('taskCounterRefresh', this.block.id)
    this.retrieveSavedData()
  },
  methods: {
    ...mapActions([
      'saveTaskStorageBlock',
    ]),
    clear() {
      this.text = ''
    },
    retrieveSavedData() {
      this.text = this.taskStoreId.text
      // let d = TaskStorage.get(this.block.id)
      // if(d) {
      //   this.text = d.text
      this.$nextTick(() => {
        EventBus.$emit('taskCounterEdited',this.block.id)
      })
      // }
    },
    send() {
      let data = {
        text: this.text,
      }
      this.saveTaskStorageBlock({blockId: this.block.id, lessonId: this.lessonId, materialId: this.materialId, type: this.viewMode, data: data})
    },
    textChanged() {
      let d = { text: this.text }
      if(this.text) {
        EventBus.$emit('taskCounterEdited',this.block.id)
      }
      EventBus.$emit('slideTaskChangedSend', this.block.id, d)
      if(this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(this.send, 500)
    }
  },
  watch: {
    // text(val) {
    //   let d = { text: val }
    //   if(val) {
    //     EventBus.$emit('taskCounterEdited',this.block.id)
    //   }
    //   EventBus.$emit('slideTaskChangedSend', this.block.id, d)
    //   if(this.timer) clearTimeout(this.timer)
    //   this.timer = setTimeout(this.send, 500)
    // },
    taskStoreId: {
      handler() {
        this.retrieveSavedData()
      }
    }
  }
}
</script>






var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.parsed)?_c('div',{staticClass:"task",class:("view-" + _vm.alignView)},[_c('TaskCounter',{ref:"taskcounter",attrs:{"block":_vm.block,"showAnswers":_vm.host,"counter":_vm.counter},on:{"refresh":_vm.clear}}),_c('div',{},[_c('drop',{staticClass:"drag-words-wrapper",on:{"drop":function($event){return _vm.droppedBack.apply(void 0, arguments)}}},[_vm._l((_vm.shuffled),function(b,bind){return [_c('drag',{key:'drag-'+_vm.block.id+'-'+bind,staticClass:"d-inline-block drag-wrapper",class:{ dragged: _vm.usedWords.includes(b.counter), selected: _vm.selectedWord && _vm.selectedWord.index === b.counter },attrs:{"image-x-offset":(b.html.length * 2.5) + 12,"image-y-offset":20,"draggable":!_vm.usedWords.includes(b.counter),"transfer-data":{ html: b.html, index: b.counter, filled: false }},nativeOn:{"click":function($event){return _vm.dragWordClicked({ html: b.html, index: b.counter, counter: b.counter, filled: false })}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.transferData)?_c('div',{staticClass:"drag-block removed-by-dragging text-themed"},[_vm._v(_vm._s(b.html))]):_c('div',{staticClass:"drag-block"},[_vm._v(_vm._s(b.html))])]}}],null,true)},[_c('div',{staticClass:"drag-image",attrs:{"slot":"image"},slot:"image"},[_vm._v(_vm._s(b.html))])])]})],2)],1),_c('div',{staticClass:"align-items-center text-content"},[_vm._l((_vm.blocks),function(b,bind){return [(b.type === 'html')?[_vm._v(_vm._s(b.html))]:_vm._e(),(b.type === 'missing')?[_c('div',{key:'drop-'+_vm.block.id+'-'+bind,staticClass:"d-inline-block position-relative align-middle drag-wrapper-setup"},[_c('drop',{staticClass:"drop-block task-input form-control form-control-white",class:{
                  responded: _vm.responded[b.counter],
                  hovered: _vm.hovered[b.counter] === true,
                  right:  (_vm.checking || _vm.showEngGoResponses || _vm.homeworkReview) && _vm.responded[b.counter] && _vm.responded[b.counter].html == b.html,
                  wrong:  (_vm.checking || _vm.showEngGoResponses || _vm.homeworkReview) && _vm.responded[b.counter] && _vm.responded[b.counter].html != b.html
                },on:{"dragover":function($event){return _vm.$set(_vm.hovered, b.counter, true)},"dragleave":function($event){return _vm.$set(_vm.hovered, b.counter, false)},"drop":function($event){
                var i = arguments.length, argsArray = Array(i);
                while ( i-- ) argsArray[i] = arguments[i];
return _vm.dropped.apply(void 0, [ b.html, b.counter ].concat( argsArray ))}},nativeOn:{"click":function($event){return _vm.dropWordClicked(b)}}},[(_vm.responded[b.counter])?_c('drag',{key:'drag-'+_vm.block.id+'-'+bind,staticClass:"d-inline-block drag-wrapper drag-wrapper-setup",attrs:{"image-x-offset":(_vm.responded[b.counter].length * 2.5) + 12,"image-y-offset":20,"draggable":!(_vm.checking && _vm.responded[b.counter].html == b.html) && !_vm.showEngGoResponses,"transfer-data":{ html: _vm.responded[b.counter].html, index: _vm.responded[b.counter].index, counter: b.counter, filled: true }},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.dragWordClicked({ html: _vm.responded[b.counter].html, index: _vm.responded[b.counter].index, counter: b.counter, filled: true })}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.transferData)?_c('div',{staticClass:"drag-block removed-by-dragging text-themed"},[_vm._v(_vm._s(_vm.responded[b.counter].html))]):_c('div',{staticClass:"drag-block"},[(_vm.responded[b.counter].html == b.html || !_vm.showEngGoResponses)?[_vm._v(_vm._s(_vm.responded[b.counter].html))]:[_c('span',{staticClass:"striked"},[_vm._v(_vm._s(_vm.responded[b.counter].html))]),_c('span',{staticClass:"text-success ml-2"},[_vm._v(_vm._s(b.html))])]],2)]}}],null,true)},[_c('div',{staticClass:"drag-image",attrs:{"slot":"image"},slot:"image"},[_vm._v(_vm._s(_vm.responded[b.counter].html))])]):_vm._e()],1)],1)]:_vm._e()]})],2),(_vm.showAnswers && _vm.allResponded && !(_vm.homeworkReview && !_vm.block.content.show_answers) && !_vm.isLight)?_c('TaskCheckButton',{staticClass:"mt-2",attrs:{"tries":_vm.tries,"disabled":_vm.tries && _vm.tries.length >= _vm.triesLimit},on:{"taskCheck":_vm.toCheck}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
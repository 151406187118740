<!-- Multiple choice test -->
<template>
  <div class="task" v-if="parsed">
    <TaskCounter :block="block"
                 :counter="counter"
                 :showAnswers="host"
                 @refresh="clear"
                 ref="taskcounter"/>
    <template v-for="(q, qind) in block.content.questions">
      <div class="question-group"
           :key="'ques'+block.id+'-'+qind"
           :class="[{
             responded: responded.includes(qind),
             multiple: countCorrectAnswersInQuestion(q) > 1
           }, `view-${block.content.view}`]">
        <div><span v-if="!isLight">{{ (qind + 1) }}. </span>{{ q.title }}</div>
        <div class="answer-grid">
          <div class="quiz-answer"
               v-for="(a, aind) in q.answers"
               :key="'ans'+block.id+'-'+qind+'-'+aind"
               :class="{
                  shouldberight: (showAnswers || showEngGoResponses || homeworkReview) && responded.includes(qind) && !responses[qind].includes(aind) && a.correct,
                  right: (showAnswers || showEngGoResponses || homeworkReview) && responded.includes(qind) && responses[qind].includes(aind) && a.correct,
                  wrong: (showAnswers || showEngGoResponses || homeworkReview) && responded.includes(qind) && responses[qind].includes(aind) && !a.correct
               }">
            <div class="checkbox form-group" :class="{ disabled: responded.includes(qind) && showAnswers }">
              <input type="checkbox"
                     value="1"
                     :checked="responses[qind] && responses[qind].includes(aind)"
                     :disabled="responded.includes(qind) && showAnswers"
                     :id="block.id+'-'+qind+'-'+aind"
                     @input="answered(qind, aind, a.correct, countCorrectAnswersInQuestion(q), block.content.questions.length)"/>
              <label class="mb-0"
                     :for="block.id+'-'+qind+'-'+aind">
                {{ a.title }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>

import TaskCounter from "@/views/parts/constructor/parts/TaskCounter"
import EventBus from '@/services/event-bus'
import { mapState, mapMutations, mapActions } from 'vuex'
import TaskMixin from "./TaskMixin"

export default {
  components: {
    TaskCounter
  },
  mixins: [TaskMixin],
  props: {
    host: {
      type: Boolean,
      default: false
    },
    source: {
      type: String,
      default: "preview"
    },
    block: {
      type: Object,
      default: null
    },
    counter: {
      type: String,
      default: null
    },
    ind: {},
  },
  computed: {
    showAnswers() {
      if(this.isLight) return true
      return this.host || (this.block.content.show_answers && !this.isEnggoMethodics)
    },
    ...mapState({
      tasksStore: state => state.call.tasksStore,
      lessonId: state   => state.call.lessonId,
      materialId: state => state.call.materialId,
      viewMode: state   => state.call.viewMode,
      selfStudyReviewed: state  => state.call.selfStudyReviewed,
    }),
    showEngGoResponses() {
      return (!this.host && this.$store.state.call.blocksShowingResponses.includes(this.block.id)) || this.selfStudyReviewed
    },
    taskStoreId() {
      return this.tasksStore[this.block.id] ? this.tasksStore[this.block.id] : {
        right: 0,
        responses: [],
        responded: [],
        wnr: [],
        finished: false
      }
    },
  },
  data() {
    return {
      id: this.ind,
      responses: [],
      responded: [],
      wnr: [],
      correct: [],
      right: 0,
      finished: false,
      parsed: false,
      blocks: []
    }
  },
  mounted() {
    this.parseData()
    EventBus.$on('slideTaskChangedReceive', (cid, data) => {
      if(this.block.id == cid) {
        this.responded = data.responded
        this.responses = data.responses
        this.finished = data.finished
        this.right = data.right
        this.wnr = data.wnr
        this.saveTaskStorageBlock({
          blockId: this.block.id,
          lessonId: this.lessonId,
          materialId: this.materialId,
          type: this.viewMode,
          data
        })
      }
    })
    EventBus.$on('taskClear', this.clear)
    this.$emit('solved', this.finished, this.wnr)
  },
  methods: {
    ...mapMutations([
      'clearTasksBlock',
    ]),
    ...mapActions([
      'saveTaskStorageBlock',
    ]),
    answered(question_index, answer_index, correct, correct_answers, total_questions) {
      this.$refs.taskcounter.edited = true
      if(this.responded.includes(question_index)) {
        return
      }
      if(!this.responses[question_index] || this.responses[question_index].length < 1) {
        this.responses[question_index] = []
      }
      this.responses[question_index].push(answer_index)
      if(correct) {
        this.correct[question_index] = !this.correct[question_index] ? 1 : (this.correct[question_index] + 1)
      }
      if(correct_answers == this.responses[question_index].length || !this.showAnswers) {
        this.checkQuestion(question_index, correct_answers)
      }
      this.finished = this.responded.length == total_questions
      this.send()
    },
    checkQuestion(question_index) {
      let correctlyResponded = this.correct[question_index]
      if(correctlyResponded == this.responses[question_index].length) {
        this.right++
        this.$set(this.wnr, question_index, 'r')
      } else {
        this.$set(this.wnr, question_index, 'w')
      }
      this.responded.push(question_index)
    },
    countCorrectAnswersInQuestion(q) {
      return q.answers.reduce((a, v) => (v.correct ? a + 1 : a), 0)
    },
    send() {
      let data = {
        responded: this.responded,
        responses: this.responses,
        finished: this.finished,
        right: this.right,
        wnr: this.wnr,
      }
      EventBus.$emit('slideTaskChangedSend', this.block.id, data)
      if(this.viewMode != 'homeworkReview') {
        this.saveTaskStorageBlock({
          blockId: this.block.id,
          lessonId: this.lessonId,
          materialId: this.materialId,
          type: this.viewMode,
          data
        })
      }
    },
    retrieveSavedData() {
      this.responses = this.taskStoreId.responses
      this.responded = this.taskStoreId.responded
      this.finished = this.taskStoreId.finished
      this.wnr = this.taskStoreId.wnr
      this.right = this.taskStoreId.right
      this.$nextTick(() => this.$refs.taskcounter.edited = true)
    },
    clear() {
      this.clearTasksBlock(this.block.id)
      this.$nextTick(this.send)
    },
    parseData() {
      this.parsed = true
      this.retrieveSavedData()
    }
  },
  watch: {
    finished(val) {
      this.$emit('solved', val, this.wnr)
    },
    taskStoreId: {
      handler() {
        this.retrieveSavedData()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.view-button_grid, .view-button_vertical {
  .answer-grid {
    margin-top: 16px;
    .quiz-answer {
      margin-top: 0;
      label:hover {
        background: #e5e5e7;
      }
      :checked + label {
        background: #7E828C;
        color: #fff;
      }
      &.wrong {
        label {
          background: #EB5164;
          color: #fff;
        }
      }
      &.right {
        label {
          background: #80CA78;
          color: #fff;
        }
      }
    }
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(2, 1fr);
    .form-group {
      margin-bottom: 0;
      label {
        background: #F5F6F8;
        border-radius: 8px;
        height: 38px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #7E828C;
        font-weight: bold;
        font-size: 16px;
        cursor: pointer;
        &::before {
          display: none;
        }
        &::after {
          display: none;
        }
      }
    }
  }
}
.view-button_vertical {
  .answer-grid {
    max-width: 400px;
    margin: 0 auto;
    grid-template-columns: 1fr;
  }
}
</style>






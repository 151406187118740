<!--Underline words in text-->
<template>
  <div class="task" v-if="parsed">
    <TaskCounter :block="block"
                 :counter="counter"
                 :showAnswers="host"
                 @refresh="clear"
                 ref="taskcounter"/>
    <div class="position-relative">
      <div class="ts-text">
      <span v-for="(b, bind) in blocks"
            :key="`word-${bind}`"
            :class="{
              right: (finished || showEngGoResponses) && responded[bind] && b.type === responded[bind],
              wrong: (finished || showEngGoResponses) && responded[bind] && b.type !== responded[bind],
              break: b.word == '\n',
              'ts-word': b.type != 'inactive'
            }"
            v-click-outside="clickOutside"
            @click="selectWord(bind, b.word)"
            class="position-relative"><span>{{ b.word }}</span><div class="response-ts" v-if="responded[bind]" :class="{ 'resp-absolute': responded[bind] === '***' }">
          <span class="ts-line" v-if="['*', '**'].includes(responded[bind])"></span>
          <span class="ts-line mt-1" v-if="responded[bind] === '**'"></span>
          <span class="ts-circle ts-responded" v-if="responded[bind] === '***'"></span>
        </div>
        <div v-if="selectedWord === bind" class="position-absolute dropdown-ts-menu">
          <a class="dropdown-ts-item text-center" @click.stop="selectType(bind, null)"></a>
          <a class="dropdown-ts-item" v-if="hasSingleLine" @click.stop="selectType(bind, '*')">
            <span class="ts-line"></span>
          </a>
          <a class="dropdown-ts-item" v-if="hasDoubleLine" @click.stop="selectType(bind, '**')">
            <span class="ts-line"></span>
            <span class="ts-line mt-1"></span>
          </a>
          <a class="dropdown-ts-item" v-if="hasCircle" @click.stop="selectType(bind, '***')">
            <span class="ts-circle"></span>
          </a>
        </div></span>
      </div>
      <TaskCheckButton v-if="showAnswers && responded.length && !homeworkReview"
                       class="mt-3"
                       :tries="tries"
                       :disabled="tries && tries.length >= triesLimit"
                       @taskCheck="toCheck"
      />
    </div>
  </div>
</template>

<script>

import TaskCounter from "@/views/parts/constructor/parts/TaskCounter"
import EventBus from '@/services/event-bus'
import TaskCheckButton from "./parts/TaskCheckButton"
import { mapActions, mapMutations, mapState } from "vuex"
import TaskMixin from "./TaskMixin"

export default {
  components: {
    TaskCounter,
    TaskCheckButton
  },
  mixins: [TaskMixin],
  props: {
    host: { type: Boolean, default: false },
    source: { type: String, default: "preview" },
    block: { type: Object, default: null },
    counter: { type: String, default: null },
    ind: {},
  },
  data() {
    return {
      finished: false,
      showHint: false,
      parsed: false,
      blocks: [],
      responded: [],
      wnr: [],
      tries: [],
      selectedWord: null,
      justOpened: false,
      timer: null,
      hasSingleLine: false,
      hasDoubleLine: false,
      hasCircle: false
    }
  },
  computed: {
    showAnswers() {
      if(this.isLight) return true
      return this.host || (this.block.content.show_answers && !this.isEnggoMethodics)
    },
    ...mapState({
      tasksStore: state => state.call.tasksStore,
      lessonId: state   => state.call.lessonId,
      materialId: state => state.call.materialId,
      viewMode: state   => state.call.viewMode,
      selfStudyReviewed: state  => state.call.selfStudyReviewed,
    }),
    showEngGoResponses() {
      return (!this.host && this.$store.state.call.blocksShowingResponses.includes(this.block.id)) || this.selfStudyReviewed
    },
    triesLimit() {
      return this.isLight ? 1 : 3
    },
    isCorrect() {
      let isCorrect = false
      if(this.responded.length) {
        isCorrect = true
        this.responded.forEach((response, index) => {
          let block = this.blocks[index]
          let blockType = block.type == 'inactive' ? null : block.type
          if(!block || blockType !== response) {
            isCorrect = false
          }
        })
        this.blocks.forEach((block, index) => {
          let response = this.responded[index]
          let blockType = block.type == 'inactive' ? null : block.type
          if(blockType && blockType !== response) {
            isCorrect = false
          }
        })
      }
      return isCorrect
    },
    taskStoreId() {
      return this.tasksStore[this.block.id] ? this.tasksStore[this.block.id] : {
        finished: false,
        responded: [],
        wnr: [],
        tries: [],
      }
    },
  },
  mounted() {
    this.parseData()
    EventBus.$on('slideTaskChangedReceive', (cid, data) => {
      if(this.block.id == cid) {
        this.responded = data.responded
        this.tries = data.tries
        this.wnr = data.wnr
        this.finished = data.finished
        this.saveTaskStorageBlock({blockId: this.block.id, lessonId: this.lessonId, materialId: this.materialId, type: this.viewMode, data: data})
      }
    })
    EventBus.$on('taskClear', this.clear)
  },
  methods: {
    ...mapMutations([
      'clearTasksBlock',
    ]),
    ...mapActions([
      'saveTaskStorageBlock',
    ]),
    toCheck() {
      this.$refs.taskcounter.edited = true
      this.finished = true
      this.tries.push(this.isCorrect)
      this.checkWNR()
      this.$emit('solved', true, this.wnr)
      this.send()
    },
    send() {
      let data = {
        responded: this.responded,
        tries: this.tries,
        finished: this.finished,
        wnr: this.wnr
      }
      EventBus.$emit('slideTaskChangedSend', this.block.id, data)
      if(this.viewMode != 'homeworkReview') {
        this.saveTaskStorageBlock({
          blockId: this.block.id,
          lessonId: this.lessonId,
          materialId: this.materialId,
          type: this.viewMode,
          data: data
        })
      }
    },
    checkWNR() {
      this.$set(this.wnr, 0, this.isCorrect ? 'r' : 'w')
    },
    selectWord(index, word) {
      if(word == '\n') return
      if(this.finished && this.responded[index] === this.blocks[index].type) return
      if(this.finished && this.tries.length >= this.triesLimit) return
      if(this.finished && this.isCorrect) return
      if(this.selectedWord === index) {
        this.selectedWord = null
        return
      }
      this.justOpened = true
      this.selectedWord = index
      setTimeout(() => {
        this.justOpened = false
      }, 20)
    },
    clickOutside() {
      if(!this.justOpened) {
        this.selectedWord = null
      }
    },
    selectType(index, type) {
      this.$set(this.responded, index, type)
      this.finished = false
      this.selectedWord = null
      if(!this.isLight) this.$emit('solved', true, this.wnr)
      this.checkWNR()
      this.send()
    },
    clear() {
      this.clearTasksBlock(this.block.id)
      this.$nextTick(this.send)
    },
    retrieveSavedData() {
      this.responded = this.taskStoreId.responded
      this.tries = this.taskStoreId.tries
      this.wnr = this.taskStoreId.wnr
      this.finished = this.taskStoreId.finished
      this.$nextTick(() => this.$refs.taskcounter.edited = true)
      if(this.isLight && this.finished) this.$emit('solved', true, this.wnr)
      this.checkWNR()
    },
    parseData() {
      let text = this.block.content.descr
      let tmp_str = ''
      let inBracketsSpan = false
      let inLooseBracketsSpan = false
      let type = null
      if(text && text.length) {
        for (var i = 0; i < text.length; i++) {
          let char = text.charAt(i)
          if([' ', ',','\n','.','!','?'].includes(char) && !inBracketsSpan && !inLooseBracketsSpan) {
            if(tmp_str) {
              this.blocks.push({
                type: null,
                word: tmp_str
              })
              tmp_str = ''
            }
            this.blocks.push({
              type: 'inactive',
              word: char
            })
            continue
          }
          if(char === '{') {
            inLooseBracketsSpan = true
            char = ''
          }
          if(char === '}') {
            inLooseBracketsSpan = false
            char = ''
          }
          if(char === '[') {
            inBracketsSpan = true
            if(tmp_str) {
              this.blocks.push({
                type: null,
                word: tmp_str
              })
              tmp_str = ''
            }
            continue
          }
          if(char === ']') {
            inBracketsSpan = false
            if(tmp_str) {
              if (tmp_str[0] === '*') {
                tmp_str = tmp_str.slice(1);
                type = '**'
                this.hasDoubleLine = true
              } else if (tmp_str[0] === '!') {
                tmp_str = tmp_str.slice(1);
                type = '***'
                this.hasCircle = true
              } else {
                type = '*'
                this.hasSingleLine = true
              }
              this.blocks.push({
                word: tmp_str,
                type: type
              })
              tmp_str = ''
            }
            continue
          }
          tmp_str += char
        }
      }
      if(tmp_str) {
        this.blocks.push({
          type: null,
          word: tmp_str
        })
      }
      this.parsed = true
      this.retrieveSavedData()
    }
  },
  watch: {
    finished() {
      if(this.isLight) return
      this.$emit('solved', true, this.wnr)
    },
    taskStoreId: {
      handler() {
        this.retrieveSavedData()
      }
    }
  }

}
</script>

<style scoped lang="scss">
.dropdown-ts-menu {
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(31, 42, 50, 0.3);
  border-radius: 6px;
  left: 0;
  top: 100%;
  //bottom: -100%;
  width: 100%;
  min-width: 50px;
  z-index: 3;
  .dropdown-ts-item {
    padding: 12px 15px;
    border-bottom: 1px solid #DADFEB;
    display: block;
    cursor: pointer;
    &:hover {
      background: $gray-back2;
    }
    &:last-of-type {
      border-bottom: none;
    }
  }
}
.ts-word {
  cursor: pointer;
  border-radius: 3px;
  display: inline-block;
  line-height: 1.3;
  > span {
    position: relative;
    z-index: 2;
  }
  &:hover {
    background: #FBE7C9;
  }
  &.right {
    background: $light-green;
    .ts-circle.ts-responded {
      background: $light-green;
    }
  }
  &.break {
    width: 100%;
    &:hover {
      background: transparent;
    }
  }
  &.wrong {
    background: $bloody-red;
    .ts-circle.ts-responded {
      background: $bloody-red;
    }
  }
}
.response-ts {
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
  &.resp-absolute {
    top: 0;
  }
}
.ts-line {
  background: #F2AF4A;
  height: 2px;
  width: 100%;
  display: block;
}
.ts-circle {
  border: 2px solid #F2AF4A;
  background: #FFF3E0;
  height: 10px;
  width: 100%;
  display: block;
  border-radius: 8px;
  &.ts-responded {
    position: absolute;
    height: 22px;
    left: -3px;
    top: -1px;
    width: calc(100% + 6px);
  }
}
.ts-text {
  line-height: 2;
}
</style>






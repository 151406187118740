<!--Record audio-->
<template>
  <div class="task">
    <TaskCounter :block="block"
                 :counter="counter"
                 :showAnswers="host"
                 @refresh="clear"
                 ref="taskcounter"/>
    <div class="slide__text st__preface st__input"
         v-if="block.content.descr"
         v-html="block.content.descr"></div>

    <div class="text-center mt-3">
      <button @click="startRecording"
              class="btn-themed btn-white recording-btn"
              v-if="!recordingInitiated">
        {{ $t('start_recording')}}
        <RecordingSvg class="ml-2"/>
      </button>

      <div class="recording-wrapper" v-if="recordingInitiated">
        <div class="recording-fill" :style="{width: timeWidth+'%' }"></div>
        <b-row>
          <b-col cols="auto">
            <div>
              <PauseSvg @click="pause" class="pointer" v-if="isRecording || isPlaying"/>
              <a v-if="isRecording" class="ml-4 recording-action">{{ $t('recording') }}...</a>
            </div>
            <div v-if="!isRecording && !isPlaying">
              <PlaySvg @click="resume" class="pointer"/>
              <a @click="saveRecording" class="ml-4 recording-action">{{ $t('save') }}</a>
              <a @click="cancelRecording" class="ml-3 recording-action">{{ $t('cancel') }}</a>
            </div>
          </b-col>
          <b-col class="text-right">
            <div>
              <span>{{ secondsToTime(duration) }}</span>
              <span class="separator mx-3"></span>
              <span class="mr-3">{{ secondsToTime(timeLimit) }}</span>
              <RecordingSvg class="recording-icon" :class="{ pulsating: isRecording }" @click="resume"/>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>

    <div class="mt-3" v-if="!isRecording && !isPlaying">
      <audio v-if="serverUrl" controls @ended="audioEnded" :key="`audioCounter-${audioCounter}`">
        <source :src="`${serverUrl}?counter=${audioCounter}`" type="audio/mp3">
      </audio>
    </div>

  </div>
</template>

<script>

import TaskCounter from "@/views/parts/constructor/parts/TaskCounter"
import RecordingSvg from "@/assets/svg/recording.svg"
import PlaySvg from "@/assets/svg/play.svg"
import PauseSvg from "@/assets/svg/pause.svg"
// import getBlobDuration from 'get-blob-duration'
import {ConstructorService} from "../../../services/api.service"
import EventBus from '@/services/event-bus'
import {mapActions, mapMutations, mapState} from "vuex"

export default {
  components: {
    // StudentTaskText,
    TaskCounter,
    RecordingSvg,
    PlaySvg,
    PauseSvg
  },
  props: {
    host: {
      type: Boolean,
      default: false
    },
    source: {
      type: String,
      default: "preview"
    },
    block: {
      type: Object,
      default: null
    },
    counter: {
      type: String,
      default: null
    },
    ind: {},
  },
  data() {
    return {
      isRecording: false,
      isPlaying: false,
      recordingInitiated: false,
      mediaRecorder: null,
      audio: null,
      duration: 0,
      audioCounter: 0,
      timeCounter: null,
      audioChunks: [],
      serverUrl: null,
      wnr: [],
    }
  },
  computed: {
    ...mapState({
      tasksStore: state => state.call.tasksStore,
      lessonId: state   => state.call.lessonId,
      materialId: state => state.call.materialId,
      viewMode: state   => state.call.viewMode,
    }),
    taskStoreId() {
      return this.tasksStore[this.block.id] ? this.tasksStore[this.block.id] : {
        serverUrl: null,
        wnr: []
      }
    },
    timeWidth() {
      let limit = parseInt(this.block.content.time_limit)
      if(!limit) return 0
      return (this.duration / limit) * 100
    },
    timeLimit() {
      return parseInt(this.block.content.time_limit)
    }
  },
  mounted() {
    this.parseData()
    EventBus.$on('slideTaskChangedReceive', (cid, data) => {
      if(this.block.id == cid) {
        this.serverUrl = data.serverUrl
        this.wnr = data.wnr
        this.audioCounter++
        if(!this.host) {
          this.saveTaskStorageBlock({blockId: this.block.id, lessonId: this.lessonId, materialId: this.materialId, type: this.viewMode, data: data})
        }
      }
    })
    this.$emit('solved', true, this.wnr)
  },
  methods: {
    ...mapMutations([
      'clearTasksBlock',
    ]),
    ...mapActions([
      'saveTaskStorageBlock',
    ]),
    send() {
      let data = {
        serverUrl: this.serverUrl,
        wnr: this.wnr
      }
      EventBus.$emit('slideTaskChangedSend', this.block.id, data)
      if(this.viewMode != 'homeworkReview') {
        this.saveTaskStorageBlock({blockId: this.block.id, lessonId: this.lessonId, materialId: this.materialId, type: this.viewMode, data: data})
      }
    },
    clear() {
      this.clearTasksBlock(this.block.id)
      this.$nextTick(this.send)
    },
    retrieveSavedData() {
      this.serverUrl = this.taskStoreId.serverUrl
      this.audioCounter++
      this.$nextTick(() => this.$refs.taskcounter.edited = true)
    },
    parseData() {
      this.retrieveSavedData()
    },
    startRecording() {
      this.audioChunks = []
      this.duration = 0
      if(this.mediaRecorder) {
        this.recordingInitiated = true
        this.isRecording = true;
        this.mediaRecorder.start();
        return
      }
      navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
        this.recordingInitiated = true
        this.isRecording = true;
        this.mediaRecorder = new MediaRecorder(stream);
        this.mediaRecorder.start();

        this.mediaRecorder.addEventListener("dataavailable", event => {
          this.audioChunks.push(event.data);
        });

        this.mediaRecorder.addEventListener("stop", () => {
          if(this.timeCounter) clearTimeout(this.timeCounter);
        });

        this.mediaRecorder.addEventListener("start", () => {
          this.launchTimeCounter()
        });
      });
    },
    launchTimeCounter() {
      if(this.timeCounter) clearTimeout(this.timeCounter);
      this.timeCounter = setInterval(() => {
        this.duration += 0.1
        if(this.duration >= this.timeLimit) {
          this.pause()
        }
      }, 100)
    },
    pause() {
      if(this.isRecording) {
        this.mediaRecorder.stop()
        this.isRecording = false
      }
      if(this.isPlaying) {
        this.audio.pause()
        this.isPlaying = false
      }
    },
    resume() {
      if(!this.isRecording && !this.isPlaying) {
        this.isRecording = true
        this.mediaRecorder.start()
      }
    },
    async listen() {
      this.isPlaying = true
      const audioBlob = new Blob(this.audioChunks)
      const audioUrl = URL.createObjectURL(audioBlob)
      if(!this.audio) {
        this.audio = new Audio(audioUrl)
        this.audio.addEventListener("ended", () => {
          this.isPlaying = false
        })
      } else {
        this.audio.src = audioUrl
      }
      this.audio.play()
    },
    async saveRecording() {
      const blob = new Blob(this.audioChunks, {
        'type': 'audio/mp3'
      })
      const file = new File([blob], "audio.mp3")
      const formData = new FormData()
      formData.append('file', file)
      let res = await ConstructorService.saveAudio(this.block.slide_id, this.block.id, formData)
      this.serverUrl = res.data.data
      this.recordingInitiated = false
      this.audioCounter++
      this.send()
    },
    cancelRecording() {
      this.audioChunks = []
      this.duration = 0
      this.recordingInitiated = false
      ConstructorService.saveAudio(this.block.slide_id, this.block.id, (new FormData())).then(this.serverUrl = null)
    },
    secondsToTime(e){
      var m = Math.floor(e % 3600 / 60).toString().padStart(2,'0'),
          s = Math.floor(e % 60).toString().padStart(2,'0');
      return m + ':' + s
    },
    audioEnded() {
      this.audioCounter++
    }
  },
  watch: {
    serverUrl(val) {
      this.wnr = [val ? 'r' : 'w']
      this.$emit('solved', !!val, this.wnr)
    },
    taskStoreId: {
      handler() {
        this.retrieveSavedData()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.recording-wrapper {
  border: 1px solid #DADFEB;
  border-radius: 14px;
  padding: 16px 22px;
  position: relative;
  overflow: hidden;
  font-size: 14px;
  .recording-fill {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: #F9F9F9;
    transition: all 0.2s;
  }
  .recording-action {
    font-weight: 600;
    cursor: pointer;
    color: #515C5F;
  }
  .separator {
    width: 1px;
    height: 12px;
    top: 1px;
    display: inline-block;
    background: #DADFEB;
  }
  .recording-icon {
    box-shadow: 0 0 0 0 rgba(155, 36, 36, 1);
    border-radius: 50%;
    transform: scale(1);
    &.pulsating {
      animation: pulse 1.4s infinite;
    }
  }
}
@keyframes pulse {
  0% {
    transform: scale(0.8);
    box-shadow: 0 0 0 0 rgba(155, 36, 36, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 5px rgba(155, 36, 36, 0);
  }

  100% {
    transform: scale(0.8);
    box-shadow: 0 0 0 0 rgba(155, 36, 36, 0);
  }
}
</style>






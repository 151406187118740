<!--Type word by letters-->
<template>
  <div class="task" v-if="parsed">
    <TaskCounter :block="block"
                 :counter="counter"
                 :showAnswers="host"
                 @refresh="clear"
                 ref="taskcounter"/>
    <div class="word-teaser">
      <div class="word-teaser-content">
        <div v-if="block.content.descr" class="word-description">
          {{ block.content.descr }}
        </div>
        <div class="text-center" v-else-if="block.content.img">
          <img :src="block.content.img ? block.content.img : `/images/logo_h.png`"
               class="word-teaser-img"
               alt=""/>
        </div>
        <div class="text-center mt-3">
          <div v-for="(w, wind) in block.content.word"
               class="letter-blank"
               :class="{
                      right: ((finished && showAnswers) || showEngGoResponses) && responded[wind] && w === responded[wind].letter,
                      wrong: ((finished && showAnswers) || showEngGoResponses) && responded[wind] && w !== responded[wind].letter
                    }"
               @click="dropLetter(wind)"
               :key="`let-${wind}`">
            <template v-if="responded[wind]">{{ responded[wind].letter }}</template>
          </div>
        </div>
        <div class="mt-3 text-center">
          <div v-for="(w, wind) in shuffled"
               class="letter-drag"
               :class="{ active: selectedLetter && selectedLetter.index === wind }"
               @click="selectLetter(w, wind)"
               :key="`let-r-${wind}`">
            <template v-if="!responded.filter(x => x && x.index === wind).length">
              {{ w }}
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import TaskCounter from "@/views/parts/constructor/parts/TaskCounter"
import EventBus from '@/services/event-bus'
import { mapActions, mapMutations, mapState } from "vuex"
import { shuffleArray } from "../../../helpers/shuffleArray"
import TaskMixin from "./TaskMixin"

export default {
  components: {
    TaskCounter,
  },
  mixins: [TaskMixin],
  props: {
    host: { type: Boolean, default: false },
    source: { type: String, default: "preview" },
    block: { type: Object, default: null },
    counter: { type: String, default: null },
    ind: {},
  },
  data() {
    return {
      shuffled: [],
      finished: false,
      parsed: false,
      responded: [],
      wnr: [],
      selectedLetter: null,
    }
  },
  computed: {
    showAnswers() {
      return this.host || (this.block.content.show_answers && !this.isEnggoMethodics)
    },
    ...mapState({
      tasksStore: state => state.call.tasksStore,
      lessonId: state   => state.call.lessonId,
      materialId: state => state.call.materialId,
      viewMode: state   => state.call.viewMode,
      selfStudyReviewed: state  => state.call.selfStudyReviewed,
    }),
    showEngGoResponses() {
      return (!this.host && this.$store.state.call.blocksShowingResponses.includes(this.block.id)) || this.selfStudyReviewed
    },
    isCorrect() {
      let respondedWord = this.responded.map(function(item) {
        return item ? item.letter : ''
      });
      return respondedWord.join('') == this.block.content.word
    },
    taskStoreId() {
      return this.tasksStore[this.block.id] ? this.tasksStore[this.block.id] : {
        finished: false,
        responded: [],
        // shuffled: [],
        wnr: [],
      }
    },
  },
  mounted() {
    this.parseData()
    EventBus.$on('slideTaskChangedReceive', (cid, data) => {
      if(this.block.id == cid) {
        this.responded = data.responded
        this.wnr = data.wnr
        this.finished = data.finished
        // this.shuffled = data.shuffled
        this.saveTaskStorageBlock({
          blockId: this.block.id,
          lessonId: this.lessonId,
          materialId: this.materialId,
          type: this.viewMode,
          data: data
        })
      }
    })
    EventBus.$on('taskClear', this.clear)
  },
  methods: {
    ...mapMutations([
      'clearTasksBlock',
    ]),
    ...mapActions([
      'saveTaskStorageBlock',
    ]),
    toCheck() {
      this.$refs.taskcounter.edited = true
      this.finished = this.responded.filter(x => x).length === this.block.content.word.length
      if(this.finished) {
        this.$emit('solved', true, this.wnr)
      }
      this.checkWNR()
      this.send()
    },
    send() {
      let data = {
        responded: this.responded,
        finished: this.finished,
        wnr: this.wnr
      }
      EventBus.$emit('slideTaskChangedSend', this.block.id, data)
      if(this.viewMode != 'homeworkReview') {
        this.saveTaskStorageBlock({
          blockId: this.block.id,
          lessonId: this.lessonId,
          materialId: this.materialId,
          type: this.viewMode,
          data: data
        })
      }
    },
    checkWNR() {
      this.$set(this.wnr, 0, this.isCorrect ? 'r' : 'w')
    },
    selectLetter(letter, index) {
      if(this.isLight && this.finished) return
      if(this.responded.filter(x => x && x.index === index).length) return
      if(this.selectedLetter && this.selectedLetter.index === index) {
        this.selectedLetter = null
        return
      }
      this.selectedLetter = { letter, index }
    },
    dropLetter(index) {
      if(this.isLight && this.finished) return
      if(!this.selectedLetter) {
        if(this.responded[index] && this.responded[index].letter) {
          this.$set(this.responded, index, null)
          this.toCheck()
        }
        return
      }
      this.$set(this.responded, index, this.selectedLetter)
      this.toCheck()
      this.selectedLetter = null
    },
    clear() {
      this.clearTasksBlock(this.block.id)
      this.$nextTick(this.send)
    },
    retrieveSavedData() {
      this.responded = this.taskStoreId.responded
      this.wnr = this.taskStoreId.wnr
      this.finished = this.taskStoreId.finished
      // this.shuffled = this.taskStoreId.shuffled
      this.$nextTick(() => this.$refs.taskcounter.edited = true)
      this.checkWNR()
    },
    parseData() {
      let word = this.block.content.word
      this.shuffled = shuffleArray(Array.from(word))
      this.parsed = true
      this.retrieveSavedData()
    }
  },
  watch: {
    finished() {
      this.$emit('solved', true, this.wnr)
    },
    taskStoreId: {
      handler() {
        this.retrieveSavedData()
      }
    }
  }

}
</script>

<style scoped lang="scss">
.word-teaser {
  max-width: 480px;
  margin: 0 auto;
  //box-shadow: 0px 2px 16px -4px rgba(131, 136, 140, 0.23);
  height: 100%;
  //padding: 20px;
  position: relative;
  border-radius: 16px;
  .word-description {
    color: $text-themed;
    min-height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F5F6F8;
    border-radius: 4px;
    padding: 10px;
    width: 80%;
    min-width: calc(min(100%, 240px));
    margin: 0 auto;
  }
  .word-teaser-img {
    height: 140px;
    width: 100%;
    object-fit: contain;
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    border-radius: 4px;
  }
  .letter-blank, .letter-drag {
    width: 38px;
    height: 38px;
    vertical-align: middle;
    border-radius: 8px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 2px;
    cursor: pointer;
    font-size: 21px;
    font-weight: bold;
  }
  .letter-blank {
    background: #FFFFFF;
    border: 1px solid #E9EBF0;
    &.right {
      border-color: $text-success;
    }
    &.wrong {
      border-color: $theme-red;
    }
  }
  .letter-drag {
    background: #F5F6F8;
    color: #7E828C;
    &.active {
      background-color: $gray-fill;
    }
  }
  .word {
    &.title {
      font-weight: bold;
      font-size: 28px;
    }
    &.title-md {
      font-weight: bold;
      font-size: 18px;
    }
  }
  &-content {
    .b-bottom {
      padding-bottom: 12px;
      border-bottom: 1px solid $border-gray;
      .word {
        font-size: 15px;
        font-weight: 500;
      }
    }
  }
}


@media(max-width: 768px) {
  .word-teaser {
    &-content {
      padding: 8px 8px 0 8px;
      border-radius: 14px;
    }
  }
}
</style>






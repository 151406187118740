<!--Find errors in text-->
<template>
  <div class="task" v-if="parsed">
    <TaskCounter :block="block"
                 :counter="counter"
                 :showAnswers="host"
                 @refresh="clear"
                 ref="taskcounter"/>
    <div class="position-relative">
      <autosize-text-area v-if="!finished && !showEngGoResponses"
                          class="form-control-white find-errors-textarea"
                          :min-h="50"
                          :max-h="400"
                          :white="true"
                          @input="textareaInput"
                          @focus="focus"
                          @blur="blur"
                          v-model="text">
      </autosize-text-area>

      <div v-if="(finished || showEngGoResponses || homeworkReview)">
        <div class="mt-2">Correct version</div>
        <div class="textarea-fantom form-control form-control-white find-errors-textarea"
             :class="{ 'mt-3': homeworkReview}">
          <template v-for="(b, bind) in blocks.blocks">
            <template v-if="b.type === 'html'">{{ b.html }}</template>
            <template v-if="b.type === 'error'">
              <del :key="'del-'+bind"
                   v-if="!showEngGoResponses || (wnr[b.counter] && wnr[b.counter] === 'w')"
                   class="text-danger">{{ b.html }}</del>
              <span :key="'span-'+bind" class="text-success font-weight-bold">{{ b.correct }}</span>
            </template>
          </template>
        </div>

        <div class="mt-2">Student version</div>
        <div class="textarea-fantom form-control form-control-white find-errors-textarea" v-html="textByBlocks"></div>
      </div>

      <a class="task-hint"
         v-if="showAnswers"
         :class="{ shown: showHint }"
         v-b-tooltip.hover :title="$t('correct_answer')"
         @mousedown="check(true)">💡</a>

      <TaskCheckButton v-if="isLight"
                       class="mt-2"
                       :tries="0"
                       :disabled="finished"
                       @taskCheck="check"
      />
    </div>
  </div>
</template>

<script>

import TaskCounter from "@/views/parts/constructor/parts/TaskCounter"
import EventBus from '@/services/event-bus'
import AutosizeTextArea from "@/views/parts/general/form/AutosizeTextArea"
import { mapActions, mapMutations, mapState } from "vuex"
import { findDifferencesBetweenStrings } from "@/helpers/textDifferencer"
import TaskCheckButton from "./parts/TaskCheckButton.vue"
import TaskMixin from "./TaskMixin"

export default {
  components: {
    TaskCheckButton,
    TaskCounter,
    AutosizeTextArea
  },
  mixins: [TaskMixin],
  props: {
    host: {
      type: Boolean,
      default: false
    },
    source: {
      type: String,
      default: "preview"
    },
    block: {
      type: Object,
      default: null
    },
    counter: {
      type: String,
      default: null
    },
    ind: {},
  },
  data() {
    return {
      right: null,
      wrong: null,
      text: null,
      finished: false,
      showHint: false,
      parsed: false,
      blocks: [],
      wnr: [],
      timer: null
    }
  },
  computed: {
    showAnswers() {
      return this.host || (this.block.content.show_answers && !this.isEnggoMethodics)
    },
    ...mapState({
      tasksStore: state => state.call.tasksStore,
      lessonId: state   => state.call.lessonId,
      materialId: state => state.call.materialId,
      viewMode: state   => state.call.viewMode,
      selfStudyReviewed: state  => state.call.selfStudyReviewed,
    }),
    showEngGoResponses() {
      return (!this.host && this.$store.state.call.blocksShowingResponses.includes(this.block.id)) || this.selfStudyReviewed
    },
    taskStoreId() {
      return this.tasksStore[this.block.id] ? this.tasksStore[this.block.id] : {
        finished: false,
        text: this.wrong,
        wnr: []
      }
    },
    textByBlocks() {
      return findDifferencesBetweenStrings(this.text, this.right, true)
      // let diffs = findDifferencesBetweenStrings(this.text, this.right, true)
      // let index = 0
      // diffs.forEach(d => {
      //   d.start = index
      //   index += d.passages[1].text.length
      //   d.finish = index
      // })
      // diffs = diffs.filter(x => !x.doPassagesMatch)
      // return diffs
      // return
    },
    // blocksWithManualErrors() {
    //   let obj = {}
    //   for(var i = 0; i < this.blocks.blocks.length; i++ ) {
    //     let block = this.blocks.blocks[i]
    //     let wrongByUser = null
    //     this.textByBlocks.forEach(item => {
    //       if((item.start >= block.index && item.start < block.finish) || (item.finish > block.index && item.finish <= block.finish)) {
    //         wrongByUser = item
    //       }
    //     })
    //     if(wrongByUser) {
    //       let alreadyThere = Object.values(obj).find(x => x.start == wrongByUser.start);
    //       if(!alreadyThere) obj[i] = wrongByUser
    //     }
    //   }
    //   return obj
    // }
  },
  mounted() {
    this.parseData()
    EventBus.$on('slideTaskChangedReceive', (cid, data) => {
      if(this.block.id == cid) {
        this.text = data.text
        this.wnr = data.wnr
        this.finished = this.showAnswers && this.text && this.text === this.right ? true : data.finished
        this.saveTaskStorageBlock({blockId: this.block.id, lessonId: this.lessonId, materialId: this.materialId, type: this.viewMode, data: data})
      }
    })
    EventBus.$on('taskClear', this.clear)
    if(!this.isLight) this.$emit('solved', true, this.wnr)
  },
  methods: {
    ...mapMutations([
      'clearTasksBlock',
    ]),
    ...mapActions([
      'saveTaskStorageBlock',
    ]),
    textareaInput() {
      if(this.isLight) return
      this.check(false)
    },
    check(hint = false) {
      if(this.timer) clearTimeout(this.timer)
      this.$refs.taskcounter.edited = true
      if(hint || this.isLight) {
        this.finished = true
      } else {
        this.finished = this.showAnswers && this.text && this.text === this.right
      }
      this.checkWNR()
      this.$emit('solved', true, this.wnr) // in case you do not see answers, you
      this.timer = setTimeout(this.send, 500)
    },
    send() {
      let data = {
        text: this.text,
        finished: this.finished,
        wnr: this.wnr
      }
      EventBus.$emit('slideTaskChangedSend', this.block.id, data)
      if(this.viewMode != 'homeworkReview') {
        this.saveTaskStorageBlock({blockId: this.block.id, lessonId: this.lessonId, materialId: this.materialId, type: this.viewMode, data: data})
      }
      // TaskStorage.save(this.block.id, d)
    },
    checkWNR() {
      this.blocks.blocks.forEach(block => {
        if(block.type === 'html') {
          // let userResponse = this.text.substring(block.index, (block.index + block.html.length))
          // let isCorrect = userResponse === block.html

        }
        if(block.type === 'error') {
          let userResponse = this.text.substring(block.index, (block.index + block.correct.length))
          let isCorrect = this.text && this.text === this.right ? true : (userResponse == block.correct)
          this.$set(this.wnr, block.counter, isCorrect ? 'r' : 'w')
        }
      })
    },
    focus() {
      this.showHint = true
    },
    blur() {
      this.showHint = false
    },
    clear() {
      this.clearTasksBlock(this.block.id)
      this.$nextTick(this.send)
    },
    retrieveSavedData() {
      this.text = this.taskStoreId.text
      this.wnr = this.taskStoreId.wnr ? this.taskStoreId.wnr : []
      this.finished = (this.showAnswers && this.text === this.right) ? true : this.taskStoreId.finished
      this.$nextTick(() => this.$refs.taskcounter.edited = true)
      this.checkWNR()
    },
    parseData() {
      let text = this.block.content.descr
      let wrong_text = ''
      let right_text = ''
      let tmp_str = ''
      this.blocks = {
        blocks: [],
        right: '',
        wrong: ''
      }
      let textIndex = 0
      let cnt = 0
      for (var i = 0; i < text.length; i++) {
        let char = text.charAt(i)
        if(char === '[') {
          if(tmp_str) {
            this.blocks.blocks.push({
              type: 'html',
              html: tmp_str,
              index: textIndex,
              finish: textIndex + tmp_str.length
            })
            wrong_text += tmp_str
            right_text += tmp_str
            tmp_str = ''
          }
          // textIndex = wrong_text.length
          textIndex = right_text.length
          continue;
        }
        if(char === ']') {
          if(tmp_str) {
            let res =  tmp_str.split("|")
            if(res.length === 2) {
              let wrong = res[0]
              let right = res[1]
              wrong_text += wrong
              right_text += right
              this.blocks.blocks.push({
                type: 'error',
                html: wrong,
                correct: right,
                counter: cnt,
                index: textIndex,
                // finish: textIndex + wrong.length
                finish: textIndex + right.length
              })
              cnt++
            }
            tmp_str = ''
          }
          continue
        }
        tmp_str += char
      }
      if(tmp_str) {
        this.blocks.blocks.push({
          type: 'html',
          html: tmp_str,
          index: textIndex,
          finish: textIndex + tmp_str.length
        })
        wrong_text += tmp_str
        right_text += tmp_str
      }
      this.blocks.wrong = wrong_text
      this.blocks.right = right_text
      this.right = right_text
      this.wrong = wrong_text
      if(!this.text) {
        this.text = this.wrong
      }
      this.parsed = true
      this.retrieveSavedData()
    }
  },
  watch: {
    finished() {
      this.$emit('solved', true, this.wnr)
    },
    taskStoreId: {
      handler() {
        this.retrieveSavedData()
      }
    }
  }

}
</script>






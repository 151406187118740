<!--Image-->
<template>
  <div class="task">
    <TaskCounter :block="block" :counter="counter" />
    <div class="text-center">
      <img class="img st__input"
           v-if="block.content.img"
           :href="block.content.img"
           :src="block.content.img"
           :alt="block.content.title"/>
    </div>
    <div class="st__subtitle st__input" v-if="block.content.title" v-html="block.content.title"></div>
    <StudentTaskText :block="block"/>
  </div>
</template>

<script>
import StudentTaskText from "@/views/parts/constructor/parts/StudentTaskText"
import TaskCounter from "@/views/parts/constructor/parts/TaskCounter"

export default {
  components: {
    StudentTaskText,
    TaskCounter
  },
  props: {
    host: {
      type: Boolean,
      default: false
    },
    source: {
      type: String,
      default: "preview"
    },
    block: {
      type: Object,
      default: null
    },
    counter: {
      type: String,
      default: null
    },
    ind: {},
  },
  mounted() {
    this.$emit('solved', true, [])
  }
}
</script>






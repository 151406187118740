<template>
  <div class="taskCounter pl-2 d-flex justify-content-center flex-column flex-column-reverse">
      <span v-for="(t, tind) in tries"
            :key="`try-${tind}`"
            class="try-circle"
            :class="{ right: t }"></span>
    <div v-if="showSpace && !tries.length" style="width: 4px"></div>
  </div>
</template>

<script>
export default {
  name: "TaskCheckBubbles",
  props: {
    tries: {},
    showSpace: {
      type: Boolean,
      default: false
    },
  }
}
</script>

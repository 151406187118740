<!--Table-->
<template>
  <div class="task" v-if="parsed">
    <TaskCounter :block="block"
                 :showAnswers="showAnswers && host"
                 :counter="counter"
                 @refresh="clear"
                 ref="taskcounter"/>
    <div class="task-table-wrapper" :class="{ 'd-inline-block': table.width > 0 }">
      <table class="task-table client-task-table" :class="{ 'w-auto': table.width > 0 }">
        <tbody>
        <tr v-for="rowIndex in table.rows" :key="`row-${rowIndex}`">
          <td v-for="colIndex in table.cols"
              :style="{ width: table.width ? table.width+'px' : 'auto', height: table.height ? table.height+'px' : 'auto' }"
              :key="`col-${colIndex}`">
            <template v-for="(b, bind) in tasks[(rowIndex - 1)][(colIndex - 1)]">
              <span v-if="b.type === 'html'"
                    v-html="isBold(b.html, true)"
                    :key="`b-ht-${bind}`"></span>
              <input type="text"
                     :key="`b-inp-${bind}`"
                     class="task-table-input client-side-input form-control task-input"
                     placeholder="ответ"
                     :class="{
                      right: (checking || showEngGoResponses || homeworkReview) && transformResponse(responded[(rowIndex - 1)][(colIndex - 1)][b.counter]) === transformResponse(b.html),
                      wrong: (checking || showEngGoResponses || homeworkReview) && transformResponse(responded[(rowIndex - 1)][(colIndex - 1)][b.counter]) !== transformResponse(b.html)
                   }"
                     v-if="b.type === 'input'"
                     v-autowidth="{ maxWidth: 'calc(100% - 10px)', minWidth: '50px', comfortZone: 0 }"
                     @keyup="textInput"
                     :disabled="showEngGoResponses || (tries && tries.length >= triesLimit) || ((checking || homeworkReview) && transformResponse(responded[(rowIndex - 1)][(colIndex - 1)][b.counter]) === transformResponse(b.html))"
                     v-model="responded[(rowIndex - 1)][(colIndex - 1)][b.counter]"/>
              <AutosizeTextArea class="task-table-input client-side-input ml-0 mt-0"
                                :key="`b-ta-${bind}`"
                                placeholder="ответ"
                                v-if="b.type === 'textarea'"
                                :minH="34"
                                :maxH="200"
                                :disabled="Boolean(showEngGoResponses || (tries && tries.length >= triesLimit) || ((checking || homeworkReview) && !!responded[(rowIndex - 1)][(colIndex - 1)][b.counter]))"
                                @input="textInput"
                                :white="true"
                                v-model="responded[(rowIndex - 1)][(colIndex - 1)][b.counter]">
              </AutosizeTextArea>
            </template>



            <!--            <AutosizeTextArea class="task-table-input client-side-input"-->
            <!--                               :class="{ -->
            <!--                                right: (checking || showEngGoResponses || homeworkReview) && transformResponse(responded[(rowIndex - 1)][(colIndex - 1)]) === transformResponse(tasks[(rowIndex - 1)][(colIndex - 1)]), -->
            <!--                                wrong: (checking || showEngGoResponses || homeworkReview) && transformResponse(responded[(rowIndex - 1)][(colIndex - 1)]) !== transformResponse(tasks[(rowIndex - 1)][(colIndex - 1)]) -->
            <!--                              }"-->
            <!--                              placeholder="ответ"-->
            <!--                              v-if="tasks[(rowIndex - 1)][(colIndex - 1)]"-->
            <!--                              :minH="34"-->
            <!--                              :maxH="200"-->
            <!--                              :disabled="showEngGoResponses || (tries && tries.length >= 3) || ((checking || homeworkReview) && transformResponse(responded[(rowIndex - 1)][(colIndex - 1)]) === transformResponse(tasks[(rowIndex - 1)][(colIndex - 1)]))"-->
            <!--                              @input="textInput"-->
            <!--                              :white="true"-->
            <!--                              v-model="responded[(rowIndex - 1)][(colIndex - 1)]">-->
            <!--            </AutosizeTextArea>-->

            <!--            <span v-else v-html="isBold(table.content[(rowIndex - 1)][(colIndex - 1)], true)"></span>-->
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <TaskCheckButton v-if="showAnswers && allResponded && !homeworkReview"
                     class="mt-3"
                     :tries="tries"
                     :disabled="tries && tries.length >= triesLimit"
                     @taskCheck="toCheck"
    />
  </div>
</template>

<script>

import TaskCounter from "@/views/parts/constructor/parts/TaskCounter"
import TaskCheckButton from "./parts/TaskCheckButton"
import AutosizeTextArea from "@/views/parts/general/form/AutosizeTextArea"
import EventBus from '@/services/event-bus'
import {mapActions, mapMutations, mapState} from "vuex"
import TaskMixin from "./TaskMixin"

export default {
  components: {
    TaskCounter,
    AutosizeTextArea,
    TaskCheckButton
  },
  mixins: [TaskMixin],
  props: {
    host: { type: Boolean, default: false },
    source: { type: String, default: "preview" },
    block: { type: Object, default: null },
    counter: { type: String, default: null },
    ind: {},
  },
  data() {
    return {
      responded: [],
      wnr: [],
      tries: [],
      parsed: false,
      tasks: [],
      checking: false,
      timer: null,
      // finished: false,
    }
  },
  computed: {
    table() {
      return this.block.content.table
    },
    allResponded() {
      return this.countFilled(this.responded) === this.countFilled(this.tasks)
    },
    showAnswers() {
      if(this.isLight) return true
      return this.host || (this.block.content.show_answers && !this.isEnggoMethodics)
    },
    triesLimit() {
      return this.isLight ? 1 : 3
    },
    ...mapState({
      tasksStore: state => state.call.tasksStore,
      lessonId: state   => state.call.lessonId,
      materialId: state => state.call.materialId,
      viewMode: state   => state.call.viewMode,
      selfStudyReviewed: state  => state.call.selfStudyReviewed,
    }),
    showEngGoResponses() {
      return (!this.host && this.$store.state.call.blocksShowingResponses.includes(this.block.id)) || this.selfStudyReviewed
    },
    isCorrect() {
      if(!this.allResponded) return false
      let correct = true
      this.tasks.forEach((row, rowIndex) => {
        row.forEach((val, colIndex) => {
          if(val) {
            val.forEach(block => {
              if(block.type == 'input') {
                if(this.transformResponse(block.html) !== this.transformResponse(this.responded[rowIndex][colIndex][block.counter])) {
                  correct = false
                }
              }
              // if(block.type == 'textarea') {
              //   if(!this.responded[rowIndex][colIndex][block.counter]) {
              //     correct = false
              //   }
              // }
            })
          }
        })
      })
      // this.tasks.forEach((row, rowIndex) => {
      //   row.forEach((val, colIndex) => {
      //     if(val && val !== this.responded[rowIndex][colIndex]) {
      //       correct = false
      //     }
      //   })
      // })
      return correct
    },
    taskStoreId() {
      return this.tasksStore[this.block.id] ? this.tasksStore[this.block.id] : {
        responded: this.emptyResponded(),
        wnr: [],
        tries: [],
        checking: false
      }
    },
  },
  mounted() {
    this.parseData()
    EventBus.$on('slideTaskChangedReceive', (cid, data) => {
      if(this.block.id == cid) {
        this.responded = data.responded
        this.tries = data.tries
        this.checking = data.checking
        this.wnr = data.wnr
        this.saveTaskStorageBlock({
          blockId: this.block.id,
          lessonId: this.lessonId,
          materialId: this.materialId,
          type: this.viewMode,
          data
        })
      }
    })
    EventBus.$on('taskClear', this.clear)
    if(!this.isLight) this.$emit('solved', this.allResponded, this.wnr)
  },
  methods: {
    ...mapMutations([
      'clearTasksBlock',
    ]),
    ...mapActions([
      'saveTaskStorageBlock',
    ]),
    toCheck() {
      let empty = true
      this.responded.forEach(r => {
        let z = r.filter(x => {
          return x.filter(y => {
            return !!y
          }).length > 0
        }).length > 0
        if(z) empty = false
      })
      if(empty) return
      this.tries.push(this.isCorrect)
      this.checkWNR()
      this.checking = true
      if(this.isLight) this.$emit('solved', true, this.wnr)
      this.$nextTick(() => {
        this.send()
      })
    },
    checkWNR() {
      let counter = 0
      this.tasks.forEach((row, rowIndex) => {
        row.forEach((val, colIndex) => {
          if(val) {
            val.forEach(block => {
              if(block.type == 'input') {
                this.$set(this.wnr, counter, this.transformResponse(block.html) === this.transformResponse(this.responded[rowIndex][colIndex][block.counter]) ? 'r' : 'w')
                counter++
              }
              // if(block.type == 'textarea') {
              //   this.$set(this.wnr, counter, this.responded[rowIndex][colIndex][block.counter] ? 'r' : 'w')
              //   counter++
              // }
            })
          }
        })
      })
    },
    send() {
      let data = {
        responded: this.responded,
        tries: this.tries,
        wnr: this.wnr,
        checking: this.checking,
      }
      EventBus.$emit('slideTaskChangedSend', this.block.id, data)
      if(this.viewMode != 'homeworkReview') {
        this.saveTaskStorageBlock({blockId: this.block.id, lessonId: this.lessonId, materialId: this.materialId, type: this.viewMode, data: data})
      }
    },
    clear() {
      this.clearTasksBlock(this.block.id)
      this.$nextTick(this.send)
    },
    retrieveSavedData() {
      this.responded = this.taskStoreId.responded
      this.tries = this.taskStoreId.tries
      this.wnr = this.taskStoreId.wnr ? this.taskStoreId.wnr : []
      this.checking = this.taskStoreId.checking
      this.$nextTick(() => this.$refs.taskcounter.edited = true)
      if(this.isLight && this.checking) this.$emit('solved', true, this.wnr)
    },
    parseData() {
      let tasks = []
      for (var rowIndex = 0; rowIndex < this.table.rows; rowIndex++) {
        tasks[rowIndex] = []
        for (var colIndex = 0; colIndex < this.table.cols; colIndex++) {
          tasks[rowIndex][colIndex] = this.isTask(this.table.content[rowIndex][colIndex])
        }
      }
      this.responded = this.emptyResponded()
      this.tasks = tasks
      this.parsed = true
      this.retrieveSavedData()
    },
    textInput() {
      this.$refs.taskcounter.edited = true
      if(this.timer) clearTimeout(this.timer)
      this.checkWNR()
      this.timer = setTimeout(this.send, 500)
      this.checking = false
    },
    emptyResponded() {
      let responded = []
      for (var rowIndex = 0; rowIndex < this.table.rows; rowIndex++) {
        responded[rowIndex] = []
        for (var colIndex = 0; colIndex < this.table.cols; colIndex++) {
          let blocks = this.isTask(this.table.content[rowIndex][colIndex])
          let responses = []
          blocks.forEach(block => {
            if(block.type == 'input' || block.type == 'textarea') {
              responses.push(null)
            }
          })
          responded[rowIndex][colIndex] = responses
        }
      }
      return responded
    },
    countFilled(arr) {
      let count = 0
      arr.forEach(row => {
        row.forEach(val => {
          if(val) count++
        })
      })
      return count
    },
    isBold(string, returnString = false) {
      let stringArray = string.match(/\*(.*?)\*/i)
      if(stringArray) {
        string = string.replace(stringArray[0], '<b>'+stringArray[1]+'</b>')
        return returnString ? this.isBold(string, returnString) : true
      }
      return returnString ? string : false
    },
    isTask(string) {
      if(string === '[]') {
        return [{type: 'textarea', html: '', counter: 0}]
      }
      let blocks = []
      let tmp_str = ''
      let cnt = 0
      for (var i = 0; i < string.length; i++) {
        let char = string.charAt(i)
        if(char === '[') {
          if(tmp_str) {
            blocks.push({
              type: 'html',
              html: tmp_str
            })
            tmp_str = ''
          }
          continue
        }
        if(char === ']') {
          if(tmp_str) {
            blocks.push({
              type: 'input',
              html: tmp_str,
              counter: cnt
            })
            tmp_str = ''
            cnt++
          }
          continue
        }
        tmp_str += char
      }
      if(tmp_str) {
        blocks.push({
          type: 'html',
          html: tmp_str
        })
      }
      return blocks
      // if(string.length < 2) return null
      // if(string[0] === '[' && string[string.length - 1] === ']') {
      //   return string.slice(1,-1)
      // }
      // return null
    },
    // isTask(string) {
    //   if(string.length < 2) return null
    //   if(string[0] === '[' && string[string.length - 1] === ']') {
    //     return string.slice(1,-1)
    //   }
    //   return null
    // },
    transformResponse(string) {
      if(!string) return ''
      return string.toLowerCase().trim().replace('.', '')
    }
  },
  watch: {
    allResponded(val) {
      this.checking = false
      this.$emit('solved', val, this.wnr)
    },
    taskStoreId: {
      handler() {
        this.retrieveSavedData()
      }
    }
  }
}
</script>




<!--Record audio-->
<template>
  <div class="task">
    <TaskCounter :block="block"
                 :counter="counter"
                 ref="taskcounter"/>
    <div class="px-md-3 px-0 text-center">
      <b-row class="align-items-center d-inline-flex" :class="{ 'flex-nowrap': ['left','right'].includes(imgPosition) }">
        <div
            v-if="content.img || isLight"
            :class="{
              'mb-3': ['top'].includes(imgPosition),
              'mt-3': ['bottom'].includes(imgPosition) && !isLight,
              'col-12': ['top','bottom'].includes(imgPosition),
              'col-lg-auto col-md-4 col-sm-4 col-5': ['left','right'].includes(imgPosition),
              'order-0': imgPosition === 'left',
              'order-1': ['right', 'bottom'].includes(imgPosition)
            }">
          <div class="px-2">
            <img class="img st__input"
                 v-if="content.img"
                 :href="content.img"
                 :src="content.img"
                 :alt="content.descr"/>
            <Monstrik v-if="!content.img && isLight"/>
          </div>
        </div>
        <div v-if="content.descr"
             :class="{
                  'mx-auto': ['top','bottom'].includes(imgPosition),
                  'order-1': imgPosition === 'left',
                  'order-0': ['right', 'bottom'].includes(imgPosition)
               }">
          <div class="px-2">
            <div class="text-block d-inline-block px-md-4 px-2 bubble-speech" :class="{
            'corner-left': (content.img || isLight) && imgPosition === 'left',
            'corner-right': (content.img || isLight) && imgPosition === 'right',
            'corner-top': (content.img || isLight) && imgPosition === 'top',
            'corner-bottom': (content.img || isLight) && imgPosition === 'bottom'
          }">
              <b-row class="align-items-center">
                <b-col cols="auto" v-if="content.audio || content.auto_audio">
                  <a class="btn pl-0 pr-md-2 pr-1" @click="playAudio"><PlaySvg class="svg-icon-md svg-blue"/></a>
                </b-col>
                <b-col>{{ content.descr }}</b-col>
              </b-row>
            </div>
          </div>
        </div>
        <b-col cols="auto" v-if="!content.descr && (content.audio || content.auto_audio)">
          <div class="px-2">
            <a class="btn btn-themed" @click="playAudio">
              <PlaySvg class="svg-icon-md svg-white"/>
            </a>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>

import TaskCounter from "@/views/parts/constructor/parts/TaskCounter"
import PlaySvg from "@/assets/svg/audio6.svg"
import { mapState } from "vuex"
import { generateAudio } from "../../../helpers/generateAudio"
import Monstrik from "./parts/Monstrik.vue"
import TaskMixin from "./TaskMixin"

export default {
  components: {
    Monstrik,
    TaskCounter,
    PlaySvg,
  },
  data() {
    return {
      audio: null
    }
  },
  mixins: [TaskMixin],
  props: {
    host: {
      type: Boolean,
      default: false
    },
    source: {
      type: String,
      default: "preview"
    },
    block: {
      type: Object,
      default: null
    },
    counter: {
      type: String,
      default: null
    },
    ind: {},
  },
  computed: {
    ...mapState({
      tasksStore: state => state.call.tasksStore,
      lessonId: state   => state.call.lessonId,
      materialId: state => state.call.materialId,
      viewMode: state   => state.call.viewMode,
    }),
    content() {
      return this.block.content
    },
    imgPosition() {
      return this.content?.img_position || 'left'
    }
  },
  mounted() {
    this.$emit('solved', true, [])
  },
  methods: {
    async playAudio() {
      if(!this.content.audio && !this.content.auto_audio) return
      if(this.audio) this.audio.pause()
      if(this.content.audio) {
        this.audio = new Audio(this.content.audio)
        await this.audio.play()
        return
      }
      if(this.content.auto_audio && this.content.descr) {
        this.content.audio = await generateAudio(this.content.descr, null, this.block.slide_id, `${this.block.id}_audio_${Math.floor(Date.now() / 1000)}.mp3`, null, this.content.audio_config)
      }
    }
  },
  beforeDestroy() {
    if(this.audio) this.audio.pause()
  }
}
</script>

<style scoped lang="scss">
.text-block {
  background: #FFFFFF;
  border: 1px solid #E6EAF2;
  border-radius: 12px;
  padding: 12px;
  position:relative;
}
.btn-themed {
  height: 64px;
  width: 64px;
  padding: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.bubble-speech {
  font-size: 17px;
  min-width: 180px;
}
.img {
  max-width: 260px !important;
  width: 100%;
}
.corner-left,
.corner-right,
.corner-top,
.corner-bottom {
  &:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    //top: 50%;
    //transform: translateY(-50%);
    //left: -17px;
  }
  &:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }
}
.corner-left:before,
.corner-left:after,
.corner-right:before,
.corner-right:after {
  top: 50%;
  transform: translateY(-50%);
}
.corner-left:before {
  border-right: 8px solid #E6EAF2;
  left: -17px;
}
.corner-left:after {
  border-right: 10px solid #fff;
  left: -17px;
}
.corner-right:before {
  border-left: 8px solid #E6EAF2;
  right: -17px;
}
.corner-right:after {
  border-left: 10px solid #fff;
  right: -17px;
}

.corner-top:before,
.corner-top:after,
.corner-bottom:before,
.corner-bottom:after {
  left: 50%;
  transform: translateX(-50%);
}

.corner-top:before {
  border-bottom: 8px solid #E6EAF2;
  top: -17px;
}
.corner-top:after {
  border-bottom: 10px solid #fff;
  top: -17px;
}
.corner-bottom:before {
  border-top: 8px solid #E6EAF2;
  bottom: -17px;
}
.corner-bottom:after {
  border-top: 10px solid #fff;
  bottom: -17px;
}

@media(max-width: 768px) {
  .text-block {
    border-radius: 8px;
    padding: 8px;
  }
  .bubble-speech {
    font-size: 15px;
  }
}
</style>






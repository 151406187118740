<template>
  <canvas id="rive" width="420" height="560"/>
</template>

<script>
import EventBus from '@/services/event-bus'

export default {
  name: "Monstrik",
  data() {
    return {
      successEvent: null,
      failureEvent: null,
    }
  },
  mounted() {
    const check = new window.rive.Rive({
      src: "/riv/character.riv",
      canvas: document.getElementById("rive"),
      autoplay: true,
      stateMachines: "State Machine 3",
      onLoad: () => {
        const inputs = check.stateMachineInputs("State Machine 3")
        this.successEvent = inputs.find(i => i.name === 'success')
        this.failureEvent = inputs.find(i => i.name === 'fail')
      }
    })
    EventBus.$on('light-course-result', (val) => {
      if(val) {
        if(this.successEvent) this.successEvent.fire()
      } else {
        if(this.failureEvent) this.failureEvent.fire()
      }
    })
  },
  beforeDestroy() {
    EventBus.$off('light-course-result')
  }
}
</script>

<style scoped>
#rive {
  width: 210px;
}
</style>

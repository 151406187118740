<!--Dragging the missing words-->
<template>
  <div class="task"
       v-if="parsed"
       :class="`view-${alignView}`">
    <TaskCounter :block="block"
                 :showAnswers="host"
                 :counter="counter"
                 @refresh="clear"
                 ref="taskcounter"/>
    <div class="">
      <drop class="drag-words-wrapper" @drop="droppedBack(...arguments)">
        <template v-for="(b, bind) in shuffled">
          <drag class="d-inline-block drag-wrapper"
                :key="'drag-'+block.id+'-'+bind"
                :image-x-offset="(b.html.length * 2.5) + 12"
                :image-y-offset="20"
                @click.native="dragWordClicked({ html: b.html, index: b.counter, counter: b.counter, filled: false })"
                :class="{ dragged: usedWords.includes(b.counter), selected: selectedWord && selectedWord.index === b.counter }"
                :draggable="!usedWords.includes(b.counter)"
                :transfer-data="{ html: b.html, index: b.counter, filled: false }">
            <div slot="image" class="drag-image">{{ b.html }}</div>
            <template slot-scope="props">
              <div class="drag-block removed-by-dragging text-themed" v-if="props.transferData">{{ b.html }}</div>
              <div v-else class="drag-block">{{ b.html }}</div>
            </template>
          </drag>
        </template>
      </drop>
    </div>
    <div class="align-items-center text-content">
      <template v-for="(b, bind) in blocks">
        <template v-if="b.type === 'html'">{{ b.html }}</template>
        <template v-if="b.type === 'missing'">
          <div class="d-inline-block position-relative align-middle drag-wrapper-setup" :key="'drop-'+block.id+'-'+bind">
            <drop class="drop-block task-input form-control form-control-white"
                  :class="{
                    responded: responded[b.counter],
                    hovered: hovered[b.counter] === true,
                    right:  (checking || showEngGoResponses || homeworkReview) && responded[b.counter] && responded[b.counter].html == b.html,
                    wrong:  (checking || showEngGoResponses || homeworkReview) && responded[b.counter] && responded[b.counter].html != b.html
                  }"
                  @click.native="dropWordClicked(b)"
                  @dragover="$set(hovered, b.counter, true)"
                  @dragleave="$set(hovered, b.counter, false)"
                  @drop="dropped(b.html, b.counter, ...arguments)">
              <!--              <span v-if="responded[b.counter]">{{ responded[b.counter] }}</span>-->
              <drag class="d-inline-block drag-wrapper drag-wrapper-setup"
                    v-if="responded[b.counter]"
                    :image-x-offset="(responded[b.counter].length * 2.5) + 12"
                    :image-y-offset="20"
                    :draggable="!(checking && responded[b.counter].html == b.html) && !showEngGoResponses"
                    @click.native.stop="dragWordClicked({ html: responded[b.counter].html, index: responded[b.counter].index, counter: b.counter, filled: true })"
                    :key="'drag-'+block.id+'-'+bind"
                    :transfer-data="{ html: responded[b.counter].html, index: responded[b.counter].index, counter: b.counter, filled: true }">
                <div slot="image" class="drag-image">{{ responded[b.counter].html }}</div>
                <template slot-scope="props">
                  <div class="drag-block removed-by-dragging text-themed" v-if="props.transferData">{{ responded[b.counter].html }}</div>
                  <div v-else class="drag-block">
                    <template v-if="responded[b.counter].html == b.html || !showEngGoResponses">{{ responded[b.counter].html }}</template>
                    <template v-else>
                      <span class="striked">{{ responded[b.counter].html }}</span>
                      <span class="text-success ml-2">{{ b.html }}</span>
                    </template>
                  </div>
                </template>
              </drag>
            </drop>
          </div>
        </template>
      </template>
    </div>
    <TaskCheckButton v-if="showAnswers && allResponded && !(homeworkReview && !block.content.show_answers) && !isLight"
                     class="mt-2"
                     :tries="tries"
                     :disabled="tries && tries.length >= triesLimit"
                     @taskCheck="toCheck"
    />
  </div>
</template>

<script>

import TaskCounter from "@/views/parts/constructor/parts/TaskCounter"
import EventBus from '@/services/event-bus'
import Vue from 'vue'
import VueDragDrop from 'vue-drag-drop'
import { mapActions, mapMutations, mapState } from "vuex"
import TaskCheckButton from "./parts/TaskCheckButton"
import { shuffleArray } from "../../../helpers/shuffleArray"
import TaskMixin from "./TaskMixin"
Vue.use(VueDragDrop)

export default {
  components: {
    TaskCheckButton,
    TaskCounter
  },
  mixins: [TaskMixin],
  props: {
    host: {
      type: Boolean,
      default: false
    },
    source: {
      type: String,
      default: "preview"
    },
    block: {
      type: Object,
      default: null
    },
    counter: {
      type: String,
      default: null
    },
    ind: {},
  },
  data() {
    return {
      responded: {},
      wnr: [],
      checking: false,
      tries: [],
      hovered: [],
      parsed: false,
      tasks: 0,
      blocks: [],
      shuffled: [],
      selectedWord: null
    }
  },
  computed: {
    allResponded() {
      return this.usedWords.length === this.numberOfCorrectWords
    },
    numberOfCorrectWords() {
      return this.shuffled.filter(x => x.right).length
    },
    showAnswers() {
      if(this.isLight) return true
      return this.host || (this.block.content.show_answers && !this.isEnggoMethodics)
    },
    ...mapState({
      tasksStore: state => state.call.tasksStore,
      lessonId: state   => state.call.lessonId,
      materialId: state => state.call.materialId,
      viewMode: state   => state.call.viewMode,
      selfStudyReviewed: state  => state.call.selfStudyReviewed,
    }),
    isCorrect() {
      if(!this.allResponded) return false
      for(let i in this.responded) {
        let block = this.blocks.find(x => x.counter == i)
        if(!this.responded[i] || this.responded[i].html !== block.html) {
          return false
        }
      }
      return true
    },
    showEngGoResponses() {
      return (!this.host && this.$store.state.call.blocksShowingResponses.includes(this.block.id)) || this.selfStudyReviewed
    },
    triesLimit() {
      return this.isLight ? 1 : 3
    },
    taskStoreId() {
      return this.tasksStore[this.block.id] ? this.tasksStore[this.block.id] : {
        responded: {},
        wnr: [],
        checking: false,
        tries: []
      }
    },
    usedWords() {
      return (Object.values(this.responded)).map(x => x.index)
    },
  },
  mounted() {
    this.parseData()
    EventBus.$on('slideTaskChangedReceive', (cid, data) => {
      if(this.block.id == cid) {
        this.responded = data.responded
        this.checking = data.checking
        this.tries = data.tries
        this.wnr = data.wnr
        this.saveTaskStorageBlock({blockId: this.block.id, lessonId: this.lessonId, materialId: this.materialId, type: this.viewMode, data: data})
      }
    })
    EventBus.$on('taskClear', this.clear)
    this.$emit('solved', this.allResponded, this.wnr)
  },
  methods: {
    ...mapMutations(['clearTasksBlock']),
    ...mapActions(['saveTaskStorageBlock']),
    dropped(correct, index, data) {
      if(this.isLight && this.allResponded) return
      this.checking = false
      let oldIndex = null
      for(let i in this.responded) {
        if(this.responded[i].index === data.index) oldIndex = i
      }
      let previousResponse = null
      if(data.filled && this.responded[index]) {
        previousResponse = this.responded[index]
      }
      this.$set(this.responded, index, data)
      if(oldIndex) {
        this.$delete(this.responded, oldIndex)
      }
      if(oldIndex && previousResponse) {
        this.$set(this.responded, oldIndex, previousResponse) // swapping places
      }
      this.checkWNR()
      this.$refs.taskcounter.edited = true
      this.$set(this.hovered, index, false)
      this.selectedWord = null
      this.$nextTick(this.send)
    },
    droppedBack(data) {
      if(this.isLight && this.allResponded) return
      this.$delete(this.responded, data.counter)
      this.$nextTick(this.send)
      this.selectedWord = null
    },
    checkWNR() {
      this.blocks.forEach(block => {
        if(block.type === 'missing') {
          let isCorrect = this.responded[block.counter] && this.responded[block.counter].html == block.html
          this.$set(this.wnr, block.counter, isCorrect ? 'r' : 'w')
        }
      })
    },
    send() {
      let data = {
        responded: this.responded,
        checking: this.checking,
        tries: this.tries,
        wnr: this.wnr,
      }
      EventBus.$emit('slideTaskChangedSend', this.block.id, data)
      if(!this.homeworkReview) {
        this.saveTaskStorageBlock({blockId: this.block.id, lessonId: this.lessonId, materialId: this.materialId, type: this.viewMode, data: data})
      }
    },
    clear() {
      this.hovered = []
      this.clearTasksBlock(this.block.id)
      this.$nextTick(this.send)
    },
    retrieveSavedData() {
      this.checking = this.taskStoreId.checking
      this.responded = this.taskStoreId.responded
      this.tries = this.taskStoreId.tries
      this.wnr = this.taskStoreId.wnr ? this.taskStoreId.wnr : []
      this.$nextTick(() => this.$refs.taskcounter.edited = true)
    },
    toCheck() {
      this.tries.push(this.isCorrect)
      this.checking = true
      this.$nextTick(this.send)
    },
    dragWordClicked(word) {
      if(!word.filled && this.usedWords.includes(word.index)) return
      if(this.selectedWord && this.selectedWord.index === word.index) {
        this.selectedWord = null
        return
      }
      if(word.filled) {
        if(this.showEngGoResponses) return
        this.droppedBack(word)
        return
      }
      this.selectedWord = word
    },
    dropWordClicked(word) {
      if(!this.selectedWord) return
      this.dropped(word.html, word.counter, this.selectedWord)
    },
    parseData() {
      let text = this.block.content.text
      let tmp_str = ''
      let cnt = 0
      let shuffled = []
      if(text && text.length) {
        const textLen = text.length
        for (var i = 0; i < textLen; i++) {
          let char = text.charAt(i)
          if(char === '[') {
            if(tmp_str) {
              this.blocks.push({
                type: 'html',
                html: tmp_str
              })
              tmp_str = ''
            }
            continue
          }
          if(char === ']') {
            const wrong = i > 0 && text.charAt((i - 1)) === '*'
            if(tmp_str) {
              this.tasks++
              this.blocks.push({
                type: wrong ? 'wrong' : 'missing',
                html: tmp_str,
                correct: tmp_str,
                counter: cnt
              })
              shuffled.push({
                html: tmp_str,
                counter: cnt,
                right: !wrong
              })
              tmp_str = ''
              cnt++
            }
            continue
          }
          if(char === '*' && (i + 1) < (textLen) && text.charAt((i + 1)) === ']') continue
          tmp_str += char
        }
      }
      if(tmp_str) {
        this.blocks.push({
          type: 'html',
          html: tmp_str
        })
      }
      this.shuffled = shuffleArray(shuffled)
      this.parsed = true
      this.retrieveSavedData()
    },
  },
  watch: {
    allResponded(val) {
      this.checking = false
      this.$emit('solved', val, this.wnr)
      if(this.isLight && val) this.checking = true
    },
    taskStoreId: {
      handler() {
        this.retrieveSavedData()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.drag-image {
  display: inline-block;
  background: #FFFFFF;
  box-shadow: 0 1px 3px rgba(105, 114, 121, 0.25);
  border-radius: 10px;
  padding: 8px 12px;
}
.view-center {
  .drag-words-wrapper, .text-content {
    text-align: center;
  }
  ::v-deep .task-check-btn {
    justify-content: center;
  }
}
</style>





<!--Teacher's note-->
<template>
  <div class="task">
    <div v-if="block.content.descr"
         class="st__preface st__input teacher-note"
         :class="{ onlyhost: !block.content.visible_to_students }">
      <div class="subtitle">{{ $t('teacher_note') }}</div>
      <div v-html="block.content.descr"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    host: {
      type: Boolean,
      default: false
    },
    source: {
      type: String,
      default: "preview"
    },
    block: {
      type: Object,
      default: null
    },
    counter: {
      type: String,
      default: null
    },
    ind: {},
  },
  mounted() {
    this.$emit('solved', true, [])
  },
}
</script>






<!--Video-->
<template>
  <div class="task">
    <TaskCounter :block="block" :counter="counter" ref="taskcounter"/>
    <youtube class="video"
             :player-vars="{ showinfo: 0, controls: 1, origin: origin, host: `${protocol}//www.youtube.com`, allowFullscreen: false, fs: 0, rel: 0 }"
             v-if="block.content.url"
             :video-id="videoId"
             @ready="playerReady"
             @ended="playerEnded"
             @paused="playerPaused"
             @playing="playerPlay"></youtube>
    <div v-else>{{ $t('file_does_not_exist') }}</div>
    <div class="st__subtitle st__input" v-if="block.content.title" v-html="block.content.title"></div>
    <div v-if="block.content.script"
         class="audio-script"
         :class="{ onlyhost: !block.content.show_script_to_users }">
      <div class="font-weight-bold mb-0 card-collapse" v-b-toggle="'script-'+block.id">
        {{ $t('script') }}<span class="collapse-caret ml-3"><DownSvg/></span>
      </div>
      <b-collapse :id="'script-'+block.id">
        <div class="pt-3" v-html="block.content.script"></div>
      </b-collapse>
    </div>
    <StudentTaskText :block="block"/>
  </div>
</template>

<script>
import TaskCounter from "@/views/parts/constructor/parts/TaskCounter"
import StudentTaskText from "@/views/parts/constructor/parts/StudentTaskText"
import DownSvg from "@/assets/svg/down.svg"
import EventBus from '@/services/event-bus'

export default {
  components: {
    TaskCounter,
    DownSvg,
    StudentTaskText
  },
  props: {
    host: {
      type: Boolean,
      default: false
    },
    source: {
      type: String,
      default: "preview"
    },
    block: {
      type: Object,
      default: null
    },
    counter: {
      type: String,
      default: null
    },
    ind: {},
  },
  mounted() {
    EventBus.$on('youtubeChangeReceived', (data) => {
      if(this.block.id == data.component_id && this.player && data) {
        let d = data.data
        if(!d) return
        if(d.action == 'play') {
          this.player.playVideo()
          if(d.time) {
            setTimeout(() => {
              this.player.seekTo(d.time, true)
            },100)
          }
        }
        if(d.action == 'pause') {
          this.player.stopVideo()
        }
      }
    })
    this.$emit('solved', true, [])
  },
  methods: {
    playerReady(event) {
      this.player = event.target
    },
    playerPlay() {
      this.sendActionToSocket('play')
    },
    playerPaused() {
      this.$store.commit('setSpecialKey')
      this.sendActionToSocket('pause')
    },
    playerEnded() {
      this.$store.commit('setSpecialKey')
    },
    sendActionToSocket(action) {
      if(this.host) {
        let time = this.player.getCurrentTime()
        EventBus.$emit('youtubeChangeSent', this.block.id, { action: action, time: time })
      }
    }
  },
  computed: {
    origin() {
      return window.origin
    },
    protocol() {
      return window.location.protocol
    },
    videoId() {
      const mainURL = new URL(this.block.content.url)
      return mainURL.searchParams.get('v')
    },
  },
  data() {
    return {
      player: null
    }
  }
}
</script>






<template>
  <div v-if="parsingFinished">
    <div v-for="(p, pind) in slidecomponents"
         class="position-relative"
         :key="'block-'+pind+'-'+(typeof(ind) == 'undefined' ? 0 : ind)">
      <div :ref="'refcomanchor'+pind" style="position: absolute; top: -80px; left: 0" v-if="!hideComponent(p)"></div>
      <component :is="p.component.component_type.code"
                 v-if="!hideComponent(p, pind)"
                 class="space_general_component"
                 :class="[{ hostcomponent: host }, p.component.component_type.code]"
                 :host="host"
                 :ref="'refcom'+pind"
                 :block="p.component"
                 :selectedUser="selectedUser"
                 :homeworkStudentId="homeworkStudentId"
                 :counter="counters[p.component.id]"
                 :data-id="p.component.id"
                 @solved="componentSolved(p.component, pind, ...arguments)"
                 :source="source"
                 :ind="pind+'-'+(typeof(ind) == 'undefined' ? 0 : ind)">
      </component>
    </div>
  </div>
</template>

<script>

// *** LIBRARY OF CODES *** //

// AU01 - audio
// AU02 - record audio
// AU03 - audio img text
// IM01 - image
// IM02 - attach image through QR
// TS01 - fill missing
// TS02 - insert multiple missing
// TS03 - quiz
// TS04 - drag and drop
// TS05 - find errors
// TS06 - link words
// TS07 - create sentence from words
// TS08 - sort words by columns
// TS09 - true or false
// TS10 - table
// TS11 - underline words
// TS12 - type word by letter
// TS13 - word cards task
// TS14 - type missing letters
// TX01 - text
// TX02 - teacher's note
// VD01 - video

import EventBus from '@/services/event-bus'
import AU01 from '@/views/parts/constructor/AU01'
import AU02 from '@/views/parts/constructor/AU02'
import AU03 from '@/views/parts/constructor/AU03'
import IM01 from '@/views/parts/constructor/IM01'
import IM02 from '@/views/parts/constructor/IM02'
import TS01 from '@/views/parts/constructor/TS01'
import TS02 from '@/views/parts/constructor/TS02'
import TS03 from '@/views/parts/constructor/TS03'
import TS04 from '@/views/parts/constructor/TS04'
import TS05 from '@/views/parts/constructor/TS05'
import TS06 from '@/views/parts/constructor/TS06'
import TS07 from '@/views/parts/constructor/TS07'
import TS08 from '@/views/parts/constructor/TS08'
import TS09 from '@/views/parts/constructor/TS09'
import TS10 from '@/views/parts/constructor/TS10'
import TS11 from '@/views/parts/constructor/TS11'
import TS12 from '@/views/parts/constructor/TS12'
import TS13 from '@/views/parts/constructor/TS13'
import TS14 from '@/views/parts/constructor/TS14'
import TX01 from '@/views/parts/constructor/TX01'
import TX02 from '@/views/parts/constructor/TX02'
import VD01 from '@/views/parts/constructor/VD01'

export default {
  components: {
    AU01,
    AU02,
    AU03,
    IM01,
    IM02,
    TS01,
    TS02,
    TS03,
    TS04,
    TS05,
    TS06,
    TS07,
    TS08,
    TS09,
    TS10,
    TS11,
    TS12,
    TS13,
    TS14,
    TX01,
    TX02,
    VD01,
  },
  props: {
    source: { type: String, default: 'preview' },
    slidecomponents: { type: Array },
    selectedUser: { default: null },
    homeworkStudentId: { default: null },
    followed: { type: Boolean, default: false },
    ind: {},
    slide: { type: Number },
    host: { type: Boolean, default: false },
    view: { type: String, default: 'lesson' },
    lightCourseComponentIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      parsingFinished: false,
      currentScrolledIndex: 0,
      counters: {},
      componentsSolved: {},
    }
  },
  methods: {
    handleScroll() {
      if(this.followed) {
        let el = document.getElementById("slide")
        if(!el) return
        // let h = window.scrollY + (window.innerHeight / 2)
        let h = el.scrollTop + (el.clientHeight / 2)
        // console.log(el.scrollTop)
        // console.log(el.clientHeight)

        for (let index = 0; index < this.slidecomponents.length; index++) {
          let $el = this.$refs['refcom'+index]?.[0]?.$el
          if(!$el) continue
          let off = $el.getBoundingClientRect().top
          // console.log(h, index, off)
          if(h >= off && el.scrollTop <= off) {
            // console.log('passed ', index)
            if(index != this.currentScrolledIndex) {
              this.currentScrolledIndex = index
              EventBus.$emit('scrollToTask',index)
              return
            }
          }
        }
      }
    },
    componentSolved(component, index, finished, wnr) {
      const id = component.id
      this.$set(this.componentsSolved, id, finished)
      this.$emit('componentSolved', component, index, finished, wnr)
    },
    hideComponent(d, index) {
      if(this.source === 'light') {
        if(this.lightCourseComponentIndex < index) return true
      }
      if(this.view !== 'lesson') return false
      return d.component.hidden_unit
    }
  },
  created() {
    // document.getElementById("slide").addEventListener('scroll', this.handleScroll)
    // window.addEventListener('scroll', this.handleScroll)
  },
  mounted() {
    let el = document.getElementById("slide")
    if(el) {
      el.addEventListener('scroll', this.handleScroll)
    }

    let c = 0
    this.slidecomponents.forEach(item => {
      let com = item.component
      if(['TX02'].includes(com.component_type.code)) {
        this.counters[com.id] = null
      } else {
        c++
        this.counters[com.id] = this.slide + 1 + '.' +(typeof(this.ind) == 'undefined' ? (c) : (this.ind + 1))
      }
    })
    this.parsingFinished = true
    EventBus.$on('scrollReceived', (index) => {
      // console.log('received scroll to block ', index)
      let refcom = 'refcomanchor'+index
      let els = this.$refs[refcom]
      if(els && els.length > 0) {
        // els[0].$el.scrollIntoView({ behavior: 'smooth', block: 'start' })
        els[0].scrollIntoView({ behavior: 'smooth', block: 'start' })
        setTimeout(() => {
          this.$store.commit('setSelectedBlockId', null)
        }, 3000)
      }
    })
    EventBus.$on('scrollToBlockId', (blockId) => {
      this.slidecomponents.forEach((item, index) => {
        let $el = this.$refs['refcom'+index]?.[0]
        if($el) {
          $el = $el.$el
          if($el.dataset.id == blockId) {
            this.$store.commit('setSelectedBlockId', blockId)
            this.currentScrolledIndex = index
            EventBus.$emit('scrollReceived',index)
          }
        }
      })
    })
    // this.$emit('slidesolved', this.allTasksDone)
  },
  watch: {
    allTasksDone(val) {
      this.$emit('slidesolved', val)
    }
  },
  computed: {
    allTasksDone() {
      return this.tasksSolved === this.slidecomponents.length
    },
    tasksSolved() {
      return Object.keys(this.componentsSolved).reduce((acc, cur) => this.componentsSolved[cur] ? ++acc : acc, 0)
    }
  },
  beforeDestroy() {
    let el = document.getElementById("slide")
    if(el) {
      el.removeEventListener('scroll', this.handleScroll)
    }
  }
}
</script>





